import { message, notification } from 'antd';
import api from '../api';
import { IUser } from '../../Types';

export default class UserServices {
  static readonly postUserData = async (user: IUser): Promise<IUser> => {
    return api
      .post(`/users`, user)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          notification.error({
            message: `Erro ${error.response.status}`,
            description: `${error.response.data.error}`,
            className: 'error-message',
            duration: 2,
          });
        } else {
          message.error(error.message);
        }
        return false;
      });
  };

  static readonly putUserData = async (user: IUser): Promise<IUser> => {
    return api
      .put(`/users`, user)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.error}`,
          );
        } else {
          message.error(error.message);
        }
      });
  };
}
