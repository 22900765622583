import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  background: #fff;
  color: #000 !important;
`;

export const PanelContainer = styled(Container)`
  height: calc(100% - 60px);
  flex-direction: column;
  padding: 0 1rem 85px;
  align-items: flex-start;
  background: #fbfbfb;
  overflow-y: scroll;
  overflow-x: hidden;
  /* &::before {
    content: '';
    position: fixed;
    background: #fbfbfb;
    width: 100%;
    height: 2rem;
    left: 0;
    z-index: 10;
    @media (min-width: 1024px) {
      content: none;
    } */
  }

  @media (min-width: 1024px) {
    height: calc(100% - 148px);
    padding: 0 2.5rem 0 4.7rem;
  }

  ::-webkit-scrollbar {
    width: 0px;
    @media (min-width: 1024px) {
      width: 8px;
    }
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`;

export const SessionContainer = styled(Container)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  .blue {
    height: 330px;
    position: relative;
    background: linear-gradient(180deg, #202e65 0%, #171733 100%);
  }
  .white {
    height: 100%;
    position: relative;
    background: #fff;
  }
`;
