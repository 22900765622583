import React, { FC, useState, useEffect } from 'react';
import { Select } from 'antd';
import MaskedInput from 'antd-mask-input';

import { useAuth } from '../../../../../hooks/auth';

import './styles.scss';

const prefixOptions = ['55'];

const UserInfo: FC = () => {
  const { user } = useAuth();
  const { Option } = Select;

  const [numberPrefix, setNumberPrefix] = useState('55');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (user) {
      const sectNumber = user.phone.split('(');
      if (user.phone) {
        setNumberPrefix(sectNumber[0]);
        setPhoneNumber(sectNumber[1].replace(') ', '').replace('-', ''));
      }
    }
  }, [user]);

  const handlePrefixChange = (selectedItem: string) => {
    setNumberPrefix(selectedItem);
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const prefixSelector = (
    <Select
      style={{ width: 70 }}
      value={numberPrefix}
      onChange={(e) => handlePrefixChange(e)}
    >
      {prefixOptions.map((option) => {
        return (
          <Option key={option} value={option}>
            {option}
          </Option>
        );
      })}
    </Select>
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <div id="user-info" className="view-wrapper">
      <h1>Minha conta</h1>
      <form className="config-page-form" onSubmit={handleSubmit}>
        <label htmlFor="user-name" className="config-page-form-item">
          <p>Nome</p>
          <input type="text" id="user-name" name="Nome" value={user.name} />
        </label>

        <div className="config-page-form-item">
          <p>Telefone para contato</p>
          <MaskedInput
            addonBefore={prefixSelector}
            mask="(11) 11111-1111"
            style={{ width: '100%' }}
            value={phoneNumber}
            id="user-phone"
            name="Telefone para contato"
            placeholder="(xx) 99999-5555"
            onChange={(e) => handleNumberChange(e)}
          />
        </div>
        <div className="config-page-form-item button-wrapper">
          <button type="submit">Salvar</button>
        </div>
      </form>
    </div>
  );
};

export default UserInfo;

// avatar_url: null
// company: {id: "1e2de5b5-4308-4c8d-ac90-60144d00a7cf", name: "Lucas", parent_company_id: null, social_url: "lucas", phone: null, …}
// company_id: "1e2de5b5-4308-4c8d-ac90-60144d00a7cf"
// created_at: "2020-10-06T19:57:33.986Z"
// email: "lucas@suiteshare.com"
// id: "37765523-d409-4941-ba3d-5583ae0648bf"
// name: "Lucas"
// phone: "55(51) 98265-7666"
// updated_at: "2020-10-06T19:57:33.986Z"
// __proto__: Object
