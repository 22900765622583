import React, { FC } from 'react';
import { ISpecification } from '../../../Types';
import VariantsRegistrationContent from '../SpecificationsRegisterContent';

import '../SpecificationsRegisterContent/styles.scss';

interface IProps {
  isOpen: boolean;
  closeModal: (recentlyCreatedSpecification?: ISpecification) => void;
  specificationName?: string;
}

const SpecificationsRegisterModal: FC<IProps> = (props) => {
  const { isOpen, closeModal, specificationName } = props;

  return (
    <div
      className="variants-modal "
      style={{ display: isOpen ? 'flex' : 'none' }}
    >
      <button
        className="variants-modal__mask"
        type="button"
        onClick={() => closeModal()}
      >
        x
      </button>

      <VariantsRegistrationContent
        closeModal={closeModal}
        specificationName={specificationName}
      />
    </div>
  );
};

export default SpecificationsRegisterModal;
