import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { IConnectLinks } from '../../../../../Types';

import { ConnectStoreContext } from '../../../../../stores/ConnectStore';

import {
  MobiPreview,
  MobiMask,
  MobiContent,
  ConnectMock,
  Header,
  Body,
  ConnectButton,
} from './styles';

import MobMockup from '../../../../../assets/mob-mockup.png';
import suitesharemockfooter from '../../../../../assets/logos/suiteshare-footer.png';

const MobPreview: React.FC = observer(() => {
  const connectStore = useContext(ConnectStoreContext);

  const renderMockupButtons = () => {
    if (connectStore.connectOptions?.links) {
      return connectStore.connectOptions?.links
        .slice()
        .sort((a: IConnectLinks, b: IConnectLinks) => a.position - b.position)
        .map((button: IConnectLinks) => {
          return (
            button.enabled && (
              <ConnectButton
                key={button.id}
                type="button"
                hightlight={button.highlight}
              >
                <a href={button.url}>{button.title}</a>
              </ConnectButton>
            )
          );
        });
    }
    return <div />;
  };

  return (
    <MobiPreview>
      <MobiMask src={MobMockup} alt="Mobile mockup" />
      <MobiContent>
        <ConnectMock>
          <Header
            background={[
              connectStore.connectOptions?.primary_color,
              connectStore.connectOptions?.secondary_color,
            ]}
            isRounded={connectStore.connectOptions?.rounded_header}
          >
            <div className="headerContent">
              <figure>
                {connectStore.connectOptions?.company_logo ? (
                  <img
                    src={
                      connectStore.logoPreview ||
                      `${process.env.REACT_APP_BUCKET_ASSETS_URL}${encodeURI(
                        connectStore.connectOptions.company_logo,
                      )}` ||
                      ''
                    }
                    alt={connectStore.connectOptions.company_logo || ''}
                  />
                ) : (
                  <div />
                )}
              </figure>
              <div className="socialMedias">
                <div className="icon">
                  <img src="" alt="" />
                </div>
              </div>
            </div>
          </Header>
          <Body
            background={[
              connectStore.connectOptions?.primary_color,
              connectStore.connectOptions?.secondary_color,
            ]}
            isRounded={connectStore.connectOptions?.rounded_header}
          >
            <div className="bodyContent">
              <div className="scroll">{renderMockupButtons()}</div>
              <div className="footer">
                <img src={suitesharemockfooter} alt="suiteshare" />
              </div>
            </div>
          </Body>
        </ConnectMock>
      </MobiContent>
    </MobiPreview>
  );
});

export default MobPreview;
