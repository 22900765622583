import React, { createElement, useEffect, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import { GeneralStoreContext } from '../stores/GeneralStore';
import useHeadUpdate from '../hooks/useHeadUpdate';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import { useAuth } from '../hooks/auth';
import ReminderStepsBox from '../components/Onboard';
import {
  Container,
  Content,
  PanelContainer,
  SessionContainer,
} from '../styles/default';
import CompanyServices from '../services/company';
import useVerifyStores from '../hooks/useVerifyStores';
import StoreServices from '../services/store/stores';
import { ICompanyStore, IStoreProps } from '../Types';
import TrialBanner from '../components/_Store/TrialBanner';

export const PrivateRoute = observer(({ component, ...rest }: any) => {
  const { user } = useAuth();
  const generalStore = useContext(GeneralStoreContext);

  useHeadUpdate();

  useEffect(() => {
    if (user) {
      CompanyServices.getCompanyData(user.company.social_url).then(
        async (response) => {
          generalStore.setCompanyData(response);

          if (response.stores) {
            if (response.parent_company_id) {
              await StoreServices.mergeStores(user.company_id).then(
                async (mergedStores) => {
                  if (mergedStores) {
                    const storeObjects: IStoreProps[] = [];
                    mergedStores.forEach((store: ICompanyStore) =>
                      storeObjects.push(store.store),
                    );
                    if (storeObjects) {
                      const storesData = await useVerifyStores(
                        user,
                        storeObjects,
                      );
                      generalStore.setCatalogsList(storesData.catalogs);
                      if (storesData.catalogId) {
                        generalStore.setCurrentCatalogId(storesData.catalogId);
                      }
                    }
                  }
                },
              );
            } else {
              const storesData = await useVerifyStores(user, response.stores);
              generalStore.setCatalogsList(storesData.catalogs);
              if (storesData.catalogId) {
                generalStore.setCurrentCatalogId(storesData.catalogId);
              }
            }
          }
        },
      );

      if (user.company.parent_company_id) {
        generalStore.setIsChild(true);
      } else {
        generalStore.setIsChild(false);
      }
    }
  }, [user, generalStore]);

  const routeComponent = (props: any) =>
    user ? (
      <Container>
        <Sidebar />
        <Content>
          <Header />
          <ReminderStepsBox />
          <PanelContainer>
            <TrialBanner />
            {createElement(component, props)}
          </PanelContainer>
        </Content>
      </Container>
    ) : (
      <Redirect
        to={{ pathname: `/${user ? generalStore.currApp : 'store'}/login` }}
      />
    );
  return <Route {...rest} render={routeComponent} />;
});

export const SessionRoute = ({ component, ...rest }: any) => {
  useHeadUpdate();

  const routeComponent = (props: any) => (
    <SessionContainer>{createElement(component, props)}</SessionContainer>
  );
  return <Route {...rest} render={routeComponent} />;
};
