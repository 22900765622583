import React, { FC, useState, useContext, useCallback } from 'react';
import { observer } from 'mobx-react';
import { message, Skeleton } from 'antd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { BsPencilSquare } from 'react-icons/bs';

import { ConnectStoreContext } from '../../../../../stores/ConnectStore';

import { useAuth } from '../../../../../hooks/auth';

import { IConnectLinks } from '../../../../../Types';

import LinkGroup from '../LinkGroup';

import {
  CreateContentWrapper,
  Title,
  CreateNewButton,
  LinkScroll,
} from './styles';

import ConnectEntitiesServices from '../../../../../services/connect/Entities';
import ConnectLinksServices from '../../../../../services/connect/Links';

const CreateContent: FC = observer(() => {
  const connectStore = useContext(ConnectStoreContext);

  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  const createNewButton = () => {
    const newLink: IConnectLinks = {
      id: '',
      company_id: user.company_id,
      connect_id: connectStore.connectOptions?.id,
      enabled: true,
      start_date: null,
      expiration_date: null,
      title: 'Título para o botão',
      url: '',
      highlight: false,
      position: 0,
      button_color: '#fff',
      font_color: '#000',
      thumbnail_image: '',
      total_clicks: 0,
    };

    ConnectLinksServices.postCompanyLinks(user.company_id, newLink)
      .then((newestLink: IConnectLinks) => {
        setLoading(true);
        const currentConnect = connectStore.connectOptions;
        currentConnect.links.unshift(newestLink);

        connectStore.setConnectOptions(currentConnect);
      })
      .finally(() => setLoading(false));
  };

  const updateButtonsState = useCallback(
    (button: IConnectLinks) => {
      const currentButtonsArray = connectStore.connectOptions?.links;

      const currIndex = currentButtonsArray
        .map((link: IConnectLinks) => link.id)
        .indexOf(button.id);

      ConnectLinksServices.putCompanyLinks(user.company_id, button).then(
        (response) => {
          currentButtonsArray[currIndex] = response;
          connectStore.setConnectOptions({
            ...connectStore.connectOptions,
            links: currentButtonsArray,
          });
          message.success('Link atualizado com sucesso');
        },
      );
    },
    [connectStore, user],
  );

  const removeButton = useCallback(
    (buttonId?: string) => {
      setLoading(true);

      if (buttonId) {
        ConnectLinksServices.deleteCompanyLink(user.company_id, buttonId)
          .then(() => {
            message.success('Link excluído com sucesso');
          })
          .then(async () => {
            await ConnectEntitiesServices.getConnectData(user.company_id).then(
              (response) => {
                connectStore.setConnectOptions({
                  ...connectStore.connectOptions,
                  links: response.links,
                });
              },
            );
          })
          .finally(() => setLoading(false));
      }
    },
    [connectStore, user.company_id],
  );

  const renderButtons = () => {
    if (connectStore && connectStore.connectOptions) {
      if (connectStore.connectOptions.links?.length > 0) {
        return connectStore.connectOptions?.links
          .slice()
          .sort((a: IConnectLinks, b: IConnectLinks) => a.position - b.position)
          .map((button: IConnectLinks, index: number) => {
            const currLink = button;

            currLink.position = index;

            ConnectLinksServices.putCompanyLinks(user.company_id, currLink);

            return loading ? (
              <Skeleton />
            ) : (
              <LinkGroup
                key={button.id}
                button={button}
                buttonId={button.id}
                index={index}
                removeButton={removeButton}
                updateButtonsState={updateButtonsState}
              />
            );
          });
      }
    }
    return <div />;
  };

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const quotes = reorder(
      connectStore.connectOptions?.links,
      result.source.index,
      result.destination.index,
    );

    quotes.forEach((link, index) => {
      const currLink = link;
      currLink.position = index;

      quotes[index] = currLink;

      connectStore.setConnectOptions({
        ...connectStore.connectOptions,
        links: quotes,
      });
      ConnectLinksServices.putCompanyLinks(user.company_id, link);
    });
  };

  return (
    <CreateContentWrapper>
      <Title>
        <h2>
          <span>
            <BsPencilSquare />
          </span>{' '}
          Criar conteúdo
        </h2>
      </Title>

      <CreateNewButton type="button" onClick={() => createNewButton()}>
        Criar botão/link
      </CreateNewButton>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="link-list">
          {(provided) => (
            <LinkScroll ref={provided.innerRef} {...provided.droppableProps}>
              {renderButtons()} {provided.placeholder}
            </LinkScroll>
          )}
        </Droppable>
      </DragDropContext>
    </CreateContentWrapper>
  );
});

export default CreateContent;
