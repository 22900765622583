import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import DataTable, { createTheme } from 'react-data-table-component';

import { Content, StyledForm, ActionBar } from './styles';

import { getOrders } from '../../../../../services/store/Orders';
import { useAuth } from '../../../../../hooks/auth';

interface IProps {
  changePage: (page: string) => void;
}

const OrdersList: React.FC<IProps> = (props) => {
  const { user } = useAuth();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    getOrders(user.company_id).then((res) => setOrders(res));
  }, [user.company_id]);

  createTheme('solarized', {
    text: {
      primary: '#000',
    },
    background: {
      default: '#fff',
    },
    divider: {
      default: '#e6e6e6e6',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  });

  const columns = [
    {
      name: 'Cliente',
      cell: (row: any) => <div>{row.customer ? row.customer.name : '-'}</div>,
    },
    {
      name: 'Data',
      selector: 'created_at',
      sortable: true,
    },
    {
      name: 'Valor',
      selector: 'total_value',
      sortable: true,
    },
  ];

  return (
    <Content>
      <ActionBar>
        <div className="text-wrapper action-bar">
          <h1>Pedidos</h1>
          <p>
            <span>0</span> de <span>200</span>
          </p>
        </div>
      </ActionBar>
      <StyledForm>
        {!!orders ? (
          <DataTable
            title=""
            columns={columns}
            data={orders}
            defaultSortField="title"
            theme="solarized"
            noDataComponent="Ainda não foram realizados pedidos!"
            pagination
            noHeader
          />
        ) : (
          <Skeleton active />
        )}
      </StyledForm>
    </Content>
  );
};

export default OrdersList;
