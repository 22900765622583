import React, { FC, ReactNode } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import './styles.scss';

interface IProps {
  children: ReactNode;
  closeModal: () => void;
}

const Modal: FC<IProps> = (props) => {
  const { children, closeModal } = props;

  return (
    <div className="generic-modal">
      <button
        className="generic-modal__mask"
        type="button"
        onClick={() => closeModal()}
      >
        x
      </button>
      <div className="generic-modal__body">
        <button
          className="btn btn--close"
          type="button"
          onClick={() => closeModal()}
        >
          <RiCloseLine />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
