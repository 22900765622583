import React, { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Skeleton, Empty, Input, Switch, Card, Avatar } from 'antd';
import { observer } from 'mobx-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FiSearch, FiImage, FiEye } from 'react-icons/fi';
import { ProductsStoreContext } from '../../../../../../stores/ProductsStore';
import { GeneralStoreContext } from '../../../../../../stores/GeneralStore';

import ProductsServices from '../../../../../../services/store/Products';
import StoreServices from '../../../../../../services/store/stores';

import { useAuth } from '../../../../../../hooks/auth';

import { ICompanyProducts } from '../../../../../../Types';

import { Content, StyledForm } from '../styles';
import CatalogSelect from '../../../../../../components/_Store/CatalogSelect';

import emptyProducts from '../../../../../../assets/empty/new-empty-products.png';

const { Meta } = Card;

const ChildProductList: FC = observer(() => {
  const [isTableLoading, setIsTableLoading] = useState(true);

  const generalStore = useContext(GeneralStoreContext);
  const productStore = useContext(ProductsStoreContext);
  const { user } = useAuth();
  const history = useHistory();

  const storeId = generalStore.currentCatalogId;

  useEffect(() => {
    if (generalStore.isChild && storeId) {
      StoreServices.mergeStores(user.company_id).then(async (response) => {
        if (response) {
          await ProductsServices.mergeChildProducts(
            user.company_id,
            storeId,
          ).then((res) => {
            if (res) {
              generalStore.setCompanyProducts(res);
              setIsTableLoading(false);
            }
          });
        }
      });
    }
  }, [
    productStore,
    user,
    user.company_id,
    user.company,
    generalStore,
    storeId,
  ]);

  const handleSwitch = (companyProductId: string, enabled: boolean) => {
    if (generalStore.companyProducts) {
      ProductsServices.editChildProduct(
        storeId,
        companyProductId,
        enabled,
      ).then(() => {
        ProductsServices.getChildProducts(user.company_id, storeId).then(
          (res) => {
            generalStore.setCompanyProducts(res);
          },
        );
      });
    }
  };

  const cleanValueString = (value: string) => {
    const cleanedValue = value
      .split('')
      .filter(
        (o: any) =>
          o !== 'R' && o !== '$' && o !== ' ' && o !== '.' && o !== ',',
      )
      .join('');
    return cleanedValue;
  };

  const FilterByNameInput = (
    <Input
      placeholder="Pesquisar produto"
      value={productStore.filterProductName}
      className="table-search"
      suffix={<FiSearch />}
      onChange={(e) => productStore.setFilterProductName(e.target.value)}
    />
  );
  const FilterByCategoryInput = (
    <Input
      placeholder="Pesquisar categoria"
      value={productStore.filterCategoryName}
      className="table-search"
      suffix={<FiSearch />}
      onChange={(e) => productStore.setFilterCategoryName(e.target.value)}
    />
  );

  const childColumns = [
    {
      width: 50,
      render: (companyProduct: ICompanyProducts) => (
        <figure className="table-picture">
          {companyProduct.product.images.length <= 0 ? (
            <div className="no-img">
              <FiImage />
            </div>
          ) : (
            <LazyLoadImage
              effect="blur"
              src={
                process.env.REACT_APP_BUCKET_ASSETS_URL +
                encodeURI(companyProduct.product.images[0]?.image_url)
              }
              alt={companyProduct.product.name}
            />
          )}
        </figure>
      ),
    },
    {
      title: 'Nome',
      width: 450,
      render: (companyProduct: ICompanyProducts) => (
        <div>
          {companyProduct?.product?.name ? companyProduct?.product?.name : ''}
        </div>
      ),
    },
    {
      title: 'Valor',
      sorter: (a: any, b: any) => {
        const valueA = cleanValueString(a.product.value);
        const valueB = cleanValueString(b.product.value);
        return Number(valueA) - Number(valueB);
      },
      render: (companyProduct: ICompanyProducts) => (
        <>
          {companyProduct.product.value !== '$0.00'
            ? parseFloat(
                companyProduct.product.value
                  .replace(/\$/g, '')
                  .replace(/,/g, ''),
              ).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })
            : 'Valor sob consulta'}
        </>
      ),
      showSorterTooltip: false,
      width: 200,
    },
    {
      title: 'Categoria',
      render: (companyProduct: ICompanyProducts) => {
        return (
          <div>
            {companyProduct.product.category?.description
              ? companyProduct.product.category?.description
              : ''}
          </div>
        );
      },
    },
    {
      title: 'Ver detalhes',
      render: (row: ICompanyProducts) => (
        <button
          className="tableButton"
          type="submit"
          onClick={() => history.push(`/store/products/${row.id}/view`)}
        >
          <FiEye />
        </button>
      ),
      width: 100,
    },
    {
      title: 'Habilitar',
      render: (companyProduct: ICompanyProducts) => (
        <div className="switch-wrapper">
          <Switch
            checked={companyProduct.enabled}
            onChange={() =>
              handleSwitch(companyProduct.id, !companyProduct.enabled)
            }
          />
        </div>
      ),
      width: 100,
    },
  ];

  const emptyTable = (
    <Empty
      image={emptyProducts}
      description="Você não possui produtos cadastrados."
    />
  );

  const tableData = generalStore.companyProducts?.filter(
    (product: ICompanyProducts) => {
      return (
        (product.product.name
          .toLowerCase()
          .includes(productStore.filterProductName.toLowerCase()) ||
          product.product.code
            .toLowerCase()
            .includes(productStore.filterProductName.toLowerCase())) &&
        product.product.category?.description
          .toLowerCase()
          .includes(productStore.filterCategoryName.toLowerCase())
      );
    },
  );

  const renderTable = () => {
    return (
      <Table
        columns={childColumns}
        dataSource={tableData}
        className="suiteshare-table"
        locale={{ emptyText: emptyTable }}
      />
    );
  };

  const renderCards = () => {
    return tableData?.map((companyProduct: ICompanyProducts) => {
      return (
        <Card
          key={companyProduct.id}
          className="suiteshare-card mobile"
          style={{ width: '100%', maxWidth: 400, marginTop: 16 }}
          actions={[
            <div className="switch-wrapper">
              <Switch
                checked={companyProduct.enabled}
                onChange={() =>
                  handleSwitch(companyProduct.id, !companyProduct.enabled)
                }
              />
            </div>,
          ]}
        >
          <Skeleton loading={isTableLoading} avatar active>
            <Meta
              avatar={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Avatar
                  shape="square"
                  src={
                    process.env.REACT_APP_BUCKET_ASSETS_URL +
                    encodeURI(companyProduct.product.images[0]?.image_url)
                  }
                />
              }
              title={companyProduct.product.name}
              description={parseFloat(
                companyProduct.product.value
                  .replace(/\$/g, '')
                  .replace(/,/g, ''),
              ).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            />
          </Skeleton>
        </Card>
      );
    });
  };

  return (
    <Content>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '0 0 2rem',
        }}
      >
        <CatalogSelect />
      </div>
      <div className="product-filters">
        <div className="product-filters__wrapper">{FilterByNameInput}</div>
        <div className="product-filters__wrapper">{FilterByCategoryInput}</div>
      </div>
      <StyledForm>
        {isTableLoading ? <Skeleton active /> : renderTable()}

        {generalStore.companyProducts?.length <= 0 ? (
          <div className="empty-table-mobile">{emptyTable}</div>
        ) : (
          renderCards()
        )}
      </StyledForm>
    </Content>
  );
});

export default ChildProductList;
