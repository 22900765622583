import React, { FC, useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Steps } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import { useAuth } from '../../../hooks/auth';

import { getOrders } from '../../../services/store/Orders';
import { getStoreData } from '../../../services/store/Configuration';

import { ConfigurationStoreContext } from '../../../stores/ConfigurationStore';

import './styles.scss';

interface IProps {
  shouldBoxOpen: (value: boolean) => void;
}

const StoreSteps: FC<IProps> = observer((props) => {
  const configStore = useContext(ConfigurationStoreContext);
  const { shouldBoxOpen } = props;

  const [orders, setOrders] = useState([]);

  const { user } = useAuth();
  const { Step } = Steps;

  const verifyData = (data: string | null | object[]) => {
    if (!data || data.length < 1) {
      shouldBoxOpen(true);
    }
  };

  useEffect(() => {
    getStoreData(user.company).then((response) => {
      if (response) {
        configStore.setStoreObject(response);
        verifyData(response?.company_logo);
        verifyData(response?.redirect_phone);
        verifyData(response?.redirect_url);
      }
      // if (!response) {
      //   postStoreData(user.company_id, newStoreObject).then((res) => {
      //     configStore.setStoreObject(res);
      //     verifyData(res?.company_logo);
      //     verifyData(res?.redirect_phone);
      //     verifyData(res?.redirect_url);
      //   });
      // }
    });
    getOrders(user.company_id).then((res) => {
      setOrders(res);
      verifyData(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, configStore]);

  const icon = (
    <div className="icon-steps">
      <CheckOutlined />
    </div>
  );

  return (
    <Steps direction="vertical" size="small" current={0}>
      <Step
        title="Logo da empresa"
        description="Insira o logo da sua empresa. Ele vai aparecer na Home da sua Store"
        icon={icon}
        className={configStore?.store?.company_logo ? 'done' : ''}
      />
      <Step
        title="Cadastre um vendedor"
        description="Assim você habilita a venda pelo WhatsApp"
        icon={icon}
        className={
          configStore?.store?.redirect_url !== null ||
          configStore?.store?.redirect_phone !== null
            ? 'done'
            : ''
        }
      />
      <Step
        title="Teste sua Store"
        description="Faça um pedido e teste como ficou sua Store"
        icon={icon}
        className={orders?.length > 0 ? 'done' : ''}
      />
    </Steps>
  );
});

export default StoreSteps;
