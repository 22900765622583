import styled from 'styled-components';

interface ICircleButtonProps {
  background: string[];
  isActive: boolean;
}

export const CircleButtonWrapper = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CircleButton = styled.button<ICircleButtonProps>`
  width: ${(props) => (props.isActive ? '40px' : '30px')};
  height: ${(props) => (props.isActive ? '40px' : '30px')};
  border: none;
  border: 1px solid #cbcbcb;
  border-radius: 50%;
  outline: none !important;
  background: linear-gradient(
    141.65deg,
    ${(props) => props.background[0] || `#fff`} 1.62%,
    ${(props) => props.background[1] || `#fff`} 104.12%
  );
  transition: all 0.1s ease;
  &:hover {
    width: 40px;
    height: 40px;
  }
`;
