import styled from 'styled-components';
import { Container } from '../../../../../styles/default';
import { fadeIn } from '../../../../../styles/animations';

export const OrdersListContainer = styled(Container)`
  flex: 0 0 100%;
  flex-wrap: wrap;
  padding: 0 2.5rem 0 4.7rem;
  align-items: flex-start;
  background: #fbfbfb;
`;

export const Content = styled.div`
  width: 100%;
  margin: 3rem 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: ${fadeIn} 0.5s;
`;

export const StyledForm = styled.div`
  width: 100%;
  min-height: 10rem;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  color: #000000;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.1);

  .rdt_TableHeadRow {
    box-shadow: 0px 4px 4px #f5f5f5, 0px -4px 4px rgba(0, 0, 0, 0.05);
  }
  #column-undefined,
  #column-total_value,
  #column-created_at {
    div {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 110%;
        height: 3px;
        background: #ffb822;
        bottom: -4px;
        left: 0;
      }
    }
  }
  .rdt_TableBody {
    background: linear-gradient(
      0deg,
      rgba(211, 211, 211, 0.19) 0%,
      rgba(255, 255, 255, 0.51) 71.62%
    ) !important;
  }
`;

export const ActionBar = styled.div`
  width: 100%;
  padding: 0 0 0 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  .text-wrapper {
    font-family: 'Poppins', sans-serif;
    text-align: left;
    h1 {
      font-size: 1.3rem;
      font-weight: 600;
    }
    p {
      font-size: 0.9rem;
      font-weight: 400;
      span {
        font-family: 'Lato', sans-serif;
        font-size: 0.9rem;
      }
    }
  }
`;

export const VisitStore = styled.a`
  outline: none;
  border: none;
  display: flex;
  background: #ffa800;
  border-radius: 5px;
  padding: 0.5rem 2rem;
  font-weight: 600;
  font-size: 0.8rem;
  color: #f5f5f5 !important;
  text-decoration: none !important;
  box-shadow: 0px 4px 4px rgba(254, 169, 34, 0.25);
  :focus {
    outline: none;
  }
`;
