import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import StoreSteps from './store';

import { ReactComponent as Close } from '../../assets/close-btn.svg';

interface IUrlParams {
  app: string;
}

const ReminderStepsBox: FC = () => {
  const { app } = useParams<IUrlParams>();

  // const [isBoxOpened, setIsBoxOpened] = useState(false);

  const handleStepBoxState = (value: boolean) => {
    return false;
    // setIsBoxOpened(value);
  };

  return (
    <div id="onboard-steps">
      <div className="button-wrapper">
        <button
          className="close-button"
          type="button"
          // onClick={() => setIsBoxOpened(false)}
        >
          <Close />
        </button>
      </div>
      <div id="onboard-box">
        <div className="text-wrapper">
          <h2>Termine seu cadastro ;)</h2>
          <p>Faça as etapas a seguir para completar seu cadastro.</p>
        </div>
        {app === 'store' && <StoreSteps shouldBoxOpen={handleStepBoxState} />}
      </div>
      <div className="button-wrapper">
        <Link
          to="/store/config"
          // onClick={() => setIsBoxOpened(false)}
        >
          Começar
        </Link>
      </div>
    </div>
  );
};

export default ReminderStepsBox;
