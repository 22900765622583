import styled from 'styled-components';
import { Container } from '../../../styles/default';
import { fadeIn, appearFromDown } from '../../../styles/animations';

export const ProductResgistrationContainer = styled(Container)`
  flex: 0 0 100%;
  flex-wrap: wrap;
  padding: 0 2.5rem;
  margin: 5rem 0 0 0;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.5s;
  @media (min-width: 1024px) {
    height: 100%;
  }

  .text-wrapper {
    h1 {
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
`;

export const IndividualContent = styled(Content)`
  padding: 1rem 0 0;
  @media (min-width: 1024px) {
    padding: 2.5rem 0 0;
  }
`;

export const StyledForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (min-width: 1024px) {
    height: 46rem;
  }

  #product-registration {
    width: 65%;
    height: 100%;
    #product-form {
      width: 100%;
      height: calc(100% - 120px);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .steps {
      width: 100%;
      margin: 2rem 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .button-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .circle {
          width: 2rem;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 1px solid transparent;
          transition: border 0.2s ease;
        }
        button {
          position: relative;
          border: none;
          background: #c4c4c4;
          color: #fff;
          outline: none;
          border-radius: 50%;
          width: 1.2rem;
          height: 1.2rem;
          text-align: center;
          font-size: 0.7rem;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background 0.2s ease;
          z-index: 2;
        }
        p {
          color: #232323;
          font-size: 0.9rem;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          &:after {
            content: '';
            position: absolute;
            width: 94%;
            height: 2px;
            background: #c4c4c4;
            right: -47%;
            top: 26%;
            z-index: 1;
          }
        }
        &:last-child {
          p::after {
            content: none;
          }
        }
        &.active {
          button {
            background: #fd397a;
          }
          p.active {
            &:after {
              background: #fd397a;
            }
          }
        }
      }
    }
  }
`;

export const Step = styled.div`
  width: 60%;
  min-width: 35rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  animation: ${appearFromDown} 0.5s;
  &.active {
    animation: none;
  }
  textarea {
    width: 100%;
    min-height: 8rem;
    padding: 1.4rem 1.5rem;
    margin: 1rem 0 1rem;
    border: 1px solid rgba(170, 170, 170, 0.6);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 2px;
  }
`;

export const CreateNewButton = styled.button`
  background-color: #fff;
  border: 1px solid #fd397a;
  width: 100%;
  color: #fd397a;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  padding: 0.5rem;
  outline: none !important;
`;

export const IntagramShareButton = styled(CreateNewButton)`
  border: none;
  padding: 0;
  background: linear-gradient(88.65deg, #fd397a 0%, #6f42eb 99.75%);
  box-shadow: 0px 4px 4px rgba(179, 62, 180, 0.25);
  img {
    width: 1.5rem;
    margin: 0 1rem 0 0;
  }
  a {
    width: 100%;
    height: 100%;
    padding: 0.7rem;
    display: inline-block;
    box-sizing: border-box;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    text-decoration: none;
  }
`;

export const ShareLink = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #fff;
  position: relative;
  border: 1px solid #fd397a;
  border-top: none;
  border-bottom: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 400;
  color: #232323;
  outline: none !important;
  margin: 0 0 3rem;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(179, 179, 180, 0.25);
  input {
    width: 100%;
    height: 100%;
    border: none;
    text-align: center;
    background: transparent;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  svg {
    position: absolute;
    z-index: 1;
    font-size: 1.5rem;
  }
`;

export const SwitchArabesque = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 1.5rem;
  p {
    font-size: 0.7rem;
    margin: 0;
  }
`;

export const Colors = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ColorsWrapper = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  p {
    font-size: 0.7rem;
  }
`;

export const ColorsRow = styled.div`
  width: 100%;
  min-height: 45px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
`;

export const LinkScroll = styled.div`
  width: 100%;
  height: 20rem;
  padding: 0 1rem;
  overflow-y: scroll;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const Display = styled.div`
  width: 50%;
  height: 85%;
  max-height: 600px;
  margin: auto;
  padding: 0 0 0 3rem;
  border-left: 1px solid #dadada;
  display: none;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const Title = styled.div`
  width: 100%;
  h2 {
    display: flex;
    flex: 1;
    width: 100%;
    margin: 0 0 2.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 21px;
    align-items: center;
    color: #171733;
    span {
      font-family: 'Poppins', sans-serif;
      margin-right: 0.5rem;
    }
  }
`;

export const Subtitle = styled.div`
  width: 100%;
  text-align: left;
  line-height: 21px;
  color: #232323;
  margin: 0 0 1rem;
  p {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  span {
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

export const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  a {
    font-size: 1rem;
    font-weight: 600;
    color: #232323;
  }
`;

export const TextInput = styled.input`
  border: 1px solid rgba(170, 170, 170, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  width: 100%;
  padding: 0.4rem 0.8rem;
  margin: 0rem 0 2.5rem;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 8rem;
  padding: 1rem 0rem;
  margin: 0rem 0 2.5rem;
  border: 1px solid rgba(170, 170, 170, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
`;

export const NavigationButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1rem 0;
  justify-content: space-around;
`;

export const BackButton = styled.button`
  svg {
    color: #fd397a;
    margin-right: 0.3rem;
  }
  border: none;
  background: none;
  font-size: 0.8rem;
  font-weight: 600;
  outline: none;
  &:active {
    outline: none;
  }
  &:hover {
    outline: none;
  }
`;

export const Button = styled.button`
  width: 180px;
  height: 36px;
  background: #fd397a;
  border: 0;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 19px;
  color: #f5f5f5;
`;
