import React, { FC } from 'react';

import { ImgContainer } from './styles';

import storeImg from '../../../assets/backgrounds/store-session-bg.png';
import connectImg from '../../../assets/backgrounds/connect-session-bg.gif';
import connectLine from '../../../assets/backgrounds/line-bg-connect.png';
import lineBg from '../../../assets/backgrounds/line-bg.png';

interface IProps {
  app: string;
  parentLogo?: string | null | undefined;
  parentName?: string | null | undefined;
}

const assetsUrl = process.env.REACT_APP_BUCKET_ASSETS_URL;

const SideImage: FC<IProps> = (props) => {
  const { app, parentLogo, parentName } = props;

  return (
    <ImgContainer
      style={{
        background: app === 'connect' ? '#ebebf2' : '#fff',
      }}
    >
      {parentLogo && parentName ? (
        <div className="parent-company-presentation">
          <h1>Cadastre sua filial da empresa {parentName}</h1>

          <img
            src={`${assetsUrl}${encodeURI(parentLogo)}`}
            className="parent-company-logo"
            alt={parentName}
          />

          <p>Cadastre-se agora e comece a utilizar!</p>
        </div>
      ) : (
        <figure>
          <img
            src={app === 'connect' ? connectLine : lineBg}
            className="line-bg"
            alt=""
          />
          <img
            className={app === 'connect' ? 'connect' : ''}
            src={app === 'connect' ? connectImg : storeImg}
            alt="Três telefones celulares lado a lado mostrando a plataforma de loja da Suiteshare"
          />
        </figure>
      )}
    </ImgContainer>
  );
};

export default SideImage;
