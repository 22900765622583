import styled from 'styled-components';

interface IConnectButtonProps {
  hightlight: boolean;
}

interface IConnectMockProps {
  background: string[];
  isRounded: boolean;
}

export const MobiPreview = styled.div`
  height: 100%;
  max-width: 320px;
  /* max-height: 575px; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobiMask = styled.img`
  max-width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`;

export const MobiContent = styled.div`
  height: 95%;
  width: 87%;
  position: absolute;
  z-index: 1;
  color: #a1a1a1;
  p {
    margin: 0;
    word-break: break-all;
  }
`;

export const ConnectMock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div<IConnectMockProps>`
  width: 100%;
  height: 30%;
  background: linear-gradient(
    141.65deg,
    ${(props) => props.background[1] || '#6f42eb'} 1.62%,
    ${(props) => props.background[0] || '#fd397a'} 158.12%
  );
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem 1.5rem 0 0;
  .headerContent {
    width: 100%;
    height: 100%;
    border-radius: ${(props) =>
      props.isRounded ? '1.5rem 1.5rem 2rem 0' : '1.5rem 1.5rem 0 0'};
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    figure {
      width: 60%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .socialMedias {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 0 0;
      .icon {
        img {
        }
      }
    }
  }
`;

export const Body = styled.div<IConnectMockProps>`
  width: 100%;
  height: 70%;
  background: #fff;
  border-radius: 0 0 1.5rem 1.5rem;
  transition: all 0.3s ease;
  .bodyContent {
    background: linear-gradient(
      141.65deg,
      ${(props) => props.background[0] || '#fd397a'} 1.62%,
      ${(props) => props.background[1] || '#6f42eb'} 104.12%
    );
    border-radius: ${(props) =>
      props.isRounded ? '2rem 0 1.5rem 1.5rem' : '0 0 1.5rem 1.5rem'};
    width: 100%;
    height: 100%;
    padding: 2rem 1rem 0;
    display: grid;
    grid-template-rows: 10fr 1fr;
    grid-template-columns: 1fr;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    transition: all 0.3s ease;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.5);
      &:hover {
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .scroll {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0;
      background: transparent;
      img {
        width: 60px;
        height: auto;
      }
    }
  }
`;

export const ConnectButton = styled.button<IConnectButtonProps>`
  border: none;
  width: 100%;
  background: transparent;
  padding: 0.5rem;
  background: transparent;
  border: ${(props) => (props.hightlight ? '1px solid #fff' : 'none')};
  a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #000;
    font-size: 0.7rem;
    text-decoration: none;
    padding: 0.5rem 0;
    font-weight: ${(props) => (props.hightlight ? 600 : 400)};
  }
`;
