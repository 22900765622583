import styled from 'styled-components';
import { Container } from '../../../../../styles/default';
import { Container as InputContainer } from '../../../../../components/Input/styles';
import { StyledForm as categmodal } from '../../../../../components/_Store/CategoryForm/styles';
import { fadeIn, appearFromDown } from '../../../../../styles/animations';

export const ProductResgistrationContainer = styled(Container)`
  flex: 0 0 100%;
  flex-wrap: wrap;
  padding: 0 2.5rem;
  margin: 5rem 0 0 0;
  align-items: center;
`;

export const Content = styled.div`
  padding: 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.5s;
  @media (min-width: 1024px) {
    height: 100%;
    padding: 3rem 0;
  }

  .price-line {
    position: relative;
    &.active {
      color: #aaaaaa;
      input {
        text-decoration: line-through;
      }
    }
  }

  .alert-invalid-price {
    font-size: 0.8rem;
    visibility: hidden;
    padding: 0.3rem 0.5rem;
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    position: relative;
    top: 1rem;
    &.isvisible {
      visibility: visible;
    }
  }
  .text-wrapper {
    margin: 0;
    text-align: center;
    @media (min-width: 1024px) {
      text-align: unset;
      margin: 0 0 2.4rem 2rem;
    }
    h1 {
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
`;

export const CheckBox = styled.div`
  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input {
      &#consult-price {
        width: 2rem !important;
      }
    }
    p {
      margin: 0;
    }
  }
`;

export const StyledForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (min-width: 1024px) {
    min-height: 40rem;
  }
  .ant-upload-list-item-actions {
    a {
      display: none;
    }
  }
  #product-registration {
    width: 100%;
    @media (min-width: 1024px) {
      width: 65%;
    }

    #product-form {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .label-content {
      position: absolute;
      flex-direction: column;
      .arrow-up {
        position: relative;
        bottom: 18px;
        left: 48px;
        color: #a1a1a1;
      }
      .arrow-down {
        position: relative;
        top: 18px;
        right: 48px;
        color: #a1a1a1;
      }
      .center-image {
        margin-left: 0.5rem;
        color: #a1a1a1;
      }
      p {
        text-align: center;
        font-size: 0.6rem;
        color: #afafaf;
        padding: 0 1rem;
      }
    }
    .steps {
      width: 65%;
      height: 32px;
      margin: 2rem auto;
      max-width: 706px;
      display: grid;
      border: 1px solid #ffc453;
      border-radius: 15px;
      display: none;
      justify-content: space-evenly;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      @media (min-width: 1024px) {
        display: grid;
      }
      .button-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        button {
          position: relative;
          border: none;
          background: #fff;
          color: #232323;
          outline: none;
          cursor: pointer;
          border-radius: 15px;
          width: 100%;
          height: 30px;
          text-align: center;
          /* font-size: 0.7rem; */
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          transition: background 0.2s ease;
          z-index: 2;
        }
      }
      p {
        color: #232323;
        font-size: 0.9rem;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .active {
        button {
          color: #fff;
          background: #ffc52d;
        }
        p {
          color: #fff;
        }
      }
    }
  }
`;

export const Step = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  animation: ${appearFromDown} 0.5s;
  @media (min-width: 1024px) {
    width: 60%;
    min-width: 35rem;
  }

  &.active {
    animation: none;
  }
  ${InputContainer} {
    border-radius: 5px;
    margin: 0 0 1rem;
  }

  .currency-input-wrapper {
    margin-bottom: 1rem;
    width: 100%;
    &.is-disabled {
      .currency-input {
        visibility: hidden;
      }
    }
  }

  input {
    width: 100% !important;
  }

  .ant-select {
    width: 100%;
    margin: 0 0 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .ant-select-selector {
      border: 1px solid rgb(170 170 170);
      height: 3.5rem;
      padding: 0 1rem;
      border-radius: 0.3rem;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
      .ant-select-selection-placeholder {
        font-family: 'Poppins', sans-serif;
        color: #666360;
      }
    }
    .ant-select-arrow {
      width: 1rem;
      height: 1rem;
      top: auto;
      right: 1rem;
      margin-top: 0;
      font-size: 1rem;
    }
  }
  textarea {
    width: 100%;
    min-height: 8rem;
    padding: 1.4rem 1.5rem;
    margin: 1rem 0 1rem;
    border: 1px solid rgba(170, 170, 170, 0.6);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    transition: border 0.2s ease;
    &:focus {
      border-color: #00bf54;
    }
  }
`;

export const AddNewCategory = styled.div`
  button {
    outline: none !important;
    border: none;
    border-radius: 0.2rem;
    padding: 0.5rem 1rem;
    background: transparent;
    font-size: 0.8rem;
    color: #232323;
    svg {
      margin: 0 0.5rem 0 0;
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

export const ProductImages = styled.div`
  margin: 0 0 1rem;
  ul {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    li {
      width: 150px;
      height: 150px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.3rem 0.5rem;
      .delete-button-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: all 0.3s ease;
        z-index: 2;
        button {
          background: none;
          border: none;
          color: #fff;
        }
        svg {
          :hover {
            color: #f38383;
          }
        }
      }
      .lazy-load-image-background {
        position: relative;
        z-index: 1;
      }
      img {
        width: 100%;
        height: 100%;
        border: 1px solid #c5c5c5;
        object-fit: cover;
        object-position: center;
      }
      &:hover {
        .delete-button-mask {
          opacity: 1;
        }
      }
    }
  }
  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: #232323;
    margin: 0;
  }
  label {
    width: 150px;
    height: 150px;
    border: 2px dashed #a1a1a1;
    background: transparent;
    padding: 0;
    margin: 0;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    svg {
      width: 2rem;
      height: 2rem;
      color: #929292;
    }
    &:active {
      box-shadow: 0px 0px 4px #a5a5a5;
    }
  }
  input[type='file'] {
    display: none;
  }
`;

export const UploadImage = styled.div`
  width: 100%;
  text-align: left;
  max-width: 44rem;
  margin-bottom: 2rem;
  .text-button {
    background: transparent;
    border: none;
    display: flex;
    align-items: baseline;
    p {
      font-size: 0.9rem;
      text-decoration: underline;
      cursor: pointer;
    }
    svg {
      margin: 0.5rem;
    }
  }
  .input-url {
    display: flex;
    flex-direction: row;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);

    input {
      background: #ffffff;
      border: none;
      padding-left: 1rem;
      box-sizing: border-box;
      font-size: 0.8rem;

      border-radius: 2px;
      ::placeholder {
        color: #676767;
      }
    }
    button {
      width: 91px;
      height: 31px;
      background: #ffc52d;
      border: 0;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 19px;
      color: #f5f5f5;
    }
    .active-input {
    }
  }
`;

export const CategoryRegistrationModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
  transition: opacity 0.5s ease;
  &.active {
    opacity: 1;
    z-index: 100000;
  }
  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 21;
  }
  .categ-modal-body {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 3px 6px 6px #767676;
    position: relative;
    padding: 1rem;
    z-index: 22;
    @media (min-width: 1024px) {
      width: 430px;
      height: auto;
    }

    .categ-modal-header {
      max-width: 385px;
      margin: 0 auto 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 1rem;
        font-weight: 600;
        color: #232323;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        svg {
          color: #fda829;
          margin-right: 0.5rem;
          font-size: 1.8rem;
        }

        @media (min-width: 1024px) {
          font-size: 1.2rem;
        }
      }
      .close-btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: none;
        background: transparent;
      }
    }

    #code-preview {
      font-size: 0.8rem;
      font-weight: 400;
      margin: 0 0 1rem;
    }

    ${categmodal} {
      border: none;
      box-shadow: none;
      margin: 0;
      form {
        width: 100%;
      }
    }
  }
`;

export const Display = styled.div`
  display: none;
  width: 35%;
  height: 85%;
  max-height: 680px;
  margin: auto;
  border-left: 1px solid #dadada;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const MobiPreview = styled.div`
  max-width: 85%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobiMask = styled.img`
  max-width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`;

export const MobiContent = styled.div`
  height: 95%;
  width: 87%;
  position: absolute;
  display: grid;
  grid-template-rows: 3fr 0.75fr 1fr 0.6fr;
  z-index: 0;
  color: #a1a1a1;
  background: #fff;

  p {
    margin: 0;
    word-break: break-all;
  }
  &::before {
    content: '';
    position: absolute;
    background: radial-gradient(#d5d5d5, transparent);
    width: 100%;
    height: 40%;
    right: -4rem;
    z-index: -23;
    border-radius: 2rem;
    transform: skewX(-30deg);
    bottom: -1.5rem;
  }
  .slider {
    background: #aaa;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 32px 32px 20px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 32px 32px 20px 20px;
    }
  }
  .product-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem 1rem 0.5rem;
    background: #fff;
    .product-name {
      max-width: 50%;
      p {
        color: #a1a1a1;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }
    .product-price {
      max-width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      background: #fff;
      p {
        font-weight: 600;
        font-size: 0.9rem;
      }
      span {
        font-size: 0.7rem;
      }
    }
    .product-consult {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      p {
        font-size: 0.8rem;
      }
    }
  }
  .product-description {
    padding: 0 1rem;
    overflow: hidden;
    background: #fff;
    span {
      display: flex;
      align-items: center;
      font-size: 0.7rem;
      font-weight: 500;
      margin: 0.5rem 0;
      svg {
        margin: 0 0 0 0.3rem;
      }
    }
    p {
      font-size: 0.8rem;
    }
  }
  .fixed-action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    background: #fff;
    button {
      border: none;
      font-size: 0.5rem;
      &.basket {
        width: 100%;
        background: #000;
        color: #fff;
        padding: 0.4rem 0.6rem;
      }
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  h2 {
    display: flex;
    flex: 1;
    width: 100%;
    margin: 1rem 0 0.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 21px;
    align-items: center;
    color: #171733;
    position: relative;
    left: 1.5rem;
    @media (min-width: 1024px) {
      margin: 0 0 0.5rem;
      left: 0;
      font-size: 1.2rem;
    }
    span.number {
      position: absolute;
      left: -1.5rem;
      color: #232323;
      font-weight: 800;
      font-size: 0.7rem;
      background: #fff;
      border: 1px solid #ffb900;
      padding: 0.1rem 0.3rem;
      border-radius: 0.2rem;
      width: 1.1rem;
      height: 1.1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Roboto', sans-serif;
      @media (min-width: 1024px) {
        display: none;
      }
    }

    span {
      font-family: 'Lato', sans-serif;
      margin-right: 0.5rem;
    }
  }
`;

export const Subtitle = styled.div`
  width: 100%;
  text-align: left;
  max-width: 35rem;
  line-height: 21px;
  color: #232323;
  margin: 0 0 1rem;
  p {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  span {
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

export const TextInput = styled.input`
  border: 1px solid rgba(170, 170, 170, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  width: 100%;
  padding: 0.4rem 0.8rem;
  margin: 0rem 0 2.5rem;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 8rem;
  padding: 1rem 0rem;
  margin: 0rem 0 2.5rem;
  border: 1px solid rgba(170, 170, 170, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
`;

export const NavigationButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1rem 0;
  justify-content: space-between;
`;

export const BackButton = styled.button`
  svg {
    color: #ffa800;
    margin-right: 0.3rem;
  }
  border: none;
  background: none;
  font-size: 0.8rem;
  font-weight: 600;
  outline: none;
  &:active {
    outline: none;
  }
  &:hover {
    outline: none;
  }
`;

export const Button = styled.button`
  width: 180px;
  height: 36px;
  background: #ffa800;
  border: 0;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 19px;
  color: #f5f5f5;
  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
