export default class Paths {
  static readonly login = {
    url: '/',
    name: ['Login'],
  };

  static readonly signUp = {
    url: '/signup',
    name: ['Registrar'],
  };

  static readonly forgotPassword = {
    url: '/forgot-password',
    name: ['Esqueci Senha'],
  };

  static readonly resetPassword = {
    url: '/reset-password',
    name: ['Resetar Senha'],
  };

  static readonly dashboard = {
    url: '/dashboard',
    name: ['Dashboard'],
  };

  static readonly configurations = {
    url: '/configurations',
    name: ['Configurações'],
  };

  static readonly store = {
    url: '/store',
    name: ['Store'],
  };

  static readonly products = {
    url: '/store/products',
    name: ['Store', 'Produtos'],
  };

  static readonly productRegistration = {
    url: '/store/products/register',
    name: ['Store', 'Produtos', 'Cadastrar'],
  };

  static readonly categories = {
    url: '/store/categories',
    name: ['Store', 'Categorias'],
  };

  static readonly categoriesRegistration = {
    url: '/store/categories/registration',
    name: ['Store', 'Categorias', 'Cadastrar'],
  };

  static readonly orders = {
    url: '/store/orders',
    name: ['Store', 'Pedidos'],
  };

  static readonly connect = {
    url: '/connect',
    name: ['Connect'],
  };
}
