import styled from 'styled-components';
import { fadeIn } from '../../../../../styles/animations';

export const CreateContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  animation: ${fadeIn} 0.6s;
  @media (min-width: 1024px) {
    padding: 0 5rem;
  }
`;

export const Title = styled.div`
  width: 100%;
  h2 {
    display: flex;
    flex: 1;
    width: 100%;
    margin: 0 0 2.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 21px;
    align-items: center;
    color: #171733;
    span {
      font-family: 'Poppins', sans-serif;
      margin-right: 0.5rem;
      color: #fd397a;
    }
  }
`;

export const Subtitle = styled.div`
  width: 100%;
  text-align: left;
  line-height: 21px;
  color: #232323;
  margin: 0 0 1rem;
  p {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  span {
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

export const CreateNewButton = styled.button`
  width: 100%;
  border: 1px solid #fd397a;
  border-radius: 4px;
  background-color: #fff;
  color: #fd397a;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  padding: 0.5rem;
  outline: none !important;
  transition: all 0.3s ease;
  &:active,
  &:hover {
    background-color: #fd397a;
    color: #fff;
  }
`;

export const LinkScroll = styled.div`
  width: 100%;
  height: calc(90% - 100px);
  overflow-y: scroll;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;
