import React, { FC, useEffect, useState } from 'react';
import { Empty, message, Skeleton } from 'antd';
import { FiSearch } from 'react-icons/fi';

import { useAuth } from '../../../../../hooks/auth';
import { ISeller } from '../../../../../Types';

import SellersServices from '../../../../../services/store/Sellers';

import SellerCard from './SellerCard';
import Modal from '../../../../../components/Modal';
import SellersRegistration from '../../../../../components/SellersRegistration';

import { ReactComponent as HeadSetIcon } from '../../../../../assets/icons/svg-icons/headphones.svg';
import { ReactComponent as WhatsIcon } from '../../../../../assets/icons/svg-icons/whatsapp.svg';
import { ReactComponent as EmptySellers } from '../../../../../assets/icons/svg-icons/empty-seller-list.svg';

import './styles.scss';

const SellersList: FC = () => {
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sellerToEdit, setSellerToEdit] = useState<ISeller>();
  const [sellers, setSellers] = useState<ISeller[]>([]);
  const [sellerFilter, setSellerFilter] = useState('');
  const [sellersLimit, setSellersLimit] = useState(1);

  useEffect(() => {
    if (!user.company.plan_id) {
      setSellersLimit(1);
    }

    if (user.company.plan) {
      setSellersLimit(user.company.plan.sellers_limit);
    }

    SellersServices.getSellers(user.company_id)
      .then((sellersData) => {
        setSellers(sellersData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user]);

  const closeModal = () => {
    setModalIsOpen(false);
    setSellerToEdit(undefined);
  };

  const updateSellers = async () => {
    setIsLoading(true);
    setModalIsOpen(false);
    await SellersServices.getSellers(user.company_id)
      .then((sellersArray) => {
        setSellers(sellersArray);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const editSeller = async (seller: ISeller) => {
    if (seller) {
      await SellersServices.putSeller(seller).then((response) => {
        if (response) {
          message.success('Vendedor atualizado com sucesso');

          setIsLoading(true);
          setModalIsOpen(false);

          SellersServices.getSellers(user.company_id)
            .then((sellersData) => {
              setSellers(sellersData);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      });
    }
  };

  const triggerEdit = (seller: ISeller) => {
    if (seller) {
      setSellerToEdit(seller);
      setModalIsOpen(true);
    }
  };

  const deleteSeller = async (seller: ISeller) => {
    if (seller) {
      await SellersServices.deleteSeller(seller).then((response) => {
        if (response) {
          message.success('Vendedor excluído com sucesso');

          setIsLoading(true);

          SellersServices.getSellers(user.company_id)
            .then((sellersData) => {
              setSellers(sellersData);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      });
    }
  };

  const askForMoreSellers = () => {
    const newWindow = window.open('', '_blank');
    const url = 'https://whts.co/store-trial';

    if (newWindow) {
      newWindow.location.href = url;
    } else {
      window.location.href = url;
    }
  };

  const sellerData = sellers?.filter((seller: ISeller) => {
    return seller.name.toLowerCase().includes(sellerFilter.toLowerCase());
  });

  const emptyTable = (
    <Empty
      image={<EmptySellers />}
      description="Você não possui vendedores cadastrados."
    >
      <button
        className="primary-button store"
        type="button"
        onClick={() => setModalIsOpen(true)}
        style={{ backgroundColor: 'FFB822' }}
      >
        Cadastrar Vendedor
      </button>
    </Empty>
  );

  return (
    <div className="sellers-list">
      {modalIsOpen && (
        <Modal closeModal={closeModal}>
          <SellersRegistration
            sellerData={sellerToEdit}
            editSeller={editSeller}
            updateSellers={updateSellers}
          />
        </Modal>
      )}
      <div className="sellers-list__action-bar">
        <div className="action-bar__text-wrapper row ya-center">
          <span>
            <HeadSetIcon />
          </span>
          <h1>Vendedores</h1>
        </div>
        <div className="action-bar__button-wrapper">
          {sellers.length >= sellersLimit ? (
            <button
              className="button--ask-for-more"
              type="button"
              onClick={() => askForMoreSellers()}
            >
              <WhatsIcon />
              Solicitar mais cadastros
            </button>
          ) : (
            <button
              className="button--call-register"
              type="button"
              onClick={() => setModalIsOpen(true)}
            >
              Cadastrar vendedor
            </button>
          )}
        </div>
      </div>

      <div className="sellers-list__main-content wrapper-1-1 row ya-start between">
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <div className="main-content__search-input">
              <div className="search-input__wrapper">
                <input
                  type="text"
                  placeholder="Pesquisar vendedor"
                  value={sellerFilter}
                  onChange={({ target: { value } }) => setSellerFilter(value)}
                />
                <FiSearch />
              </div>
            </div>
            <ul className="sellers-cards-list wrapper-1-1 row ya-start between">
              {sellerData && sellerData.length > 0 ? (
                sellerData.map((seller) => {
                  return (
                    <SellerCard
                      key={seller.id}
                      sellerData={seller}
                      callEdit={triggerEdit}
                      deleteSeller={deleteSeller}
                    />
                  );
                })
              ) : (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {emptyTable}
                </div>
              )}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default SellersList;
