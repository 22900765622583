import { message } from 'antd';
import { ISpecification, ISpecificationOption } from '../../Types';
import api from '../api';

export default class SpecificationsServices {
  static async getSpecifications(
    companyId: string,
    storeId: string,
  ): Promise<ISpecification[]> {
    const specificationsData = await api
      .get(`/company/${companyId}/store/${storeId}/specifications/`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return specificationsData;
  }

  static async getSpecificationById(
    companyId: string,
    storeId: string,
    specificationId: string,
  ): Promise<ISpecification> {
    const specificationData = await api
      .get(
        `/company/${companyId}/store/${storeId}/specifications/${specificationId}`,
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return specificationData;
  }

  static async postSpecification(
    companyId: string,
    storeId: string,
    newSpecification: {
      company_id: string;
      description: string;
      enabled: boolean;
    },
  ): Promise<ISpecification> {
    const specificationData = await api
      .post(
        `/company/${companyId}/store/${storeId}/specifications`,
        newSpecification,
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return specificationData;
  }

  static async putSpecification(
    companyId: string,
    storeId: string,
    specification: {
      id: string;
      company_id: string;
      description: string;
      enabled: boolean;
    },
  ): Promise<ISpecification> {
    const specificationData = await api
      .put(
        `/company/${companyId}/store/${storeId}/specifications/${specification.id}`,
        specification,
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });
    return specificationData;
  }

  static async deleteSpecification(
    companyId: string,
    storeId: string,
    specificationId: string,
  ): Promise<JSON> {
    const specificationData = await api
      .delete(
        `/company/${companyId}/store/${storeId}/specifications/${specificationId}`,
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return specificationData;
  }

  static async postSpecificationOption(
    storeId: string,
    specification: ISpecification,
    newSpecificationOption: {
      specification_id: string;
      description: string;
      position?: number;
      enabled: boolean;
    },
  ): Promise<ISpecificationOption> {
    const specificationOptionData = await api
      .post(
        `/company/${specification.company_id}/store/${storeId}/specifications/${specification.id}/options`,
        newSpecificationOption,
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return specificationOptionData;
  }

  static async putSpecificationOption(
    companyId: string,
    storeId: string,
    specificationOption: ISpecificationOption,
  ): Promise<ISpecificationOption> {
    const specificationOptionData = await api
      .put(
        `/company/${companyId}/store/${storeId}/specifications/${specificationOption.specification_id}/options/${specificationOption.id}`,
        specificationOption,
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return specificationOptionData;
  }

  static async deleteSpecificationOption(
    companyId: string,
    storeId: string,
    specificationId: string,
    specificationOptionId: string,
  ): Promise<JSON> {
    const specificationOptionData = await api
      .delete(
        `/company/${companyId}/store/${storeId}/specifications/${specificationId}/options/${specificationOptionId}`,
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });
    return specificationOptionData;
  }
}
