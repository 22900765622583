import React, { useState } from 'react';
import { Popconfirm, Switch, message } from 'antd';
import { Draggable } from 'react-beautiful-dnd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { FiInfo, FiTrash2 } from 'react-icons/fi';
import { RiDragMove2Fill } from 'react-icons/ri';

import { IConnectLinks } from '../../../../../Types';

import { LinkGroupWrapper, LinkTitle, LinkUrl, RemoveButton } from './styles';

interface IProps {
  index: number;
  button: IConnectLinks;
  buttonId?: string;
  removeButton: (buttonId?: string) => void;
  updateButtonsState: (button: IConnectLinks) => void;
}

const LinkGroup: React.FC<IProps> = (props) => {
  const { index, button, buttonId, removeButton, updateButtonsState } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [isLinkValid, setIsLinkValid] = useState(true);
  const [buttonState, setButtonState] = useState({
    ...button,
    title: button.title,
    enabled: button.enabled,
    url: button.url,
    highlight: button.highlight,
  });

  const handleEditLink = () => {
    // verificar se os objetos são iguais/não tiveram modificações
    if (buttonState.title !== button.title) {
      updateButtonsState(buttonState);
    } else if (buttonState.url !== button.url) {
      if (isLinkValid) {
        updateButtonsState(buttonState);
      } else {
        message.error('Link inválido');
      }
    }
  };

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = e.target.value;

    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    const regex = new RegExp(expression);

    setButtonState({ ...buttonState, url: e.target.value });

    if (currValue.match(regex)) {
      setIsLinkValid(true);
    } else {
      setIsLinkValid(false);
    }
  };

  return buttonId ? (
    <Draggable draggableId={buttonId} index={index}>
      {(provided) => (
        <LinkGroupWrapper
          key={buttonId}
          className={isFocused ? 'focused' : ''}
          onBlur={() => handleEditLink()}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <LinkTitle
            type="text"
            placeholder="Título"
            value={buttonState.title}
            onChange={(e) => {
              setButtonState({ ...buttonState, title: e.target.value });
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setIsFocused(false);
            }}
          />

          <div className="input-wrapper">
            <LinkUrl
              type="text"
              placeholder="https://"
              value={buttonState.url}
              onChange={(e) => handleLinkChange(e)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setIsFocused(false);
              }}
              style={{ borderColor: isLinkValid ? '' : '#f27878' }}
            />
            {isLinkValid ? (
              <div />
            ) : (
              <div className="validation-tools">
                <button
                  className="tool-tip"
                  type="button"
                  title="Insira um endereço válido"
                >
                  <FiInfo />
                </button>
                <p className="error-message">Insira um endereço válido</p>
              </div>
            )}
          </div>
          <div className="row-wrapper action-bar">
            <button className="drag-and-drop" type="button">
              <RiDragMove2Fill />
            </button>

            <button
              type="button"
              className="highlight-button"
              onClick={() => {
                setButtonState({
                  ...buttonState,
                  highlight: !buttonState.highlight,
                });
                updateButtonsState({
                  ...buttonState,
                  highlight: !buttonState.highlight,
                });
              }}
            >
              {buttonState.highlight ? <AiFillStar /> : <AiOutlineStar />}
            </button>

            <button type="button" className="switch-wrapper">
              <Switch
                checked={buttonState.enabled}
                onChange={() => {
                  setButtonState({
                    ...buttonState,
                    enabled: !buttonState.enabled,
                  });
                  updateButtonsState({
                    ...buttonState,
                    enabled: !buttonState.enabled,
                  });
                }}
              />
            </button>

            <Popconfirm
              title="Deseja excluir esse link?"
              icon={<QuestionCircleOutlined style={{ color: '#ffbf00' }} />}
              onConfirm={() => removeButton(button.id)}
              okText="Sim"
              cancelText="Não"
            >
              <RemoveButton
                type="button"
                onFocus={() => setIsFocused(true)}
                onBlur={() => {
                  setIsFocused(false);
                }}
              >
                <FiTrash2 />
              </RemoveButton>
            </Popconfirm>
          </div>
        </LinkGroupWrapper>
      )}
    </Draggable>
  ) : (
    <div />
  );
};

export default LinkGroup;
