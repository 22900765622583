import { IStoreProps, IUser } from '../Types';
import StoreServices from '../services/store/stores';
import CategoriesServices from '../services/store/Categories';
import ProductsServices from '../services/store/Products';

interface IReturn {
  catalogs: IStoreProps[];
  catalogId: string | undefined;
}

export default async function useVerifyStores(
  user: IUser,
  stores: IStoreProps[],
): Promise<IReturn> {
  if (stores.length <= 0) {
    const newStore = {
      company_id: user.company_id,
      redirect_url: '',
      primary_color: '',
      secondary_color: '',
      company_logo: '',
      suiteshare_logo: '',
      redirect_phone: '',
      enabled: true,
      title: 'Catálogo Padrão',
      description: '',
      cover_image: '',
      position: 0,
    };

    let catalogs: IStoreProps[] = [];

    await StoreServices.postStore(user.company_id, newStore).then(
      (newCatalog) => {
        localStorage.setItem(
          `@Suiteshare:catalogId-${user.company_id}`,
          newCatalog.id,
        );
        catalogs = [newCatalog];
      },
    );
    return { catalogs, catalogId: catalogs[0].id };
    // eslint-disable-next-line no-else-return
  } else if (stores.length >= 1) {
    const catalog = stores[0];

    // Se já existir um catálogo previamente criado, vamos atualizar todos os produtos e categorias que não possuiam uma Store!
    if (catalog.title === null) {
      catalog.title = 'Catálogo Padrão';
      StoreServices.editStore(user.company_id, catalog);
    }

    // Primeiro as categorias
    CategoriesServices.getCategories(user.company_id).then(
      async (categories) => {
        Promise.all(
          categories.map((category) => {
            if (!category.store_id) {
              const newCategory = { ...category, store_id: catalog.id };

              return CategoriesServices.putCategory(
                user.company_id,
                catalog.id,
                newCategory,
              );
            }
            return undefined;
          }),
        );
      },
    );

    // Depois os produtos!
    ProductsServices.getProducts(user.company_id).then(async (products) => {
      Promise.all(
        products.map((product) => {
          if (!product.store_id) {
            const newProduct = { ...product, store_id: catalog.id };

            return ProductsServices.editProduct(
              user.company_id,
              catalog.id,
              newProduct,
            );
          }
          return undefined;
        }),
      );
    });

    if (!localStorage.getItem(`@Suiteshare:catalogId-${user.company_id}`)) {
      if (catalog.title === null) {
        const currDefaultStore = catalog;
        currDefaultStore.title = 'Catálogo Padrão';
      }

      localStorage.setItem(
        `@Suiteshare:catalogId-${user.company_id}`,
        stores[0].id,
      );

      return { catalogs: stores, catalogId: stores[0].id };
    }

    if (localStorage.getItem(`@Suiteshare:catalogId-${user.company_id}`)) {
      let catalogId = localStorage.getItem(
        `@Suiteshare:catalogId-${user.company_id}`,
      );

      if (catalogId !== null) {
        const selectedStore = stores.filter(
          (o: IStoreProps) => o.id === catalogId,
        );

        if (selectedStore && selectedStore.length > 0) {
          if (selectedStore[0].title === null) {
            selectedStore[0].title = 'Catálogo Padrão';
          }
          catalogId = selectedStore[0].id;
        } else {
          catalogId = stores[0].id;
        }

        return { catalogs: stores, catalogId };
      }
    }
  }

  return { catalogs: stores, catalogId: undefined };
}
