import styled from 'styled-components';
import { Container } from '../../styles/default';
import { streach } from '../../styles/animations';

export const Icons = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  color: #232323;
  @media (min-width: 1024px) {
    width: auto;
    height: 74px;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const HeaderContainer = styled(Container)`
  width: 100%;
  height: auto;
  display: none;
  z-index: 10;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-rows: 1fr 0.5fr;
  }

  #connect-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    font-size: 0.85rem;
    @media (min-width: 1024px) {
      color: #000;
    }
    svg {
      width: 1.2rem;
      font-size: 1.2rem;
      margin-right: 0.5rem;
      @media (min-width: 1024px) {
        color: #ff5c92;
      }
    }
  }

  &.mobile {
    display: block;
    overflow: unset;
    position: fixed;
    bottom: 0.5rem;
    background: transparent;
    @media (min-width: 1024px) {
      display: none;
    }

    #plus-menu {
      width: 90%;
      max-width: 416px;
      height: auto;
      position: absolute;
      margin: 0 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      z-index: 99;
      transition: all 0.3s ease;
      a {
        display: flex;
        justify-content: left;
        align-items: center;
        color: #fff;
        background: #ffb822;
        border-radius: 5rem;
        width: 8rem;
        padding: 0.5rem 0;
        margin: 0 0.5rem;
        i {
          margin: 0 0.5rem;
        }
      }
    }

    .title {
      border: 1px solid #e5e5e5;
      padding: 0.5rem 1rem;
      position: relative;
      flex-direction: row;
      max-width: 416px;
      margin: 0 auto;
      width: 95%;
      border-radius: 5rem;
      overflow: hidden;
      background: #232323;
      z-index: 100;
      #button-mobile-menu {
        width: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          width: 100%;
          color: #fff;
          background: none;
          border: none;
          border-radius: 50%;
          padding: 0.3rem;
          transition: box-shadow 0.3s ease;
        }
      }
      nav {
        &.nav {
          width: 100%;
          max-width: 342px;
          padding: 0;
          .plus-button {
            border-radius: 50%;
            background: #515151;
            padding: 0.7rem 0.8rem;
            border: none;
            color: #fff;
            i {
              svg {
                transform: rotate(0deg);
                transition: all 0.5s ease;
              }
            }
            &.is-open {
              i {
                svg {
                  transform: rotate(-135deg);
                }
              }
            }

            @media (min-width: 1024px) {
              display: none;
            }
          }

          a {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            flex-direction: column;
            &.active {
              color: #ffb822;
              border: none;
            }
            &.connect {
              &.active {
                color: #fd397a;
              }
            }

            i {
              margin: 0;
              svg {
                width: 1.2rem;
              }
            }

            span {
              font-size: 0.6rem;
              &.span-menu-desk {
                display: none;
                @media (min-width: 1024px) {
                  display: block;
                }
              }
              &.span-menu-mob {
                display: block;
                @media (min-width: 1024px) {
                  display: none;
                }
              }
            }
          }
        }
      }
      .menu-box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 100;
        background-color: #fff;
        border: 1px solid #232323;
        padding: 0rem 1rem;
        flex-direction: row;
        border-radius: 5rem;
        transition: all 0.3s ease;
        display: grid;
        grid-template-columns: 2fr 0.5fr;

        .close-btn {
          border: none;
          background: none;
          width: 100%;
          display: flex;
          padding: 0 0.5rem 0 0;
          justify-content: flex-end;
          align-items: center;
        }

        ${Icons} {
          align-items: center;
          flex-direction: row;
        }
      }
    }
    #mobile-menu-mask {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.75);
      border: none;
      outline: none !important;
      z-index: 99;
      font-size: 0;
      color: transparent;
    }
  }

  .title {
    padding: 0 1rem;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: 3px 3px 4px #f1f1f1;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 1024px) {
      padding: 0 2.5rem 1rem 4.7rem;
      border: none;
      box-shadow: none;
    }
    img.app-logo {
      width: 2rem;
      @media (min-width: 1024px) {
        width: auto;
        height: 1.6rem;
        display: inline-block;
      }
    }
  }

  ul {
    list-style: none;
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    margin: 0rem 0 0rem 4.8rem;
    li {
      width: 10.5rem;
      position: relative;
      margin: 0 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #c8c4c4;
        bottom: 0rem;
        transition: background 0.3s ease-out;
      }
      &.active {
        button {
          font-weight: 600;
        }
        &::after {
          background: #36e481;
        }
      }
      button {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
        outline: none;
        font-size: 0.9rem;
      }
    }
  }
`;

export const ConfigWrapper = styled.div`
  padding: 1rem 0;
`;

export const Icon = styled.div`
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  @media (min-width: 1024px) {
    margin: 0rem 0.5rem 0 2rem;
  }
  p {
    font-family: 'Poppins', sans-serif;
    font-size: 0.6rem;
    margin: 0 0.2rem 0 0.3rem;
  }
  button {
    padding: 0.5rem;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 1024px) {
      padding: 0;
    }
    p {
      color: #333;
      font-size: 1rem;
    }
    svg {
      width: 1rem;
      height: auto;
    }
  }
  a {
    font-size: 1rem;
    color: #232323;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem;
    border: 2px solid transparent;
    transition: border 0.3s ease;

    img {
      height: 1rem;
      width: 1rem !important;
      margin: 0 0.4rem 0 0;
    }
  }
`;

export const OptionsBox = styled.div`
  position: absolute;
  top: 1.6rem;
  right: 0.3rem;
  padding: 1rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0.4em;
  animation: ${streach} 0.1s;
  box-shadow: 2px 5px 7px #8888;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0%;
    width: 0;
    height: 0;
    border: 25px solid transparent;
    border-bottom-color: #fff;
    border-top: 0;
    border-right: 0;
    margin-left: -10px;
    margin-top: -15px;
  }
  ${Icon} {
    color: #333;
    svg {
      margin: 0 0.5rem 0 0;
    }
  }
  a {
    color: #333;
    text-decoration: none;
    ${Icon} {
      p {
        color: #333;
        font-size: 0.8rem;
      }
    }
  }
  button {
    border: none;
    background: none;
    p {
      color: #333;
      font-size: 0.8rem;
    }
  }
`;

export const SubHeader = styled.div`
  height: 74px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-left: 64px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const TitleContainer = styled.div`
  color: #232323;
  flex-direction: column;

  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
`;

export const Title = styled.div`
  color: #232323;

  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
`;

export const SubTitle = styled.div`
  color: #b5b5b5;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

export const Actions = styled.div`
  flex-direction: row;
  padding-right: 83px;
`;

export const Action = styled.div`
  background: silver;
`;
