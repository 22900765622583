import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #282828;
    color: #fff;
    -webkit-font-smoothing: antialised;
    min-height: 100%;
  }

  body, input, button {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
    outline: none !important;
  }

  .success-message {
    background-color: #f5fced;
    border: 1px solid #b7eb8f;
  }

  .error-message {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
  }

  .warning-message {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
  }

  .info-message {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
  }

  /* Animation */
  @keyframes appearFromLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  /* Sessions */

  .form-wrapper {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fbfbfb;
    /* box-shadow: -5px 0px 5px #f4f4f4; */
    box-shadow: -5px 0px 3px rgba(0,0,0,0.05);
    @media (min-width: 1024px) {
      width: 40%;
      min-width: 400px;
      padding: 0;
    }
    img {
      width: 90px;
    @media (min-width: 1024px) {
      width: 150px;
    }
    }
    .suiteshare-form {
      width: 100%;
      max-width: 340px;
      animation: appearFromLeft 1s;
      h1 {
        font-size: 1rem;
        margin: 0.5rem auto 2rem;
        font-weight: 400;
        text-align: center;
        @media (min-width: 1024px) {
          margin: 1rem auto;
        }
      }
      .url-preview {
        width: 100%;
        word-break: break-word;
        display: inline-block;
        position: relative;
        top: -1rem;
        text-decoration: underline;
        font-size: 0.8rem;
        color: #888;
      }
      .ant-form-item {
        margin: 0 0 2rem;
        &.ant-form-item-with-help {
          margin: 0 0 2rem;
        }
      }
      .ant-form-item-control-input-content {
        position: relative;
        .ant-select {
          &.ant-select-single {
            &.ant-select-show-arrow {
              width: 70px;
              position: absolute;
              left: 2rem;
              z-index: 2;
              .ant-select-selector {
                background: transparent;
                border: none;
                box-shadow: none;
              }
            }
          }
        }
        .phone-input {
          input {
            padding: 0 0 0 4.5rem;
          }
        }
      }
      .ant-form-item-explain {
        text-align: left;
        position: absolute;
        bottom: -2rem;
      }
      .ant-input-affix-wrapper {
        background: transparent;
        border: none;
        border-bottom: 1px solid #232323;
        transition: border 0.3s ease;
        .ant-input-prefix {
          margin: 0 1rem 0 0;
          color: #232323;
          transition: color 0.3s ease;
        }
        input {
          color: #232323;
          background: transparent;
          &::placeholder {
            color: #cfcfcf;
            opacity: 1;
            transition: color 0.3s ease;
          }
        }
        &:focus,
        &.ant-input-affix-wrapper-focused {
          border: none;
          border-bottom: 1px solid #ffb900;
          box-shadow: none;
          .ant-input-prefix {
            color: #ffb900;
          }
          input {
            &::placeholder {
              color: #cfcfcf;
            }
          }
        }
      }
      .session-button {
        position: relative;
        padding: 0.7rem 0;
        background: linear-gradient(29deg, #ffb900 0%, #ffb900 70%);
        border-radius: 5px;
        border: 0;
        color: #fff;
        width: 100%;
        font-weight: 500;
        box-shadow: 4px 4px 5px #caa14b;
        z-index: 1;
        transition: box-shadow 0.3s ease;
        @media (min-width: 1024px) {
          margin-top: 16px;
        }
        &::before {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 5px;
          background: linear-gradient(29deg, rgb(255 149 42) 0%, #ffb900 70%);
          z-index: -1;
          transition: all 0.3s ease;
          opacity: 0;
        }
        &:hover {
          &::before {
            opacity: 1;
          }
        }
        &:active {
          box-shadow: -1px -1px 5px #caa14b;
        }
        &.connect {
          background: linear-gradient(29deg, #fd397a 0%, #fd397a 70%);
          box-shadow: 2px 2px 4px #fd397aa8;
          &::before {
            background: linear-gradient(29deg, rgb(193 42 255) 0%, #fd397a 70%);
          }
          &:hover {
            &::before {
              opacity: 1;
            }
          }
          &:active {
            box-shadow: -1px -1px 5px #fd397aa8;
          }
        }
      }

    }
    .bellow-link {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2rem auto 0;
      p,
      a {
        font-size: 0.9rem;
        color: #232323;
      }
      p {
        margin: 0 0.3rem 0 0;
      }
      a {
        text-decoration: underline;
      }
    }
  }
  
`;
