import React from 'react';
import SpecificationsRegisterContent from '../../../../../components/_Store/SpecificationsRegisterContent';

import { Content } from './styles';

const VariantsRegistration: React.FC = () => {
  return (
    <Content>
      <SpecificationsRegisterContent />
    </Content>
  );
};

export default VariantsRegistration;
