import { message } from 'antd';
import api from '../api';
import { ISeller } from '../../Types';

export default class SellersServices {
  static async getSellers(companyId: string): Promise<ISeller[]> {
    const sellersData = await api
      .get(`/company/${companyId}/sellers`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return sellersData;
  }

  static async postSeller(newSeller: {
    company_id: string;
    phone: string;
    name: string;
    email: string;
    code: string;
  }): Promise<ISeller> {
    const sellerData = await api
      .post(`/company/${newSeller.company_id}/sellers`, newSeller)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.error}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return sellerData;
  }

  static async putSeller(seller: ISeller): Promise<ISeller> {
    const sellerData = await api
      .put(`/company/${seller.company_id}/sellers/${seller.id}`, seller)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return sellerData;
  }

  static async deleteSeller(seller: ISeller) {
    const sellerData = await api
      .delete(`/company/${seller.company_id}/sellers/${seller.id}`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return sellerData;
  }
}
