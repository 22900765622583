import React, { FC, useState, useEffect, useCallback, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { message, Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { RiCloseLine } from 'react-icons/ri';
import { Server } from 'react-feather';

import { ISpecification } from '../../../Types';

import { useAuth } from '../../../hooks/auth';

import SpecificationsServices from '../../../services/store/specifications';

import './styles.scss';
import { GeneralStoreContext } from '../../../stores/GeneralStore';

interface IUrlParams {
  specification_id: string;
}
interface IProps {
  closeModal?: (recentlyCreatedSpecification?: ISpecification) => void;
  specificationName?: string;
}

const SpecificationsRegisterContent: FC<IProps> = observer((props) => {
  const { closeModal, specificationName } = props;
  const { user } = useAuth();
  const { specification_id } = useParams<IUrlParams>();
  const history = useHistory();
  const generalStore = useContext(GeneralStoreContext);

  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [specification, setSpecification] = useState<ISpecification>({
    id: '',
    company_id: '',
    description: '',
    enabled: true,
    options: [],
  });
  const [currentOption, setCurrentOption] = useState<string | undefined>();
  const [currentOptionPosition, setCurrentOptionPosition] = useState(0);

  const storeId = generalStore.currentCatalogId;

  useEffect(() => {
    if (specification_id) {
      // Edição de variações
      SpecificationsServices.getSpecificationById(
        user.company_id,
        storeId,
        specification_id,
      )
        .then((specificationData) => {
          if (specificationData) {
            const currentSpecificationData = specificationData;
            currentSpecificationData.options.forEach((option, index) => {
              if (option) {
                const currOption = option;
                currOption.position = index;
                currentSpecificationData.options[index] = currOption;
              }
            });
            setCurrentOptionPosition(currentSpecificationData.options.length);
            setSpecification(currentSpecificationData);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (specificationName) {
      setSpecification({ ...specification, description: specificationName });
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, specification_id, user.company_id, specificationName]);

  const addVariantOption = useCallback(() => {
    const currentSpecification = specification;

    if (currentOption) {
      currentSpecification.options.push({
        id: '',
        specification_id: currentSpecification.id,
        description: currentOption,
        position: currentOptionPosition,
        enabled: true,
      });
      setSpecification(currentSpecification);
      setCurrentOptionPosition(currentOptionPosition + 1);
      setCurrentOption('');
    }
  }, [specification, currentOption, currentOptionPosition]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key || event.keyCode;

    if (key === 'Enter' || key === 13) {
      addVariantOption();
    }
  };

  const removeVariantOption = (index: number) => {
    const currentSpecification = specification;
    if (currentSpecification) {
      if (currentSpecification.options[index].id !== '') {
        setButtonLoading(true);
        SpecificationsServices.deleteSpecificationOption(
          user.company_id,
          storeId,
          specification_id,
          specification.options[index]?.id,
        )
          .then(async () => {
            await SpecificationsServices.getSpecificationById(
              user.company_id,
              storeId,
              specification_id,
            ).then((specificationData) => {
              if (specificationData) {
                setSpecification(specificationData);
              }
            });
          })
          .finally(() => {
            setButtonLoading(false);
          });
      } else {
        currentSpecification.options.splice(index, 1);

        setSpecification({
          ...specification,
          options: currentSpecification.options,
        });
      }
    }
  };

  const submitNewVariant = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (specification_id) {
      // Update an existing specification
      setIsLoading(true);

      SpecificationsServices.putSpecification(user.company_id, storeId, {
        id: specification.id,
        company_id: specification.company_id,
        description: specification.description,
        enabled: specification.enabled,
      })
        .then(async () => {
          await Promise.all(
            specification.options.map(async (option) => {
              if (option && option.id === '') {
                // Inclusão de opção de variação
                await SpecificationsServices.postSpecificationOption(
                  storeId,
                  specification,
                  {
                    specification_id: specification.id,
                    description: option.description,
                    position: option.position,
                    enabled: option.enabled,
                  },
                );
              } else {
                // alteração de opção de variação
                await SpecificationsServices.putSpecificationOption(
                  user.company_id,
                  storeId,
                  option,
                );
              }
            }),
          );
        })
        .finally(() => {
          SpecificationsServices.getSpecificationById(
            user.company_id,
            storeId,
            specification_id,
          ).then((specificationData) => {
            if (specificationData) {
              setSpecification(specificationData);
            }
          });
          setIsLoading(false);
          history.push('/store/specifications');
        });
    } else if (specification.id === '' && specification.description) {
      // Adding a new specification
      SpecificationsServices.postSpecification(user.company_id, storeId, {
        company_id: user.company_id,
        description: specification.description,
        enabled: specification.enabled,
      }).then(async (newlySpecification) => {
        setIsLoading(true);
        if (specification.options.length > 0) {
          await Promise.all(
            specification.options.map(async (option) => {
              await SpecificationsServices.postSpecificationOption(
                storeId,
                newlySpecification,
                {
                  specification_id: newlySpecification.id,
                  description: option.description,
                  position: option.position,
                  enabled: option.enabled,
                },
              );
            }),
          );
        }
        SpecificationsServices.getSpecificationById(
          user.company_id,
          storeId,
          newlySpecification.id,
        ).then((specificationData) => {
          if (specificationData) {
            setIsLoading(false);
            if (closeModal) {
              closeModal(specificationData);
            } else {
              history.push('/store/specifications');
            }
          }
        });
      });
    } else {
      message.info('Preencha o formulário antes de enviar');
    }
  };

  return (
    <div className="variants-modal__body">
      <div className="row">
        {closeModal ? (
          <button
            className="btn btn--close"
            type="button"
            onClick={() => closeModal()}
          >
            <RiCloseLine />
          </button>
        ) : (
          <div />
        )}
      </div>
      <div className="row">
        <Server />
        <h1 className="title-modified">Cadastro de variações</h1>
      </div>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <div className="row">
            <form className="variants-form">
              <label htmlFor="variant-name" className="variants-form__label">
                Dê um nome a sua variação:
                <input
                  type="text"
                  id="variant-name"
                  className="variants-form__input"
                  placeholder="Nome da variação. Exemplo: Tamanho"
                  value={specification.description}
                  onChange={({ target: { value } }) => {
                    setSpecification({ ...specification, description: value });
                  }}
                />
              </label>
              <label htmlFor="variant-type" className="variants-form__label">
                Adicione as opções abaixo:
                <div className="row">
                  <input
                    type="text"
                    id="variant-type"
                    className="variants-form__input variants-form__input--bind"
                    placeholder="Exemplo: 40"
                    value={currentOption}
                    onChange={({ target: { value } }) => {
                      setCurrentOption(value);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                  <button
                    type="button"
                    className="btn variants-form__button"
                    onClick={() => addVariantOption()}
                  >
                    Salvar
                  </button>
                </div>
              </label>
            </form>
          </div>
          <div className="row row--fixed-height">
            <ul className="variants-list">
              {specification ? (
                specification.options?.map((option, index) => {
                  return (
                    <li
                      key={option.description + index}
                      className="variants-list__item"
                    >
                      {option.description}
                      {buttonLoading ? (
                        <LoadingOutlined />
                      ) : (
                        <button
                          type="button"
                          className="btn variants-list__remove-item"
                          onClick={() => removeVariantOption(index)}
                        >
                          <RiCloseLine />
                        </button>
                      )}
                    </li>
                  );
                })
              ) : (
                <div />
              )}
            </ul>
          </div>
          <div className="row row--end">
            {buttonLoading ? (
              <LoadingOutlined />
            ) : (
              <button
                className={`btn btn--confirm ${
                  specification.options?.length <= 0 ||
                  !specification.description
                    ? 'btn--disabled'
                    : 'pingola'
                }`}
                disabled={
                  specification.options?.length <= 0 ||
                  !specification.description
                }
                type="button"
                onClick={(e) => submitNewVariant(e)}
              >
                Salvar variação
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
});

export default SpecificationsRegisterContent;
