import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import filesize from 'filesize';
import { Descriptions, message } from 'antd';
import { BiImageAdd } from 'react-icons/bi';
import { LoadingOutlined } from '@ant-design/icons';

import { CategoryProps, FileProps } from '../../Types';

import { useAuth } from '../../hooks/auth';

import { resizeFile, base64ToFile } from '../../utils/resizeFile';

import { GeneralStoreContext } from '../../stores/GeneralStore';
import { CategoriesStoreContext } from '../../stores/CategoriesStore';

import {
  Mask,
  ModalBody,
  CloseModal,
  EditForm,
  Title,
  Input,
  ProductDetails,
  ProductImages,
  ButtonWrapper,
  SaveButton,
} from './styles';

import { ReactComponent as CloseButton } from '../../assets/close-btn.svg';
import CategoriesServices from '../../services/store/Categories';

interface IProps {
  isOpen: boolean;
  categoryData: CategoryProps;
  closeModal: (value: boolean) => void;
  editCategory: (product: CategoryProps, files: FileProps[]) => void;
  loading?: boolean;
}

const EditModal: React.FC<IProps> = observer((props: IProps) => {
  const { isOpen, categoryData, closeModal } = props;
  const categoriesStore = useContext(CategoriesStoreContext);
  const generalStore = useContext(GeneralStoreContext);

  const [isSettingData, setIsSettingData] = useState(false);
  const [category, setCategory] = useState<CategoryProps>(categoryData);

  const { user } = useAuth();

  const storeId = generalStore.currentCatalogId;

  const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (category) {
      const captureObj: CategoryProps = {
        ...category,
        description: event.target.value,
        code: event.target.value.toLowerCase().split(' ').join('-'),
      };

      setCategory(captureObj);
    }
  };

  const handleUploadChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setIsSettingData(true);
      const fileMapped: FileProps = {
        file: e.target.files[0],
        name: e.target.files[0].name,
        position: 0,
        path: URL.createObjectURL(e.target.files[0]),
        readableSize: filesize(e.target.files[0].size),
      };
      const img = await resizeFile(fileMapped.file);
      const nFile = await base64ToFile(img, fileMapped.name);
      const image = new FormData();
      image.append('file', nFile, nFile.name);

      CategoriesServices.postCategoryImage(
        user.company_id,
        storeId,
        category.id,
        image,
      ).then(() => {
        CategoriesServices.getCategories(user.company_id, storeId).then(
          (res) => {
            const filteredCategory = res.filter(
              (o: CategoryProps) => o.id === category.id,
            );
            if (filteredCategory[0].image_url) {
              setCategory({
                ...category,
                image_url: filteredCategory[0].image_url,
              });
              categoriesStore.editCategoryFromList(filteredCategory[0]);
            }
          },
        );
        message.success('Imagem adicionada com sucesso');
        setIsSettingData(false);
      });
    }
  };

  const saveEditedProduct = () => {
    if (category) {
      if (category.description) {
        setIsSettingData(true);
        CategoriesServices.putCategory(user.company_id, storeId, category)
          .then((res) => {
            message.success('Produto editado com sucesso');
            categoriesStore.editCategoryFromList(res);
          })
          .catch((error) => {
            message.error(error.message);
          })
          .finally(() => {
            setIsSettingData(false);
            closeModal(false);
          });
      } else {
        message.error('Nenhum campo pode estar vazio');
      }
    }
  };

  return isOpen ? (
    <Mask>
      <ModalBody>
        <CloseModal>
          <Title>
            <h2>Informações da categoria</h2>
          </Title>
          <CloseButton onClick={() => closeModal(false)} />
        </CloseModal>
        {category ? (
          <EditForm>
            <ProductDetails>
              <Descriptions
                layout="vertical"
                column={1}
                className="suiteshare-descriptions"
              >
                <Descriptions.Item label="Nome da categoria">
                  <Input
                    id="categ-name-edit"
                    type="text"
                    name="categ-name-edit"
                    value={category.description}
                    onChange={(e) => changeValue(e)}
                  />
                </Descriptions.Item>
              </Descriptions>
            </ProductDetails>
            <ProductImages>
              {category.image_url && (
                <div id="categ-img">
                  {isSettingData ? (
                    <LoadingOutlined />
                  ) : (
                    <img
                      src={
                        process.env.REACT_APP_BUCKET_ASSETS_URL +
                        category?.image_url
                      }
                      alt={category.description}
                    />
                  )}
                </div>
              )}
              {category.image_url ? (
                <label className="change-image" htmlFor="categ-edit-image">
                  <BiImageAdd size={20} />
                  Trocar imagem
                  <input
                    type="file"
                    name=""
                    id="categ-edit-image"
                    accept=".jpg, .png, .jpeg"
                    onChange={(e) => handleUploadChange(e)}
                  />
                </label>
              ) : (
                <label className="add-image" htmlFor="categ-edit-image">
                  <BiImageAdd size={50} />
                  <input
                    type="file"
                    name=""
                    id="categ-edit-image"
                    accept=".jpg, .png, .jpeg"
                    onChange={(e) => handleUploadChange(e)}
                  />
                </label>
              )}
              <p>
                *Utilize imagens em sentido horizontal para melhor apresentação
                da categoria
              </p>
            </ProductImages>
          </EditForm>
        ) : (
          'Ocorreu um erro'
        )}
        <ButtonWrapper>
          <SaveButton
            type="submit"
            onClick={() => saveEditedProduct()}
            disabled={isSettingData}
          >
            Salvar
          </SaveButton>
        </ButtonWrapper>
      </ModalBody>
    </Mask>
  ) : (
    <div />
  );
});

export default EditModal;
