import styled from 'styled-components';
import { Container } from '../../../styles/default';
import { fadeIn, streach } from '../../../styles/animations';

export const ConfigurationContainer = styled(Container)`
  padding: 0 2.5rem;
  margin: 5rem 0 0 0;
  color: #000;
  background-color: #fbfbfb;

  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  #menu-column {
    .title {
      width: 290px;
      .title__content {
        width: 80%;
        color: 484848;
        h1 {
          font-size: 20px;
        }

        p {
          font-size: 13px;
        }
      }
    }

    .config-menu-link {
      position: relative;
      display: flex;
      margin: 0.5rem 0;
      justify-content: center;
      align-items: center;
      border-right: 5px solid transparent;
      border-radius: 0.3rem;
      transition: border-color 0.3s ease;
      &::before {
        content: '';
        position: absolute;
        right: -3.9px;
        width: 8px;
        height: 8px;
        background-color: transparent;
        transform: rotate(45deg);
      }

      span {
        width: 210px;
        height: 118px;
        color: #232323;
        background: #fff;
        font-size: 0.8rem;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        padding: 0.3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        svg {
          font-size: 1.4rem;
          color: #788cdc;
        }
      }
      &.active {
        border-color: #788cdc;
        &::before {
          background-color: #788cdc;
        }
      }
    }
  }
  #view-column {
    width: 70%;
    .view-wrapper {
      width: 100%;
      animation: 0.5s ${fadeIn};

      .title-wrapper {
        h1 {
          display: flex;
          align-items: center;
          font-size: 1.3rem;
          color: #232323;
          margin: 0;
        }
        svg {
          color: #3d509c;
          font-size: 1.5rem;
        }
      }

      .config-page-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .config-page-register-data,
        .config-page-adress {
          padding: 1rem;
          h1 {
            font-size: 17px;
          }
        }

        .config-page-register-data {
          width: 100%;
          /* margin-bottom: 2rem; */
        }
        .config-page-form-item {
          width: 60%;
          min-width: 450px;
          padding: 0 0 1rem 0.7rem;

          &::placeholder {
            color: #a1a1a1;
          }

          .config-page-span {
            width: 100%;
            padding: 0rem 1rem;
            background: #c9c9c9;
            color: #857d7b;
            border: 1px solid rgba(170, 170, 170, 0.6);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
            border-radius: 2px;
          }
          p {
            margin: 0;
            font-size: 0.9rem;
            color: #3d509c;
          }

          input {
            width: 100%;
            padding: 0.3rem 1rem;
            background: #ffffff;
            color: #232323;
            border: 1px solid rgba(170, 170, 170, 0.6);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
            border-radius: 2px;
            &::placeholder {
              color: #a5a5a5;
            }
            &:disabled {
              background-color: #e1e1e1;
              color: #a1a1a1;
            }
          }

          &.button-wrapper {
            display: flex;
            justify-content: flex-end;
            margin: 1rem 0 0 0;

            button {
              padding: 0.4rem 1.3rem;
              font-size: 0.8rem;
              color: #f5f5f5;
              background: #52c054;
              border: none;
              border-radius: 4px;
              box-shadow: 0px 4px 4px rgba(0, 191, 84, 0.25);
              font-weight: 600;
            }
          }
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.5s;
  .text-wrapper {
    margin: 0 0 2.4rem 2rem;
    h1 {
      font-size: 1.3rem;
      font-weight: 600;
      color: #ffffff;
    }
  }
`;

export const WhiteBox = styled.div`
  width: 100%;
  padding: 0 2rem;
  display: grid;
  grid-template-rows: 1fr 6fr;
  .columns-wrapper {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }
`;

export const ActionBar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr;
  .button-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .title-wrapper {
    display: flex;
    h3 {
      font-weight: 600;
      font-size: 1.5rem;
    }
  }
`;

export const AvatarColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  figure {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid #333;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }
`;

export const InfoColumn = styled.div`
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  .input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    label {
      width: 100%;
      display: inline-block;
      margin: 0.7rem 0;
      color: #3d509c;
      font-size: 0.7rem;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      input {
        width: 100%;
        padding: 0.3rem 1rem;
        background: #ffffff;
        color: #a1a1a1;
        border: 1px solid rgba(170, 170, 170, 0.6);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        border-radius: 2px;
        &::placeholder {
          color: #a1a1a1;
        }
      }
    }
    .email-wrapper {
      width: 100%;
    }
    .change-password {
      width: 100%;
      margin: 0.5rem 0 0;
      .label {
        color: #3d509c;
        font-size: 0.7rem;
        font-weight: 500;
      }
      p {
        font-size: 0.8rem;
        color: #232323;
      }
    }
    .button-wrapper {
      margin: 1.5rem 0 0;
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const AnimateFields = styled.div`
  transform-origin: top left;
  animation: ${streach} 0.7s;
`;

export const ButtonColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const Button = styled.button`
  padding: 0.4rem 1.3rem;
  font-size: 0.8rem;
  color: #f5f5f5;
  background: #788cdc;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(120, 140, 220, 0.25);
  &.save {
    background: #52c054;
    box-shadow: 0px 4px 4px rgba(0, 191, 84, 0.25);
    font-weight: 600;
  }
`;

export const BackButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  background: none;
  color: #232323;
  font-weight: 600;
  font-size: 0.8rem;
  svg {
    color: #ffa800;
    margin: 0 1rem 0 0;
  }
`;
