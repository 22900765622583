import { observable } from 'mobx';
import { createContext } from 'react';

import { IStoreProps } from '../Types';

class ConfigurationStore {
  @observable store: any;

  setStoreObject = (store: IStoreProps | undefined) => {
    this.store = store;
  };
}

export const ConfigurationStoreContext = createContext(
  new ConfigurationStore(),
);
