import styled from 'styled-components';
import { fadeIn, appearFromUp } from '../../styles/animations';

const h3style = `
  font-size: 0.8rem;
  font-weight: 500;
  color: #232323;
  margin: 0;
`;

const pstyle = `
  font-size: 0.7rem;
  font-weight: 400;
  color: #232323;
  margin: 0;
`;

const spacing = `
  margin: 0 0 1rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  h3 {
    ${h3style}
  }
`;

export const Mask = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100000;
  animation: ${fadeIn} 0.3s;
`;

export const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 1rem 1.5rem;
  display: grid;
  grid-template-rows: 0.8fr 5fr 0.9fr;
  background-color: #fff;
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 11;
  animation: ${appearFromUp} 0.6s;
  @media (min-width: 1024px) {
    width: 480px;
    height: auto;
  }
`;

export const CloseModal = styled.div`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (min-width: 1024px) {
    padding: 1rem 15px;
  }

  svg {
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
  }
`;

export const EditForm = styled.div`
  padding: 0;
  border-radius: 5px;
  .ant-descriptions-view {
    width: 100%;
    margin: 0 auto;
    @media (min-width: 1024px) {
      width: 415px;
    }
  }
`;

export const Title = styled.div`
  h2 {
    font-size: 1.1rem;
    font-weight: 600;
    color: #232323;

    @media (min-width: 1024px) {
      font-size: 1.2rem;
    }
  }
`;

export const Input = styled.input`
  ${h3style}
  padding: 0.3rem 0.5rem;
  border: 1px solid #cccccc;
  border-radius: 4px;
`;

export const Textarea = styled.textarea`
  ${h3style}
  width: 100%;
  height: 6rem;
  padding: 0.3rem 0.5rem;
  border: none;
  border-bottom: 1px solid #a1a1a1;
  border-radius: 4px;
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  p {
    ${pstyle}
    text-decoration: underline;
  }
`;

export const ProductDetails = styled.div`
  ${spacing}
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ProductImages = styled.div`
  ${spacing}
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  p {
    max-width: 385px;
    font-size: 0.7rem;
    font-weight: 400;
    font-style: italic;
    color: #232323;
    text-align: left;
    margin: 0 auto;
  }
  #categ-img {
    width: 100%;
    height: 128px;
    margin: 0 auto;
    object-fit: contain;
    position: relative;
    border: 1px solid #cdcdcd;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 1024px) {
      width: 385px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .delete-button-mask {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.3s ease;
      button {
        background: none;
        border: none;
        color: #fff;
      }
    }
    &:hover {
      .delete-button-mask {
        opacity: 1;
      }
    }
  }
  label {
    width: 100%;
    border: 1px dashed #cccccc;
    border-radius: 4px;
    padding: 0;
    margin: 1rem auto;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    @media (min-width: 1024px) {
      width: 385px;
    }

    &.add-image {
      height: 128px;
      background: #fbfbfb;
      color: #d2d2d2;
      svg {
        margin: 0;
      }
    }
    &.change-image {
      height: 35px;
      background: #f5f5f5;
      color: #828282;
      svg {
        margin: 0.3rem 0.5rem 0 0;
      }
    }
  }
  input[type='file'] {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 1rem 0 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: 1024px) {
    padding: 0 15px;
  }
`;

export const SaveButton = styled.button`
  padding: 0.5rem 3.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: #ffffff;
  background: #ffa800;
  border: none;
  border-radius: 4px;
  outline: none;
`;
