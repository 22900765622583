import { observable } from 'mobx';
import { createContext } from 'react';
import { CompanyProps, ICompanyProducts, IStoreProps } from '../Types';

class GeneralStore {
  @observable currApp = '';

  @observable isChild = false;

  @observable companyProducts: any;

  @observable companyData: any;

  @observable currentCatalogId: any;

  @observable catalogsList: any;

  changeCurrApp = (productName: string) => {
    this.currApp = productName;
  };

  setIsChild = (value: boolean) => {
    this.isChild = value;
  };

  setCompanyProducts = (companyProd: ICompanyProducts) => {
    this.companyProducts = companyProd;
  };

  setCompanyData = (companydata: CompanyProps) => {
    this.companyData = companydata;
  };

  setCurrentCatalogId = (currentCatalogId: string) => {
    this.currentCatalogId = currentCatalogId;
  };

  setCatalogsList = (catalogsData: IStoreProps[]) => {
    this.catalogsList = catalogsData;
  };
}

export const GeneralStoreContext = createContext(new GeneralStore());
