import React, { FC, useState, useEffect } from 'react';
import { Skeleton, message } from 'antd';
import { AiOutlineMail } from 'react-icons/ai';
import { BiStore } from 'react-icons/bi';
import { FaChartLine } from 'react-icons/fa';
import { FiCopy } from 'react-icons/fi';

import { useAuth } from '../../../../../hooks/auth';

import CompanyServices from '../../../../../services/company';
import { CompanyProps } from '../../../../../Types';

import './styles.scss';

const BrachList: FC = () => {
  const { user } = useAuth();

  const [isLoading, setIsloading] = useState(true);
  const [branchList, setBranchList] = useState<CompanyProps[]>([]);

  useEffect(() => {
    CompanyServices.getCompanyBranches(user.company_id)
      .then((branchesData) => {
        if (branchesData) setBranchList(branchesData);
      })
      .finally(() => setIsloading(false));
  }, [user.company_id]);

  const copyToClip = (e: any) => {
    e.target.select();
    document.execCommand('copy');
    e.target.focus();

    message.success('Link copiado');
  };

  const handleClick = () => window.open('https://whts.co/marcelo');

  const renderChildren = () => {
    if (branchList && branchList.length > 0) {
      return branchList.map((branch: CompanyProps) => (
        <div className="branch-card">
          <div className="branch-card__content ">
            <p>{branch.name}</p>
            <p>
              <AiOutlineMail />
              {branch.users ? branch.users[0]?.email : ''}
            </p>
            <div className="branch-card__coming-soon">
              <div>
                <FaChartLine />
                <span>Relatórios</span>
              </div>
              <p>Em breve</p>
            </div>
          </div>
        </div>
      ));
    }

    return <h3>Você ainda não tem filiais cadastradas</h3>;
  };

  const renderInfo = () => {
    if (branchList && branchList.length > 0) {
      return (
        <>
          <p>
            Compartilhe o link abaixo para suas filiais se cadastrem na Store e
            possam utilizar o seu catálogo virtual
          </p>
          <div className="branch-list__share-link wrapper-1-1">
            <div className="share-link">
              <input
                type="text"
                value={`https://app.suitesha.re/store/signup?parent-id=${user.company_id}`}
                onClick={(e) => copyToClip(e)}
              />
              <FiCopy />
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <p>
          Cadastre suas unidades e filiais para que utilizem seu catálogo. Fale
          com um consultor para solicitar essa funcionalidade.
        </p>
        <div className="branch-list__share-link wrapper-1-1">
          <button
            className="btn call-to-action"
            type="button"
            onClick={() => handleClick()}
          >
            Falar com um consultor
          </button>
        </div>
      </>
    );
  };

  return (
    <section className="branch-list">
      <div className="branch-list__header">
        <BiStore />
        <h1>Unidades e Filiais</h1>
      </div>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="branch-list__info wrapper-1-1">
            {renderInfo()}
            <p>
              <small>
                Obs: Seus canais de vendas não poderão modificar o seu catálogo,
                somente escolher o que estará disponível{' '}
              </small>
            </p>
          </div>
          <h2>Filiais Cadastradas</h2>
          <div className="branch-list__content">
            <div className="separate-content-line" />
            {renderChildren()}
          </div>
        </>
      )}
    </section>
  );
};

export default BrachList;
