import styled from 'styled-components';
import { fadeIn } from '../../../../../styles/animations';

export const CustomizeWrapper = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  animation: ${fadeIn} 0.6s;
  @media (min-width: 1024px) {
    padding: 0 5rem;
  }

  .save-content-bar {
    width: 100%;
    margin: 2rem 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (min-width: 1024px) {
      margin: 1rem 0 0;
    }

    button {
      border: none;
      border-radius: 4px;
      background: #fd397a;
      color: #fff;
      padding: 0.5rem 1rem;
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  h2 {
    display: flex;
    flex: 1;
    width: 100%;
    margin: 0 0 2.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 21px;
    align-items: center;
    color: #171733;
    span {
      font-family: 'Lato', sans-serif;
      margin-right: 0.5rem;
      color: #fd397a;
    }
  }
`;

export const Subtitle = styled.div`
  width: 100%;
  text-align: left;
  line-height: 21px;
  color: #232323;
  margin: 0 0 1rem;
  p {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  span {
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

export const CreateNewButton = styled.button`
  background-color: #fff;
  border: 1px solid #fd397a;
  width: 100%;
  color: #fd397a;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  padding: 0.5rem;
  outline: none !important;
`;

export const ImageUploadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 2rem;
  label {
    width: 100%;
    border: 1px solid #fd397a;
    border-radius: 4px;
    color: #fd397a;
    background: #fff;
    font-weight: 500;
    margin: 0;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    svg {
      margin: 0 0.5rem 0 0;
    }
    &:active,
    &:hover {
      color: #fff;
      background: #fd397a;
    }
  }
  input[type='file'] {
    display: none;
  }
  img {
    width: 8rem;
    height: auto;
    object-fit: contain;
    max-height: 8rem;
    margin: 0 0 1.2rem;
  }
`;

export const SocialMedia = styled.div`
  width: 100%;
  margin: 0 0 2rem;
  .ant-input-affix-wrapper {
    width: 100%;
    margin: 0.5rem 0.5rem 0.5rem 0;
    @media (min-width: 1024px) {
      width: 45%;
    }
  }
`;

export const SwitchArabesque = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 1.5rem;
  p {
    font-size: 0.7rem;
    margin: 0;
  }
`;

export const Colors = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const ColorsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (min-width: 1024px) {
    width: 45%;
  }

  p {
    font-size: 0.7rem;
  }
`;

export const ColorsRow = styled.div`
  width: 100%;
  min-height: 45px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
`;
