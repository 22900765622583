import React, { FC } from 'react';
import { message, Popconfirm } from 'antd';
import { FiCopy, FiEdit, FiTrash2 } from 'react-icons/fi';
import { GoFileCode, GoMail } from 'react-icons/go';

import { ISeller } from '../../../../../../Types';

import { useAuth } from '../../../../../../hooks/auth';

import { ReactComponent as WhatsIcon } from '../../../../../../assets/icons/svg-icons/whatsapp.svg';
import { ReactComponent as SellerIcon } from '../../../../../../assets/icons/svg-icons/seller.svg';

import './styles.scss';

interface IProps {
  sellerData: ISeller;
  deleteSeller: (seller: ISeller) => void;
  callEdit: (seller: ISeller) => void;
}

const SellerCard: FC<IProps> = (props) => {
  const { sellerData, callEdit, deleteSeller } = props;

  const { user } = useAuth();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `https://store.suitesha.re/${user.company.social_url}?seller=${sellerData.id}`,
    );
    message.success('Link copiado com sucesso!');
  };

  const shareInWhatsApp = () => {
    const text = `*Oi%20${sellerData.name}*,%20olha%20que%20demais%20isso!%0a%0aEstou%20passando%20aqui%20para%20te%20enviar%20o%20link%20oficial%20do%20seu%20catálogo%20da%20${user.company.name}.%0a%0aVocê%20só%20precisa%20compartilhar%20esse%20link%20com%20seus%20clientes%20para%20começar%20a%20receber%20pedidos%20no%20seu%20WhatsApp.%0a%0ahttps://store.suitesha.re/${user.company.social_url}?seller=${sellerData.id}%0a%0aBoas%20vendas!`;

    const url = `https://wa.me/${sellerData.phone}?text=${text}`;

    window.open(url, 'blank');
  };

  return (
    <li className="sellers-card column center">
      <div className="sellers-card__row-wrapper wrapper-1-1 row ya-start between">
        <div className="sellers-card__seller-info row center ya-start">
          <figure className="seller-info__image-wrapper">
            <span>
              <SellerIcon />
            </span>
          </figure>
          <div className="seller-info__text-wrapper column start">
            <p>{sellerData.name}</p>
            <span>
              <GoMail />
              {sellerData.email}
            </span>
            <span className="text-wrapper__whats-icon">
              <WhatsIcon />
              {sellerData.phone}
            </span>
            <span>
              <GoFileCode />
              {sellerData.code}
            </span>
          </div>
        </div>
      </div>
      <div className="sellers-card__row-wrapper wrapper-1-1 row center between">
        <div className="sellers-card__help-buttons wrapper-1-2 row ya-center">
          <button
            className="help-button help-button--whatsapp"
            type="button"
            title="Envie o link do vendedor pelo WhatsApp"
            onClick={() => shareInWhatsApp()}
          >
            <span>
              <WhatsIcon />
            </span>
          </button>
        </div>
        <div className="sellers-card__action-buttons wrapper-1-2 row xa-end">
          <button
            className="action-buttons__button action-buttons__button--copy"
            type="button"
            title="Clique para copiar o link do vendedor"
            onClick={() => copyToClipboard()}
          >
            <span>
              <FiCopy />
            </span>
          </button>
          <button
            className="action-buttons__button action-buttons__button--edit"
            type="button"
            title="Clique para editar o cadastro do vendedor"
            onClick={() => callEdit(sellerData)}
          >
            <span>
              <FiEdit />
            </span>
          </button>

          <Popconfirm
            title="Tem certeza que deseja remover esse vendedor?"
            onConfirm={() => deleteSeller(sellerData)}
            okText="Sim"
            cancelText="Não"
          >
            <button
              className="action-buttons__button action-buttons__button--delete"
              type="button"
              title="Clique excluir o vendedor"
            >
              <span>
                <FiTrash2 />
              </span>
            </button>
          </Popconfirm>
        </div>
      </div>
    </li>
  );
};

export default SellerCard;
