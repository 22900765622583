import styled from 'styled-components';
import { Container } from '../../../styles/default';
import { fadeIn } from '../../../styles/animations';

export const CategoriesRegistrationContainer = styled(Container)`
  flex: 0 0 100%;
  flex-wrap: wrap;
  padding: 0 2.5rem;
  align-items: flex-start;
`;

export const Content = styled.div`
  width: 100%;
  margin: 3rem 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: ${fadeIn} 0.5s;

  #code-preview {
    margin-bottom: 16px;
    font-size: 12px;
  }
`;

export const StyledForm = styled.div`
  width: 100%;
  min-height: 10rem;
  margin: 2.5rem 0 0 0;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #000000;
  .ant-upload-picture-card-wrapper.avatar-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  form {
    width: 60%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input {
      width: 100%;
      border: 1px solid rgba(170, 170, 170, 0.6);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 2px;
      padding: 0.4rem 0.8rem;
      margin: 0rem 0 1rem;
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  h2 {
    display: flex;
    flex: 1;
    width: 100%;
    margin: 0 0 2.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 21px;
    align-items: center;
    color: #171733;
    span {
      font-family: 'Lato', sans-serif;
      margin-right: 0.5rem;
    }
  }
`;

export const Subtitle = styled.div`
  width: 100%;
  text-align: left;
  line-height: 21px;
  color: #232323;
  margin: 0;
  p {
    font-size: 0.9rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
  }
  span {
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

export const NavigationButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 385px;
  margin: 1rem 0;
  justify-content: flex-end;
`;

export const Button = styled.button`
  width: 180px;
  height: 36px;
  background: #ffa800;
  border: 0;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 19px;
  color: #f5f5f5;
`;
