import React, { FC, useState } from 'react';
import { message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FiLock } from 'react-icons/fi';

import { useAuth } from '../../../../../hooks/auth';
import api from '../../../../../services/api';
import UserServices from '../../../../../services/user';

const SecurityInfo: FC = () => {
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [passwordChange, setPasswordChange] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    const currUser = user;

    const isPasswordTrue = await api
      .post('sessions', {
        email: user.email,
        password: passwordChange.oldPassword,
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        message.info('A senha atual não está correta');
        return false;
      });

    if (isPasswordTrue) {
      if (passwordChange.confirmNewPassword !== '') {
        if (passwordChange.newPassword === passwordChange.confirmNewPassword) {
          setIsLoading(true);
          currUser.password = passwordChange.newPassword;

          await UserServices.putUserData(currUser)
            .then(() => {
              message.success('Senha alterada com sucesso');
              setPasswordChange({
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: '',
              });
            })
            .catch(() => message.error('Não foi possível atualizar sua senha'))
            .finally(() => setIsLoading(false));
        } else {
          message.info('A confirmação de senha não confere');
        }
      } else {
        message.info('Você precisa confimar a nova senha');
      }
    }
  };

  return (
    <div id="company-info" className="view-wrapper">
      <div
        className="title-wrapper wrapper-1-1 row xa-start ya-center"
        style={{ gap: '0.5rem', margin: '0 0 2rem' }}
      >
        <FiLock />
        <h1>Segurança</h1>
      </div>

      <form className="config-page-form" action="">
        <div className="config-page-register-data">
          <h1>E-mail Cadastrado</h1>
          <div className="config-page-form-item">
            <p>E-mail</p>
            <input value={user.email} disabled />
          </div>
        </div>
        <div className="config-page-adress">
          <h1>Trocar senha</h1>
          <label htmlFor="old-password" className="config-page-form-item">
            <p>Senha atual</p>
            <input
              type="password"
              id="old-password"
              name="Senha atual"
              placeholder="Digite a senha atual"
              value={passwordChange.oldPassword}
              onChange={({ target: { value } }) => {
                setPasswordChange({ ...passwordChange, oldPassword: value });
              }}
              required
            />
          </label>
          <label htmlFor="new-password" className="config-page-form-item">
            <p>Nova senha</p>
            <input
              type="password"
              id="new-password"
              name="Nova senha"
              placeholder="Digite a nova senha"
              value={passwordChange.newPassword}
              onChange={({ target: { value } }) => {
                setPasswordChange({
                  ...passwordChange,
                  newPassword: value,
                });
              }}
              required
            />
          </label>
          <label
            htmlFor="confirm-new-password"
            className="config-page-form-item"
          >
            <p>Confirmar Nova senha</p>
            <input
              type="password"
              id="confirm-new-password"
              name="Confirmar Nova senha"
              placeholder="Digite a nova senha novamente"
              value={passwordChange.confirmNewPassword}
              onChange={({ target: { value } }) => {
                setPasswordChange({
                  ...passwordChange,
                  confirmNewPassword: value,
                });
              }}
              required
            />
          </label>
        </div>
        <div className="config-page-form-item button-wrapper">
          <button type="submit" onClick={(e) => handleSubmit(e)}>
            {isLoading ? <LoadingOutlined /> : 'Editar'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SecurityInfo;
