import React, { FC, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FiGrid, FiChevronsDown } from 'react-icons/fi';

import { GeneralStoreContext } from '../../stores/GeneralStore';

import storeLogo from '../../assets/logos/products/store.png';
import connectLogo from '../../assets/logos/products/connect.png';

import './styles.scss';

interface IUrlParams {
  app: string;
}

const SuiteAppsContainer: FC = observer(() => {
  const generalStore = useContext(GeneralStoreContext);
  const { app } = useParams<IUrlParams>();

  const [openDropbox, setOpenDropbox] = useState(false);

  useEffect(() => {
    generalStore.changeCurrApp(app);
  }, [app, generalStore]);

  return (
    <button
      id="suiteapps-button"
      type="button"
      onClick={() => setOpenDropbox(!openDropbox)}
    >
      <FiGrid
        style={{
          color: openDropbox ? '#36e481' : '#232323',
          transform: openDropbox ? 'rotate(135deg)' : 'rotate(0deg)',
        }}
      />
      Aplicativos
      <button
        className="suiteapps-mask-div"
        type="button"
        onClick={() => setOpenDropbox(false)}
        style={{
          display: openDropbox ? 'block' : 'none',
        }}
      >
        mask
      </button>
      <div className={`suiteapps-dropdown ${openDropbox ? 'oppened' : ''}`}>
        <button
          className="closebutton"
          type="button"
          onClick={() => setOpenDropbox(false)}
        >
          <FiChevronsDown />
        </button>
        <Link to="/store/products" onClick={() => setOpenDropbox(false)}>
          <img src={storeLogo} alt="Store" />
        </Link>
        <Link to="/connect/links" onClick={() => setOpenDropbox(false)}>
          <img src={connectLogo} alt="Connect" />
        </Link>
      </div>
    </button>
  );
});

export default SuiteAppsContainer;
