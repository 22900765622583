import React, { createRef, useCallback, useContext, useEffect } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Form, Input, Checkbox, notification } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useAuth } from '../../../hooks/auth';

import Paths from '../../../routes/Paths';
import { GeneralStoreContext } from '../../../stores/GeneralStore';

import SideImage from '../../../components/_Sessions/SideImage';

import { Button, Content, AnimationContainer } from './styles';

import storeTitle from '../../../assets/logos/products/store-title.png';
import connectTitle from '../../../assets/logos/products/connect.png';

interface LoginFormData {
  email: string;
  password: string;
}

interface IUrlParams {
  app: string;
}

const Login: React.FC = observer(() => {
  const generalStore = useContext(GeneralStoreContext);
  const { app } = useParams<IUrlParams>();

  const { login } = useAuth();
  const history = useHistory();

  const formRef = createRef<FormInstance>();

  useEffect(() => {
    generalStore.changeCurrApp(app);
  }, [app, generalStore]);

  const handleSubmit = useCallback(
    async (data: LoginFormData) => {
      try {
        await login({
          email: data.email.toLowerCase().trim(),
          password: data.password.trim(),
        });

        history.push(app === 'connect' ? '/connect/links' : Paths.products.url);
      } catch (error) {
        notification.error({
          message: 'Erro ao efetuar login',
          description: `Usuário ou senha incorreto. Por favor, verifique seus dados.`,
          className: 'error-message',
          duration: 2,
        });
      }
    },
    [login, history, app],
  );

  return (
    <>
      <SideImage app={app} />
      <Content>
        <AnimationContainer>
          <img
            src={app === 'connect' ? connectTitle : storeTitle}
            alt="Suiteshare"
          />
          <Form
            ref={formRef}
            onFinish={handleSubmit}
            initialValues={{ remember: true }}
          >
            <h1>Se já possui uma conta, faça seu login!</h1>
            <div className="user-login">
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Por favor, insira seu e-mail' },
                ]}
              >
                <Input prefix={<FiMail />} placeholder="E-mail" />
              </Form.Item>
            </div>
            <div className="user-login">
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Por favor, insira sua senha' },
                ]}
              >
                <Input.Password prefix={<FiLock />} placeholder="Senha" />
              </Form.Item>
            </div>
            <div className="check-and-forgot">
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Lembrar</Checkbox>
              </Form.Item>
              <a
                href="https://whts.co/adalberto"
                target="_blank"
                rel="noopener noreferrer"
              >
                Esqueci minha senha
              </a>
            </div>
            <Button
              className={app === 'connect' ? 'connect' : ''}
              type="submit"
            >
              ENTRAR
            </Button>
          </Form>
          <div className="bellow-link">
            <p>Ainda não tem uma conta?</p>
            {app === 'connect' ? (
              <Link to="/connect/signup"> Crie uma agora</Link>
            ) : (
              <Link to="/store/signup"> Crie uma agora</Link>
            )}
          </div>
        </AnimationContainer>
      </Content>
    </>
  );
});

export default Login;
