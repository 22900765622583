import styled from 'styled-components';
import { fadeIn } from '../../../../../styles/animations';

export const Content = styled.div`
  width: 100%;
  margin: 2rem 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: ${fadeIn} 0.5s;
  @media (min-width: 1024px) {
    margin: 3rem 0 0 0;
  }

  .categ-modal-body {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 3px 6px 6px #767676;
    position: relative;
    padding: 1rem;
    z-index: 22;
    @media (min-width: 1024px) {
      width: 430px;
      height: auto;
    }
  }
  .product-filters {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    @media (min-width: 1024px) {
      max-width: unset;
      margin: 0 0 1rem;
    }
    .ant-input-affix-wrapper.table-search {
      width: 100%;
      max-width: 400px;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      padding: 5px 10px;
      @media (min-width: 1024px) {
        width: 45%;
      }
      input::placeholder {
        color: #a1a1a1;
      }
      .ant-input-suffix {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
`;

export const ActionBar = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-align: center;
  @media (min-width: 1024px) {
    max-width: unset;
    align-items: center;
    padding: 0 0 0 0.3rem;
  }

  .text-wrapper {
    font-family: 'Poppins', sans-serif;
    text-align: left;
    h1 {
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 0;
      @media (min-width: 1024px) {
        font-size: 1.3rem;
        margin-bottom: 0.6rem;
      }
    }
    p {
      font-size: 0.8rem;
      font-weight: 400;
      margin-bottom: 0;
      @media (min-width: 1024px) {
        margin-bottom: 1rem;
      }
      span {
        font-family: 'Lato', sans-serif;
        font-size: 0.9rem;
      }
    }
  }
`;

export const StyledForm = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #000000;
  @media (min-width: 1024px) {
    min-height: 10rem;
    background-color: #ffffff;
    border: 2px solid #f5f5f5;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.1);
  }

  .rdt_TableHeadRow {
    box-shadow: 0px 4px 4px #f5f5f5, 0px -4px 4px rgba(0, 0, 0, 0.05);
  }
  #column-code,
  #column-undefined,
  #column-description {
    div {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 110%;
        height: 3px;
        background: #ffb822;
        bottom: -4px;
        left: 0;
      }
    }
  }
  .rdt_TableBody {
    background: linear-gradient(
      0deg,
      rgba(211, 211, 211, 0.19) 0%,
      rgba(255, 255, 255, 0.51) 71.62%
    ) !important;
  }
  .tableButton {
    border: none;
    background: none;
    color: #00b2ff;
    @media (min-width: 1024px) {
      margin: 0 0 0 1.2rem;
    }
    &.remove {
      color: #f27878;
    }
  }
`;

export const AddCategory = styled.button`
  outline: none;
  border: none;
  display: flex;
  background: #ffa800;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 0.8rem;
  color: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(254, 169, 34, 0.25);
  @media (min-width: 1024px) {
    padding: 0.5rem 2rem;
  }

  :focus {
    outline: none;
  }
  &.disabled {
    background: #c0c0c0;
    cursor: not-allowed;
  }
`;
