import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import bgImage from '../../../assets/sessions-bg.png';
import { Container } from '../../../styles/default';

export const ForgotPasswordContainer = styled(Container)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-image: url(${bgImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ImgContainer = styled.div`
  width: 45%;
  min-width: 400px;
  display: flex;
  justify-content: center;
  align-items: content;
  figure {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  width: 45%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  place-content: center;
  form {
    width: 100%;
    h1 {
      font-size: 1rem;
      margin: 0 0 3rem;
      font-weight: 400;
    }
    div {
      width: auto;
      border: none;
      padding: 0;
      background: none;
      color: #fff !important;
      input {
        width: 100%;
        margin: 0 0 1rem;
        padding: 0.5rem 0;
        background: none;
        border-bottom: 1px solid #fff;
        color: #fff !important;
        &::placeholder {
          color: #ffff;
          opacity: 1;
        }
      }
    }
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  place-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 80px 0;
    text-align: center;
    button {
      max-width: 352px;
      position: relative;
      padding: 0.7rem 0;
      background: linear-gradient(
        29deg,
        rgba(22, 164, 138, 1) 0%,
        rgba(22, 164, 138, 1) 70%
      );
      border-radius: 5px;
      border: 0;
      color: #fff;
      width: 100%;
      font-weight: 500;
      margin-top: 16px;
      z-index: 1;
      transition: all 0.3s ease;
      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 5px;
        background: linear-gradient(
          29deg,
          rgba(54, 228, 129, 1) 0%,
          rgba(22, 164, 138, 1) 70%
        );
        z-index: -1;
        transition: opacity 0.3s linear;
        opacity: 0;
      }
      &:hover::before {
        opacity: 1;
      }
    }

    a {
      color: #fff;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#fff')};
      }
    }
  }

  > a {
    color: #fff;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: linear-gradient(to top right, #36e481, #16a48a, #36e481, #16a48a);
  background-size: 400% 400%;
`;
