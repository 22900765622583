import React from 'react';
import { NavLink } from 'react-router-dom';
// import { FaCog } from 'react-icons/fa';

import { Container, Logo, MenuItems, MenuItem, Footer } from './styles';

import logoStore from '../../assets/logos/suiteshare-store.png';
import StoreIcon from '../../assets/icons/store.png';
// import ConnectIcon from '../../assets/icons/connect.png';

const Sidebar: React.FC = () => {
  return (
    <Container>
      <Logo>
        <img src={logoStore} height={40} alt="suiteshare" />
      </Logo>
      <MenuItems>
        {/* <MenuItem>
          <NavLink to="/dashboard" title="Suiteshare 3.0">
            <img src={WhtsIcon} alt="Suiteshare 3.0" />
          </NavLink>
        </MenuItem> */}
        <MenuItem>
          <NavLink to="/store/products" title="Store" activeClassName="active">
            <img src={StoreIcon} alt="Store" />
          </NavLink>
        </MenuItem>
        {/* <MenuItem>
          <NavLink to="/connect/links" title="Connect">
            <img src={ConnectIcon} alt="Connect" />
          </NavLink>
        </MenuItem> */}
        {/* <MenuItem>
          <NavLink to="/convertion-flow" title="Short">
            <img src={ShortIcon} alt="Short" />
          </NavLink>
        </MenuItem> */}
      </MenuItems>
      <Footer>
        {/* <NavLink to="/configurations" activeClassName="footer-active">
          <FaCog size={25} />
        </NavLink> */}
      </Footer>
    </Container>
  );
};

export default Sidebar;
