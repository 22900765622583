import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { observer } from 'mobx-react';
import { uuid } from 'uuidv4';
import parse from 'html-react-parser';
import filesize from 'filesize';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Form } from '@unform/web';
import { Editor } from '@tinymce/tinymce-react';
import { Select, message, Radio, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BiImageAdd, BiTrash } from 'react-icons/bi';
import {
  FiChevronLeft,
  FiCamera,
  FiPlus,
  FiFolderPlus,
  FiLink,
} from 'react-icons/fi';

import {
  FileProps,
  ProductImageProps,
  ISpecification,
  ProductProps,
  CategoryProps,
  ISpecificationOption,
} from '../../../../../Types';

import { useAuth } from '../../../../../hooks/auth';

import StoreServices from '../../../../../services/store/stores';
import ProductsServices from '../../../../../services/store/Products';

import { GeneralStoreContext } from '../../../../../stores/GeneralStore';

import {
  resizeFile,
  base64ToFile,
  blobCreationFromURL,
} from '../../../../../utils/resizeFile';

import {
  Content,
  StyledForm,
  Display,
  MobiPreview,
  MobiMask,
  MobiContent,
  Title,
  Subtitle,
  NavigationButtons,
  Button,
  BackButton,
  Step,
  AddNewCategory,
  CategoryRegistrationModal,
  CheckBox,
  ProductImages,
  UploadImage,
} from './styles';

import CropImg from '../../../../../components/CropImg';
import Input from '../../../../../components/Input';
import CurrencyInput from '../../../../../components/CurrencyInput';
import CategoryForm from '../../../../../components/_Store/CategoryForm';

import MobMockup from '../../../../../assets/mob-mockup.png';
import { ReactComponent as Close } from '../../../../../assets/close-btn.svg';
import SpecificationsRegisterModal from '../../../../../components/_Store/SpecificationsRegisterModal';
import SpecificationsServices from '../../../../../services/store/specifications';
import Modal from '../../../../../components/Modal';

interface OptionsType {
  value: string;
}

interface IUrlParams {
  id: string;
}

interface IProps {
  changePage: (page: string) => void;
  productList: string;
}

const ProductRegistration: React.FC<IProps> = observer(() => {
  const { user } = useAuth();
  const history = useHistory();
  const { id } = useParams<IUrlParams>();

  const generalStore = useContext(GeneralStoreContext);

  const [specifications, setSpecifications] = useState<ISpecification[]>();
  const [specificationsSelect, setSpecificationsSelect] = useState<
    OptionsType[]
  >();
  const [specificationSearchValue, setSpecificationSearchValue] = useState<
    string
  >();

  const [selectedSpecification, setSelectedSpecification] = useState<
    ISpecification
  >();
  const [specificationModalState, setSpecificationModalState] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [categoryModalState, setCategoryModalState] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const [categoryOptions, setCategoryOptions] = useState<OptionsType[]>([]);
  const [categorySearchValue, setCategorySearchValue] = useState<string>();

  const [productData, setProductData] = useState<ProductProps>({
    id: '',
    code: '',
    name: '',
    value: '',
    enabled: true,
    images: [],
    category_id: '',
    description: '',
    promotional_value: '',
  });

  const [uploadedFiles] = useState<FileProps[]>([]);
  const [productImages, setProductImages] = useState<FileProps[]>([]);
  const [hasVariant, setHasVariant] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isPromotionalValueValid, setIsPromotionalValueValid] = useState(true);
  // Disable with URL image
  const [hideURLInput, setHideURLInput] = useState(true);
  const [inputURL, setInputURL] = useState('');

  const [imageToCrop, setImageToCrop] = useState<File | null>(null);
  const [cropper, setCropper] = useState<any>();

  const catalogId = generalStore.currentCatalogId;

  useEffect(() => {
    const categOptions: OptionsType[] = [];

    StoreServices.getStoreCategories(user.company_id, catalogId).then(
      (categories) => {
        categories.forEach((category: any) => {
          const categoryName = { value: category.description, id: category.id };
          categOptions.push(categoryName);
        });
        setCategoryOptions(categOptions);
      },
    );

    SpecificationsServices.getSpecifications(user.company_id, catalogId).then(
      (specificationsData) => {
        if (specificationsData) {
          const specificationsSelectOptions: OptionsType[] = [];
          specificationsData.forEach((specification) => {
            const specificationSelect = {
              value: specification.description,
              id: specification.id,
            };
            specificationsSelectOptions.push(specificationSelect);
          });
          setSpecifications(specificationsData);
          setSpecificationsSelect(specificationsSelectOptions);
        }
      },
    );
  }, [user.company_id, catalogId]);

  useEffect(() => {
    if (id) {
      if (generalStore.isChild) {
        history.push(`/store/products/${id}/view`);
      }
      setIsEditing(true);
      ProductsServices.getProductData(user.company_id, catalogId, id).then(
        (product) => {
          if (product && product.category) {
            setProductData({
              ...product,
              value: `R$ ${parseFloat(
                product.value.replace(/\$/g, '').replace(/,/g, ''),
              ).toLocaleString('pt-BR')}`,
              promotional_value: product.promotional_value
                ? `R$ ${parseFloat(
                    product.promotional_value
                      .replace(/\$/g, '')
                      .replace(/,/g, ''),
                  ).toLocaleString('pt-BR')}`
                : undefined,
            });
            if (product.value === '$0.00') {
              setChecked(true);
            }
            if (
              product.productSpecifications &&
              product.productSpecifications.length > 0
            ) {
              const specificationData =
                product.productSpecifications[0].specification;
              const specificationOptionsData: ISpecificationOption[] = [];

              product.productSpecifications[0].options.forEach((option) => {
                const optionObj = {
                  id: option.specificationOption.id,
                  specification_id: option.specificationOption.specification_id,
                  description: option.specificationOption.description,
                  enabled: option.enabled,
                };

                specificationOptionsData.push(optionObj);
              });

              setHasVariant(1);

              if (specificationData && specificationOptionsData.length > 0) {
                setSelectedSpecification({
                  id: specificationData.id,
                  company_id: specificationData.company_id,
                  description: specificationData.description,
                  enabled: specificationData.enabled,
                  options: specificationOptionsData,
                });
              }
            }
          }
        },
      );
    }
  }, [
    isEditing,
    user.company_id,
    id,
    generalStore.isChild,
    history,
    catalogId,
  ]);

  const notFoundContentForSelect =
    categoryOptions.length >= 10 ? (
      <AddNewCategory>
        <button
          type="button"
          onClick={() => setCategoryModalState(true)}
          className="disabled"
          disabled
          title="Entre em contato com nossa equipe comercial caso queira cadastrar mais categorias"
        >
          <FiPlus size={20} />
          nova categoria
        </button>
      </AddNewCategory>
    ) : (
      <AddNewCategory>
        <button type="button" onClick={() => setCategoryModalState(true)}>
          <FiPlus size={20} />
          nova categoria
        </button>
      </AddNewCategory>
    );

  const notFoundContentForSpecifications = (
    <AddNewCategory>
      <button type="button" onClick={() => setSpecificationModalState(true)}>
        <FiPlus size={20} />
        nova variação
      </button>
    </AddNewCategory>
  );

  const checkedValue = (a: boolean) => {
    if (a) {
      setIsPromotionalValueValid(true);
      setChecked(true);
      setProductData({
        ...productData,
        value: '$0.00',
        promotional_value: '$0.00',
      });
    } else {
      setChecked(false);
      setProductData({ ...productData, value: '', promotional_value: '' });
    }
  };

  const handleStep = (n: number) => {
    if (
      productData.name &&
      productData.description &&
      productData.category_id
    ) {
      setCurrentStep(n);
    }
  };

  const handleSubmit = async () => {
    if (
      productData.name &&
      productData.category_id &&
      productData.description
    ) {
      try {
        setIsLoading(true);

        const product = {
          code: productData.code,
          name: productData.name,
          description: productData.description,
          category_id: productData.category_id,
          value: productData.value
            ? parseFloat(
                productData.value
                  .replace('R', '')
                  .replace('$', '')
                  .replace(/\./g, '')
                  .replace(/,/g, '.'),
              ).toLocaleString('en-US')
            : '$0.00',
          promotional_value: productData.promotional_value
            ? parseFloat(
                productData.promotional_value
                  .replace('R', '')
                  .replace('$', '')
                  .replace(/\./g, '')
                  .replace(/,/g, '.'),
              ).toLocaleString('en-US')
            : null,
          company_id: user.company_id,
          store_id: catalogId,
        };

        const newProduct = await ProductsServices.postNewProduct(
          user.company_id,
          catalogId,
          product,
        );

        if (selectedSpecification) {
          ProductsServices.postProductSpecification(
            user.company_id,
            catalogId,
            newProduct.id,
            selectedSpecification.id,
            selectedSpecification.enabled,
          ).then(async (productSpecificationData) => {
            if (productSpecificationData) {
              await Promise.all(
                selectedSpecification.options.map((specificationOption) => {
                  return ProductsServices.postProductSpecificationOption(
                    user.company_id,
                    catalogId,
                    newProduct.id,
                    {
                      product_specification_id: productSpecificationData.id,
                      specification_option_id: specificationOption.id,
                      sku: '',
                      position: specificationOption.position,
                      enabled: specificationOption.enabled,
                    },
                  );
                }),
              );
            }
          });
        }

        if (productImages.length > 0) {
          productImages.forEach(async (file, index) => {
            const upFile: FileProps = {
              file: file.file,
              name: file.name,
              position: index,
              path: URL.createObjectURL(file.file),
              readableSize: filesize(file.file.size),
            };

            const img = await resizeFile(upFile.file);
            const nFile = await base64ToFile(img, file.name);
            const image = new FormData();
            image.append('file', nFile, nFile.name);

            await ProductsServices.postProductImageWithPosition(
              user.company_id,
              catalogId,
              newProduct.id,
              image,
              upFile.position,
            );
          });
        }
      } catch (err) {
        message.error(
          'Não foi possível casdastrar. Tente novamente mais tarde',
        );
        setIsLoading(false);
      }

      message.success('Produto cadastrado com sucesso!');
      history.push('/store/products');
    } else {
      message.info('É necessário preencher todos os campos');
    }
  };

  const handleProductEdit = () => {
    if (
      productData.name &&
      productData.category_id &&
      productData.description
    ) {
      setIsLoading(true);

      ProductsServices.editProduct(user.company_id, catalogId, {
        ...productData,
        value: productData.value
          ? parseFloat(
              productData.value
                .replace('R', '')
                .replace('$', '')
                .replace(/\./g, '')
                .replace(/,/g, '.'),
            ).toLocaleString('en-US')
          : '$0.00',
        promotional_value: productData.promotional_value
          ? parseFloat(
              productData.promotional_value
                .replace('R', '')
                .replace('$', '')
                .replace(/\./g, '')
                .replace(/,/g, '.'),
            ).toLocaleString('en-US')
          : null,
      })
        .then(async () => {
          // Already has a specification
          if (
            productData.productSpecifications &&
            productData.productSpecifications[0] &&
            selectedSpecification
          ) {
            // Is it the same specification?
            if (
              productData.productSpecifications[0].specification_id !==
              selectedSpecification.id
            ) {
              // Delete the current specification and then add a new one
              ProductsServices.deleteProductSpecification(
                user.company_id,
                catalogId,
                productData.id,
                productData.productSpecifications[0],
              ).then(() => {
                ProductsServices.postProductSpecification(
                  user.company_id,
                  catalogId,
                  productData.id,
                  selectedSpecification.id,
                  selectedSpecification.enabled,
                ).then(async (productSpecificationData) => {
                  if (productSpecificationData) {
                    await Promise.all(
                      selectedSpecification.options.map(
                        (specificationOption) => {
                          return ProductsServices.postProductSpecificationOption(
                            user.company_id,
                            catalogId,
                            productData.id,
                            {
                              product_specification_id:
                                productSpecificationData.id,
                              specification_option_id: specificationOption.id,
                              sku: '',
                              position: specificationOption.position,
                              enabled: specificationOption.enabled,
                            },
                          );
                        },
                      ),
                    );
                  }
                });
              });
              // A new specification?
            } else if (
              productData.productSpecifications[0].specification_id ===
              selectedSpecification.id
            ) {
              // Iterar opções e ver se mudou

              await Promise.all(
                productData.productSpecifications[0].options.map(
                  // eslint-disable-next-line array-callback-return
                  async (productSpecificationOption) => {
                    const selectedOption = selectedSpecification.options.filter(
                      (o) =>
                        o.id ===
                        productSpecificationOption.specification_option_id,
                    );
                    if (selectedOption && selectedOption.length > 0) {
                      if (
                        selectedOption[0].enabled !==
                        productSpecificationOption.enabled
                      ) {
                        if (
                          productData.productSpecifications &&
                          productData.productSpecifications[0]
                        ) {
                          ProductsServices.putProductSpecificationOption(
                            user.company_id,
                            catalogId,
                            productData.id,
                            productData.productSpecifications[0].id,
                            {
                              id: productSpecificationOption.id,
                              sku: productSpecificationOption.sku,
                              position: productSpecificationOption.position,
                              enabled: selectedOption[0].enabled,
                            },
                          );
                        }
                      }
                    }
                  },
                ),
              );
              // Doesnt have a specification
            }
          } else if (selectedSpecification) {
            ProductsServices.postProductSpecification(
              user.company_id,
              catalogId,
              productData.id,
              selectedSpecification.id,
              selectedSpecification.enabled,
            ).then(async (productSpecificationData) => {
              if (productSpecificationData) {
                await Promise.all(
                  selectedSpecification.options.map((specificationOption) => {
                    return ProductsServices.postProductSpecificationOption(
                      user.company_id,
                      catalogId,
                      productData.id,
                      {
                        product_specification_id: productSpecificationData.id,
                        specification_option_id: specificationOption.id,
                        sku: '',
                        position: specificationOption.position,
                        enabled: specificationOption.enabled,
                      },
                    );
                  }),
                );
              }
            });
          }
        })
        .then(() => {
          ProductsServices.getProducts(user.company_id, catalogId).then(
            (products) => {
              const currentProduct = products.filter(
                (o: ProductProps) => o.id === id,
              );
              if (currentProduct[0]) {
                setProductData(currentProduct[0]);
                if (currentProduct[0].value === '$0.00') {
                  setChecked(true);
                }
              }
            },
          );
        })
        .catch((error) => {
          message.error(error.message);
        })
        .finally(() => {
          setIsLoading(false);
          message.success('Produto editado com sucesso');
          history.push('/store/products');
        });
    } else {
      message.error('Nenhum campo pode estar vazio');
    }
  };

  const defineSelectDefaultValue = (category: CategoryProps) => {
    const newCategory = {
      value: category.description,
      id: category.id,
    };

    setCategoryOptions([...categoryOptions, newCategory]);

    setProductData({
      ...productData,
      category_id: category.id,
      category: {
        id: category.id,
        code: category.code,
        enabled: category.enabled,
        image_url: category.image_url,
        description: category.description,
      },
    });
  };

  const captureSearchInputValue = (value: string) => {
    setCategorySearchValue(value);
  };

  const captureSpecificationSeatchInputValue = (value: string) => {
    setSpecificationSearchValue(value);
  };

  const handleCategoryChange = (event: any, value: any) => {
    setProductData({
      ...productData,
      category_id: value?.id,
      category: {
        id: value?.id,
        code: '',
        enabled: true,
        image_url: '',
        description: value?.value,
      },
    });
  };

  const handleSpecificationChange = (event: any, value: any) => {
    if (value) {
      const selectedSpecificationFilter = specifications?.filter(
        (o) => o.id === value.id,
      );

      if (selectedSpecificationFilter) {
        setSelectedSpecification(selectedSpecificationFilter[0]);
      }
    }
  };

  const checkEnabledOptions = (index: number) => {
    const currSelectedSpecification = selectedSpecification;

    if (currSelectedSpecification) {
      currSelectedSpecification.options[
        index
      ].enabled = !currSelectedSpecification.options[index].enabled;

      setSelectedSpecification(currSelectedSpecification);
    }
  };

  const showStep = (index: number) => {
    const hidden: React.CSSProperties = {
      display: 'none',
    };

    const visible: React.CSSProperties = {
      display: 'flex',
    };

    return currentStep === index ? visible : hidden;
  };

  const closeModal = () => {
    setCategoryModalState(false);
  };

  const closeVariantModal = (recentlyCreatedSpecification?: ISpecification) => {
    setSpecificationModalState(false);

    if (specificationsSelect && recentlyCreatedSpecification) {
      const newSpecification = {
        value: recentlyCreatedSpecification.description,
        id: recentlyCreatedSpecification.id,
      };

      setSpecificationsSelect([...specificationsSelect, newSpecification]);
      setSelectedSpecification(recentlyCreatedSpecification);
    }
  };

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    let images = reorder(
      productImages,
      result.source.index,
      result.destination.index,
    );

    if (id) {
      images = reorder(
        productData.images
          .slice()
          .sort(
            (a: ProductImageProps, b: ProductImageProps) =>
              a.position - b.position,
          ),
        result.source.index,
        result.destination.index,
      );

      images.forEach(async (img, index) => {
        const currImage = img;
        currImage.position = index;

        productImages[index] = currImage;

        await ProductsServices.editProductImagePosition(
          user.company_id,
          catalogId,
          id,
          currImage.id,
          currImage.position,
        );
      });

      setProductData({ ...productData, images });
    }

    setProductImages([...images]);
  };

  const onImageChange = async (e?: any) => {
    let file;

    if (!isLoading) {
      if (e) {
        e.preventDefault();
        if (e.dataTransfer) {
          file = e.dataTransfer.files[0];
        } else if (e.target) {
          file = e.target.files[0];
        }
      } else if (inputURL) {
        file = await blobCreationFromURL(inputURL);
        setInputURL('');
      }

      if (file) {
        setImageToCrop(file);
      }
    } else {
      message.warn('Aguarde um momento antes de inserir uma nova imagem');
    }
  };
  // Disable with URL image
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key || event.keyCode;

    if (key === 'Enter' || key === 13) {
      onImageChange();
    }
  };

  const handleSetCropper = (value: any) => {
    setCropper(value);
  };

  const handleNewProductImages = (fileMapped: FileProps) => {
    const currImgArray = productImages;

    currImgArray.push(fileMapped);
    setProductImages([...currImgArray]);
  };

  const handleExistingProductImages = async (fileMapped: FileProps) => {
    const img = await resizeFile(fileMapped.file);
    const nFile = await base64ToFile(img, fileMapped.name);

    const image = new FormData();
    image.append('file', nFile, nFile.name);

    ProductsServices.postProductImageWithPosition(
      user.company_id,
      catalogId,
      id,
      image,
      fileMapped.position,
    ).then(() => {
      ProductsServices.getProducts(user.company_id, catalogId)
        .then((res) => {
          const filteredProduct = res.filter((o: ProductProps) => o.id === id);
          setProductData({
            ...productData,
            images: filteredProduct[0].images,
          });
        })
        .finally(() => {
          message.success('Imagem adicionada com sucesso');
          setIsLoading(false);
        });
    });
  };

  const getCropData = async () => {
    if (typeof cropper !== 'undefined') {
      if (imageToCrop) {
        const guid = uuid();
        const nFile = await base64ToFile(
          cropper.getCroppedCanvas().toDataURL(),
          imageToCrop?.name,
        );

        setIsLoading(true);

        const fileMapped: FileProps = {
          uid: guid,
          file: nFile,
          name: nFile.name,
          position:
            productImages.length > 0
              ? productImages[productImages.length - 1].position + 1
              : 0,
          path: URL.createObjectURL(nFile),
          readableSize: filesize(nFile.size),
        };

        if (fileMapped) {
          if (id) {
            handleExistingProductImages(fileMapped);
          } else {
            handleNewProductImages(fileMapped);
          }
        }

        setIsLoading(false);
        setImageToCrop(null);
      }
    }
  };

  const closeCropperModal = () => {
    setImageToCrop(null);
    if (inputURL) {
      setInputURL('');
    }
  };

  const removeImg = (name: string, index: number) => {
    const currimgarray = productImages;

    const currImg = currimgarray[index];

    if (currImg.name === name) {
      currimgarray.splice(index, 1);

      setProductImages([...currimgarray]);
    }
  };

  const removeUploadedImg = (imageId: string) => {
    // TODO
    /**
     * Componetizar o render de images para ter um controle
     * de quando for feito o upload conseguir setar um loading prop
     * para criar um feedback visual para o usuário
     */
    ProductsServices.deleteImage(user.company_id, catalogId, id, imageId)
      .then(() => {
        if (productData.images.length > 0) {
          const currProdImgsArray = productData.images.filter(
            (img: ProductImageProps) => img.id !== imageId,
          );
          currProdImgsArray.forEach((img: ProductImageProps, index: number) => {
            const currImg = img;
            currImg.position = index;

            ProductsServices.editProductImagePosition(
              user.company_id,
              catalogId,
              id,
              currImg.id,
              currImg.position,
            );
          });
        }
      })
      .then(() => {
        ProductsServices.getProducts(user.company_id, catalogId).then((res) => {
          const filteredProduct = res.filter((o: ProductProps) => o.id === id);
          setProductData({ ...productData, images: filteredProduct[0].images });
        });
      })
      .finally(() => {
        message.success('Imagem excluída com sucesso');
      });
  };

  // Disable with URL image
  const inputFocus = useRef<any>();

  const toggleUrlInput = () => {
    if (hideURLInput) {
      setHideURLInput(false);
      setTimeout(() => {
        inputFocus.current.focus();
      }, 100);
    } else {
      setHideURLInput(true);
      setInputURL('');
    }
  };

  const renderUploadedImages = () => {
    if (productImages.length > 0) {
      return productImages.map((img, index) => {
        return (
          <Draggable
            key={img.uid}
            draggableId={img.uid || img.path}
            index={index}
          >
            {(provided) => (
              <li
                key={img.uid}
                {...provided.dragHandleProps}
                {...provided.draggableProps}
                ref={provided.innerRef}
              >
                <div className="delete-button-mask">
                  <button
                    type="button"
                    onClick={() => removeImg(img.name, index)}
                  >
                    <BiTrash />
                  </button>
                </div>
                <img src={URL.createObjectURL(img.file)} alt={img.name} />
              </li>
            )}
          </Draggable>
        );
      });
    }
    return <div />;
  };

  // TODO texto de span
  // Travar avanço se promotional for menor
  // Tirar o estado e colocar promotional value

  const changePromotionalValue = (inputValue: string) => {
    setProductData({
      ...productData,
      promotional_value: inputValue,
    });

    if (inputValue) {
      const pValue = Number(
        inputValue
          .replace(/R/g, '')
          .replace(/\$/g, '')
          .replace(/,/g, '')
          .replace(/,/g, '.')
          .trim(),
      );
      const value = Number(
        productData.value
          .replace(/R/g, '')
          .replace(/\$/g, '')
          .replace(/,/g, '')
          .replace(/,/g, '.')
          .trim(),
      );

      if (pValue < value) {
        setIsPromotionalValueValid(true);
      } else {
        setIsPromotionalValueValid(false);
      }
    } else {
      setIsPromotionalValueValid(true);
    }
  };

  const changePriceValue = (inputValue: string) => {
    setProductData({
      ...productData,
      value: inputValue,
    });
  };

  const renderProductImgs = () => {
    if (productData.images) {
      if (productData.images.length > 0) {
        return productData.images
          .slice()
          .sort(
            (a: ProductImageProps, b: ProductImageProps) =>
              a.position - b.position,
          )
          .map((img: ProductImageProps, index: number) => {
            return (
              <Draggable key={img.id} draggableId={img.id} index={index}>
                {(provided) => (
                  <li
                    key={img.id}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                  >
                    <div className="delete-button-mask">
                      <button
                        type="button"
                        onClick={() => removeUploadedImg(img.id)}
                      >
                        <BiTrash />
                      </button>
                    </div>
                    <LazyLoadImage
                      src={
                        process.env.REACT_APP_BUCKET_ASSETS_URL + img.image_url
                      }
                      alt={`Foto do produto id ${img.id}`}
                      effect="blur"
                      height={150}
                      width={150}
                    />
                  </li>
                )}
              </Draggable>
            );
          });
      }
    }
    return <div />;
  };

  return (
    <Content>
      <CategoryRegistrationModal className={categoryModalState ? 'active' : ''}>
        <div className="mask" />
        <div className="categ-modal-body">
          <div className="categ-modal-header ">
            <h1>
              <FiFolderPlus /> Cadastrar Categoria
            </h1>
            <button
              className="close-btn"
              type="button"
              onClick={() => setCategoryModalState(false)}
            >
              <Close />
            </button>
          </div>
          <CategoryForm
            redirect={closeModal}
            defaultInputValue={categorySearchValue}
            defineSelectDefaultValue={defineSelectDefaultValue}
          />
        </div>
      </CategoryRegistrationModal>
      <div className="text-wrapper">
        <h1>{id ? 'Editar' : 'Cadastrar'} Produto</h1>
      </div>
      <SpecificationsRegisterModal
        isOpen={specificationModalState}
        closeModal={closeVariantModal}
        specificationName={specificationSearchValue}
      />
      <StyledForm>
        <div id="product-registration">
          <div className="steps">
            <div
              className={`button-wrapper ${currentStep === 1 ? 'active ' : ''}`}
            >
              <button type="button" onClick={() => handleStep(1)}>
                <p>1</p>
                <p>Descrição</p>
              </button>
            </div>
            <div
              className={`button-wrapper ${currentStep === 2 ? 'active' : ''}`}
            >
              <button type="button" onClick={() => handleStep(2)}>
                <p>2</p>
                <p>Estoque</p>
              </button>
            </div>
            <div
              className={`button-wrapper ${currentStep === 3 ? 'active' : ''}`}
            >
              <button type="button" onClick={() => handleStep(3)}>
                <p>3</p>
                <p>Variações</p>
              </button>
            </div>
            <div
              className={`button-wrapper ${currentStep === 4 ? 'active' : ''}`}
            >
              <button type="button" onClick={() => handleStep(4)}>
                <p>4</p>
                <p>Imagens</p>
              </button>
            </div>
          </div>
          <Form
            id="product-form"
            onSubmit={() => {
              return true;
            }}
          >
            <Step
              style={showStep(1)}
              className={currentStep === 1 ? 'active' : ''}
            >
              <Title>
                <h2>
                  <span className="number">1</span>Descrição do produto
                </h2>
              </Title>
              <Subtitle>
                <span></span>
              </Subtitle>
              <span>Nome do produto</span>
              <Input
                name="name"
                placeholder="Nome do produto"
                value={productData.name}
                type="text"
                onChange={(e) => {
                  setProductData({ ...productData, name: e.target.value });
                }}
              />
              <span>Categoria</span>
              <Select
                showSearch
                placeholder="Escolha uma categoria"
                optionFilterProp="children"
                value={productData.category?.description}
                onChange={handleCategoryChange}
                onSearch={captureSearchInputValue}
                options={categoryOptions}
                notFoundContent={notFoundContentForSelect}
                filterOption={(inputValue, option) =>
                  option?.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
              <span>Descrição</span>
              <Editor
                apiKey="eu1vtbl5hs2f8s704454vqxlu8354hm2n56rgzwlgmyw4ap4"
                init={{
                  height: 200,
                  menubar: false,
                  placeholder: 'Descrição do produto',
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    // eslint-disable-next-line no-multi-str
                    'undo redo | bold italic | \
                    removeformat',
                }}
                value={productData.description}
                onEditorChange={(content) => {
                  setProductData({
                    ...productData,
                    description: content,
                  });
                }}
              />
              <NavigationButtons style={{ justifyContent: 'flex-end' }}>
                <Button
                  type="button"
                  onClick={() => setCurrentStep(2)}
                  disabled={
                    !productData.name ||
                    !productData.description ||
                    !productData.category_id
                  }
                >
                  Avançar
                </Button>
              </NavigationButtons>
            </Step>
            <Step
              style={showStep(2)}
              className={currentStep === 1 ? 'active' : ''}
            >
              <Title>
                <h2>
                  <span className="number">2</span>Informações de Estoque
                </h2>
              </Title>
              <Subtitle>
                <span></span>
              </Subtitle>

              <div
                className={`currency-input-wrapper ${
                  isChecked ? 'is-disabled' : ''
                }`}
              >
                <span>Referência</span>
                <Input
                  name="sku"
                  placeholder="Referência"
                  type="text"
                  value={productData.code}
                  onChange={(e) =>
                    setProductData({ ...productData, code: e.target.value })
                  }
                />
                <p style={{ fontSize: '0.8rem' }}>
                  * Esse é o código de Identificação único do produto
                </p>
                <span>Preço do produto</span>

                <CheckBox style={{ margin: '1rem 0 0' }}>
                  <label htmlFor="consult-price">
                    <input
                      id="consult-price"
                      type="checkbox"
                      checked={isChecked}
                      onChange={({ target: { checked } }) => {
                        checkedValue(checked);
                      }}
                    />
                    <p> Preço sob consulta</p>
                  </label>
                </CheckBox>
                <CurrencyInput
                  disabled={isChecked}
                  name="value"
                  placeholder="R$ 0,00"
                  value={productData.value}
                  type="text"
                  onChange={(e) => {
                    changePriceValue(e.target.value);
                  }}
                />
                <p
                  style={{
                    visibility: isChecked ? 'hidden' : 'visible',
                    paddingTop: '1rem',
                  }}
                >
                  Preço Promocional
                </p>
                <CurrencyInput
                  disabled={isChecked}
                  name="value"
                  placeholder="R$ 0,00"
                  value={productData.promotional_value}
                  type="text"
                  onChange={(e) => {
                    changePromotionalValue(e.target.value);
                  }}
                />
                <span
                  style={{
                    visibility: isPromotionalValueValid ? 'hidden' : 'visible',
                    fontSize: '0.8rem',
                  }}
                >
                  O Valor do preço promocional deve ser menor que o do Preço
                  padrão do produto.
                </span>
              </div>

              <NavigationButtons>
                <BackButton type="button" onClick={() => setCurrentStep(1)}>
                  <FiChevronLeft />
                  Voltar
                </BackButton>
                <Button
                  type="button"
                  onClick={() => {
                    setCurrentStep(3);
                  }}
                  disabled={
                    !productData.category_id ||
                    !productData.code ||
                    !productData.value ||
                    !isPromotionalValueValid
                  }
                >
                  Avançar
                </Button>
              </NavigationButtons>
            </Step>
            <Step
              style={showStep(3)}
              className={currentStep === 1 ? 'active' : ''}
            >
              <Title>
                <h2>
                  <span className="number">3</span>Variações
                </h2>
              </Title>
              <Subtitle>
                <span></span>
              </Subtitle>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <p style={{ margin: '0 1rem 0 0' }}>
                  Produto possui variações?
                </p>
                <Radio.Group
                  onChange={({ target: { value } }) => setHasVariant(value)}
                  value={hasVariant}
                >
                  <Radio value={1}>Sim</Radio>
                  <Radio value={0}>Não</Radio>
                </Radio.Group>
              </div>

              {!hasVariant ? (
                <div />
              ) : (
                <>
                  <p style={{ margin: '2rem 0 1rem' }}>
                    Crie uma ou mais variações de seus produtos, se houver
                    necessidade.
                  </p>
                  <Select
                    showSearch
                    placeholder="Escolha uma variação"
                    optionFilterProp="children"
                    value={selectedSpecification?.description}
                    onChange={handleSpecificationChange}
                    onSearch={captureSpecificationSeatchInputValue}
                    options={specificationsSelect}
                    notFoundContent={notFoundContentForSpecifications}
                    filterOption={(inputValue, option) =>
                      option?.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />

                  <div
                    style={{
                      width: '100%',
                      margin: '2rem 0 0',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {selectedSpecification ? (
                      selectedSpecification.options?.map((option, index) => {
                        return (
                          <Checkbox
                            key={option.id}
                            defaultChecked={option.enabled}
                            onClick={() => {
                              checkEnabledOptions(index);
                            }}
                          >
                            {option.description}
                          </Checkbox>
                        );
                      })
                    ) : (
                      <div />
                    )}
                  </div>
                </>
              )}
              <NavigationButtons>
                <BackButton type="button" onClick={() => setCurrentStep(2)}>
                  <FiChevronLeft />
                  Voltar
                </BackButton>
                <Button
                  type="button"
                  onClick={() => setCurrentStep(4)}
                  // disabled={!selectedCategory || !priceInput || !codeInput}
                >
                  Avançar
                </Button>
              </NavigationButtons>
            </Step>
            <Step
              style={showStep(4)}
              className={currentStep === 1 ? 'active' : ''}
            >
              <Title>
                <h2>
                  <span className="number">3</span>Imagens do produto
                </h2>
              </Title>
              <Subtitle>
                <span></span>
              </Subtitle>
              <ProductImages>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="link-list" direction="horizontal">
                    {(provided) => (
                      <ul ref={provided.innerRef} {...provided.droppableProps}>
                        <li>
                          <label
                            htmlFor="product-photos-upload"
                            className="label-content"
                          >
                            <BiImageAdd size={20} className="center-image" />

                            <input
                              type="file"
                              name=""
                              id="product-photos-upload"
                              accept=".jpg, .png, .jpeg"
                              onChange={(e) => onImageChange(e)}
                            />
                            <p>Dimensões ideais: 600px X 840px</p>
                          </label>
                        </li>
                        {id ? renderProductImgs() : renderUploadedImages()}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

                {imageToCrop ? (
                  <Modal closeModal={closeCropperModal}>
                    <CropImg
                      imgSrc={imageToCrop}
                      aspectRatio={10 / 14}
                      getCropData={getCropData}
                      handleSetCropper={handleSetCropper}
                    />
                  </Modal>
                ) : (
                  <div />
                )}
              </ProductImages>
              <UploadImage>
                <button
                  type="button"
                  className="text-button"
                  onClick={() => toggleUrlInput()}
                >
                  <FiLink />
                  <p>Adicionar foto por URL </p>
                  <span
                    style={{
                      padding: '0.1rem 0.3rem',
                      margin: '0 0 0 0.3rem',
                      fontSize: '0.6rem',
                      color: '#fff',
                      backgroundColor: 'red',
                      borderRadius: '0.2rem',
                    }}
                  >
                    BETA
                  </span>
                </button>
                <div
                  className="input-url"
                  style={{
                    visibility: hideURLInput ? 'hidden' : 'visible',
                  }}
                >
                  <input
                    placeholder="https://"
                    ref={inputFocus}
                    value={inputURL}
                    disabled={hideURLInput}
                    onChange={(e) => setInputURL(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <button
                    type="button"
                    disabled={isLoading}
                    onClick={() => onImageChange()}
                  >
                    {isLoading ? <LoadingOutlined /> : 'Adicionar'}
                  </button>
                </div>
              </UploadImage>
              <NavigationButtons>
                <BackButton type="button" onClick={() => setCurrentStep(3)}>
                  <FiChevronLeft />
                  Voltar
                </BackButton>
                {isLoading ? (
                  <LoadingOutlined />
                ) : (
                  <Button
                    type="button"
                    onClick={() => (id ? handleProductEdit() : handleSubmit())}
                  >
                    {id ? 'Salvar' : 'Cadastrar'}
                  </Button>
                )}
              </NavigationButtons>
            </Step>
          </Form>
        </div>
        <Display>
          <MobiPreview>
            <MobiMask src={MobMockup} alt="Mobile mockup" />
            <MobiContent>
              <div
                className="slider"
                style={{
                  backgroundImage:
                    uploadedFiles.length > 0
                      ? `url(${uploadedFiles[0].path})`
                      : '',
                }}
              >
                {uploadedFiles.length > 0 ? <div /> : <FiCamera size={45} />}
              </div>
              <div className="product-details">
                <div className="product-name">
                  <p>{productData.name}</p>
                </div>
                <div className="product-price">
                  {!isChecked ? (
                    <p>{productData.value}</p>
                  ) : (
                    <div className="product-consult">
                      <p>Preço</p>
                      <p>Sob consulta</p>
                    </div>
                  )}
                  <span>{productData.category?.description}</span>
                </div>
              </div>
              <div className="product-description">
                <span>Descrição</span>
                <p>{parse(productData.description)}</p>
              </div>
              <div className="fixed-action-buttons">
                <button className="basket" type="button">
                  Adicionar na sacola
                </button>
              </div>
            </MobiContent>
          </MobiPreview>
        </Display>
      </StyledForm>
    </Content>
  );
});

export default ProductRegistration;
