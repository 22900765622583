import styled from 'styled-components';
import { fadeIn } from '../../../../../styles/animations';

export const Content = styled.div`
  padding: 2rem 0 0;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.5s;
  @media (min-width: 1024px) {
    height: 100%;
    padding: 1.5rem 0 0;
  }

  .text-wrapper {
    margin: 0 0 2.4rem 2rem;
    h1 {
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
  .margin-mask {
    display: none;
    position: fixed;
    width: 100%;
    height: 1.5rem;
    background: #fbfbfb;
    top: 135px;
    z-index: 5;
    @media (min-height: 1024px) {
      display: block;
    }
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
  transition: opacity 0.5s ease;
  &.active {
    opacity: 1;
    z-index: 100000;
  }
  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 21;
  }
  .categ-modal-body {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 3px 6px 6px #767676;
    position: relative;
    padding: 2.5rem 2rem;
    z-index: 22;
    @media (min-width: 1024px) {
      width: 480px;
      height: auto;
    }

    .close-btn-wrapper {
      position: absolute;
      right: -2rem;
      top: -1.7rem;
      button {
        padding: 0.2rem 0.2rem;
        border-radius: 50px;
        border: none;
        background: #ffffff;
        color: #bdbdbd;
      }
    }

    .categ-modal-header {
      max-width: 385px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      h1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.2rem;
        font-weight: 600;
        color: #232323;
        margin: 0 0 1.4rem;
        svg {
          color: #000;
          margin-right: 0.5rem;
          font-size: 1.8rem;
        }

        @media (min-width: 1024px) {
          font-size: 1.2rem;
        }
      }
      p {
        font-size: 1rem;
        margin: 2rem 0rem;
      }
      .btns {
        display: flex;
        justify-content: flex-end;
        gap: 2rem;

        margin: 1rem 0.6rem 0;
      }
      .comfirm-btn {
        border-radius: 5px;
        padding: 0.5rem;
      }
      .cancel-btn {
        color: #a1a1a1;
        border: none;
        background: transparent;
        padding: 0.5rem;
      }

      .on {
        color: #fa4a4a;
        border: 2px solid #fa4a4a;
        background: transparent;
        &:hover {
          background-color: #fa4a4a;
          color: #ffffff;
        }
      }
      .off {
        color: #0fa5e7;
        border: 2px solid #0fa5e7;
        background: transparent;
        &:hover {
          background-color: #0fa5e7;
          color: #ffffff;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  width: 90%;
  margin: 0 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media (min-width: 1024px) {
    width: 600px;
  }
`;

export const Block = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 2.5rem;
`;

export const Title = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  color: #232323;
  margin: 0 0 0.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    position: absolute;
    left: -1.5rem;
    color: #232323;
    font-weight: 800;
    font-size: 0.7rem;
    background: #fff;
    border: 1px solid #ffb900;
    padding: 0.1rem 0.3rem;
    border-radius: 0.2rem;
    width: 1.1rem;
    height: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
  }
`;

export const Text = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  color: #232323;
  a {
    color: #232323;
    text-decoration: underline;
    transition: all 0.3s ease;
    &:hover {
      color: #ffb900;
    }
  }
`;

export const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${Text} {
    margin: 0 0 0 1rem;
  }
  .ant-switch {
    min-width: 40px;
    height: 16px;
    line-height: 16px;
  }
  .ant-switch-checked {
    background: #ffe6a2;
    .ant-switch-handle {
      left: calc(100% - 16px - 2px);
      &::before {
        background-color: #ffb900;
      }
    }
  }
  .ant-switch-handle {
    width: 22px;
    height: 22px;
    top: -3px;
    left: -2px;
    &::before {
      border-radius: 50%;
      background-color: #888;
    }
  }
  .anticon {
    vertical-align: 0;
  }
`;

export const RedirectBodyWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 3px -5px 6px #cccc;
  animation: ${fadeIn} 1s;
  @media (min-width: 1024px) {
    height: 10rem;
  }

  p {
    text-align: center;
  }
  .text-wrapper {
    width: 100%;
    text-align: center;
    margin: 1.5rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 1024px) {
      flex-direction: row;
      margin: 0.6rem 0 0.6rem;
    }

    &.link {
      margin: 1.5rem 0 0;
      @media (min-width: 1024px) {
        margin: 0.6rem 0 0.6rem;
      }
    }

    h3 {
      font-size: 0.9rem;
      font-weight: 600;
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 0.8rem;
      &.subtitle {
        margin: 0 0 0 0.2rem;
      }
      span {
        font-weight: 600;
      }
      a {
        color: #232323;
        font-size: 0.8;
        font-weight: 400;
        text-decoration: underline;
        transition: color 0.3s ease;
        &:hover {
          color: #ffb900;
        }
      }
    }
  }
`;

export const ModalFirstScreen = styled(RedirectBodyWrapper)``;

export const ModalSecondScreen = styled(RedirectBodyWrapper)``;

export const StoreRedirect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 4px 6px 7px #cccc;
  .nav-buttons {
    width: 100%;
    height: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 1024px) {
      height: auto;
    }
    button {
      width: 50%;
      font-size: 0.8rem;
      padding: 0.7rem;
      background: #f8f8f8;
      border: none;
      border-top: 3px solid #eaeaea;
      transition: all 0.3s ease;
      @media (min-width: 1024px) {
        font-size: 1rem;
      }

      &.active {
        background: #fff;
        border-color: #ffb900;
        box-shadow: 3px -5px 5px #cccc;
        z-index: 2;
      }
      &.right {
        &.active {
          box-shadow: -3px -5px 5px #cccc;
        }
      }
    }
  }
`;

export const WhtscoInput = styled.div`
  height: 2rem;
  width: 100%;
  margin: 0rem auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(170, 170, 170, 0.6);
  border-radius: 2px;
  @media (min-width: 1024px) {
    height: 2.4rem;
  }

  p {
    width: 45%;
    height: 100%;
    background: #fff;
    color: #a7a7a7;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0;
    padding: 0 0.1rem 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (min-width: 1024px) {
      font-size: 1rem;
    }
  }
  input {
    width: 45%;
    height: 100%;
    font-size: 0.8rem;
    padding: 0;
    border: none;
    text-align: left;
    color: #000;
    @media (min-width: 1024px) {
      font-size: 1rem;
    }

    &::placeholder {
      color: #000;
    }
  }
`;

export const WhatsappInputWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-input-group-wrapper {
    width: 100%;
    height: 2rem;
    @media (min-height: 1024px) {
      height: 2.4rem;
    }
    .ant-input-wrapper.ant-input-group {
      height: 100%;
      input {
        height: 100%;
      }
    }
  }
`;

export const UploadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  label {
    width: 100%;
    background: #ffb900;
    color: #fff;
    font-weight: 600;
    margin: 0;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 2px 2px 4px #a5a5a5;
    transition: box-shadow 0.3s ease;
    svg {
      margin: 0 0.5rem 0 0;
    }
    &:active {
      box-shadow: 0px 0px 4px #a5a5a5;
    }
  }
  input[type='file'] {
    display: none;
  }
  img {
    width: 8rem;
    height: auto;
    object-fit: contain;
    max-height: 8rem;
    margin: 0 0 1.2rem;
  }
`;

export const BtnWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: 1024px) {
    padding: 0 1rem 1rem;
  }
`;

export const Button = styled.button`
  background: #ffb900;
  color: #fff;
  font-weight: 600;
  padding: 0.3rem 3rem;
  outline: none !important;
  border: 1px solid rgba(170, 170, 170, 0.6);
  border-radius: 2px;
  box-shadow: 2px 2px 4px #a5a5a5;
  font-size: 0.8rem;
  transition: all 0.3s ease;
  svg {
    margin: 0 0 0 0.2rem;
    width: 1.2rem;
    height: auto;
  }
  &:hover {
    background: #ff9800;
  }
  &:active {
    box-shadow: 0px 0px 4px #a5a5a5;
  }
`;

export const GoToStore = styled.button`
  width: 100%;
  max-width: 400px;
  color: #fff;
  background: #ffb900;
  padding: 0.6rem 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.2rem;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.35);
  transition: all 0.3s ease;
  &:active {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
  }
  svg {
    margin-right: 0.5rem;
  }
`;
