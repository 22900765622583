import React from 'react';

import Views from '../../Views';

import { Content, AnimationContainer } from './styles';

import { ActionBar } from '../CategoriesList/styles';
import CategoryForm from '../../../../../components/_Store/CategoryForm';

interface IProps {
  changePage: (page: string) => void;
}

const CategoryRegistration: React.FC<IProps> = (props) => {
  const { changePage } = props;

  const redirect = () => {
    changePage(Views.Categories);
  };

  return (
    <Content>
      <AnimationContainer>
        <ActionBar>
          <div className="text-wrapper">
            <h1>Cadastro de categoria</h1>
          </div>
        </ActionBar>
        <CategoryForm redirect={redirect} />
      </AnimationContainer>
    </Content>
  );
};

export default CategoryRegistration;
