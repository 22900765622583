import React, { FC, useState, useEffect, useContext } from 'react';
import {
  message,
  Popconfirm,
  Table,
  Skeleton,
  Empty,
  Input,
  Card,
  Avatar,
  Switch,
} from 'antd';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FiEdit, FiTrash2, FiSearch, FiImage } from 'react-icons/fi';
import { ProductsStoreContext } from '../../../../../../stores/ProductsStore';

import ProductsServices from '../../../../../../services/store/Products';

import Paths from '../../../../../../routes/Paths';

import { useAuth } from '../../../../../../hooks/auth';

import { ProductImageProps, ProductProps } from '../../../../../../Types';

import { Content, StyledForm, ActionBar, AddProduct } from '../styles';
import CatalogSelect from '../../../../../../components/_Store/CatalogSelect';

import emptyProducts from '../../../../../../assets/empty/new-empty-products.png';
import { GeneralStoreContext } from '../../../../../../stores/GeneralStore';

const { Meta } = Card;

const ParentProductList: FC = observer(() => {
  const [isTableLoading, setIsTableLoading] = useState(true);

  const generalStore = useContext(GeneralStoreContext);
  const productStore = useContext(ProductsStoreContext);
  const { user } = useAuth();
  const history = useHistory();

  const catalogId = generalStore.currentCatalogId;

  useEffect(() => {
    if (catalogId !== null) {
      ProductsServices.getProducts(user.company_id, catalogId)
        .then((products) => {
          if (products) {
            productStore.setProductsList(products);
          }
        })
        .finally(() => setIsTableLoading(false));
    }
  }, [productStore, user.company_id, user.company, catalogId]);

  const goToProductRegister = () => {
    history.push(Paths.productRegistration.url);
  };

  const handleDelete = (productId: string, productName: string) => {
    ProductsServices.deleteProduct(productId, catalogId, user.company_id).then(
      (response) => {
        if (response) {
          message.success(`Produto ${productName} foi removido com sucesso`);
          productStore.deleteProductFromList(productId);
        }
      },
    );
  };

  const handleSwitch = (product: ProductProps, enabled: boolean) => {
    if (productStore.products) {
      const currProd = product;

      currProd.enabled = enabled;

      ProductsServices.editProduct(user.company_id, catalogId, currProd).then(
        () => {
          ProductsServices.getProducts(user.company_id, catalogId).then(
            (products) => {
              productStore.setProductsList(products);
            },
          );
        },
      );
    }
  };

  const captureProductRow = (product: ProductProps) => {
    history.push(`/store/products/${product.id}/edit`);
  };

  const cleanValueString = (value: string) => {
    const cleanedValue = value
      .split('')
      .filter(
        (o: any) =>
          o !== 'R' && o !== '$' && o !== ' ' && o !== '.' && o !== ',',
      )
      .join('');
    return cleanedValue;
  };

  const parentColumns = [
    {
      width: 50,
      render: (row: ProductProps) => {
        const ordenedImages = row.images.sort(
          (a: ProductImageProps, b: ProductImageProps) =>
            a.position - b.position,
        );

        return (
          <figure className="table-picture">
            {row.images.length <= 0 && ordenedImages.length <= 0 ? (
              <div className="no-img">
                <FiImage />
              </div>
            ) : (
              <LazyLoadImage
                effect="blur"
                src={
                  process.env.REACT_APP_BUCKET_ASSETS_URL +
                  encodeURI(ordenedImages[0].image_url)
                }
                alt={row.name}
              />
            )}
          </figure>
        );
      },
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 450,
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      sorter: (a: any, b: any) => {
        const valueA = cleanValueString(a.value);
        const valueB = cleanValueString(b.value);
        return Number(valueA) - Number(valueB);
      },
      render: (value: any) => (
        <>
          {value !== '$0.00'
            ? parseFloat(
                value.replace(/\$/g, '').replace(/,/g, ''),
              ).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })
            : 'Preço sob consulta'}
        </>
      ),
      showSorterTooltip: false,
      width: 200,
    },
    {
      title: 'Categoria',
      render: (row: ProductProps) => (
        <div>
          {row.category?.description ? row.category?.description : row.category}
        </div>
      ),
    },
    {
      title: 'Editar',
      render: (row: ProductProps) => (
        <button
          className="tableButton"
          type="submit"
          onClick={() => captureProductRow(row)}
        >
          <FiEdit />
        </button>
      ),
      width: 100,
    },
    {
      title: 'Habilitar',
      render: (product: ProductProps) => (
        <div className="switch-wrapper">
          <Switch
            checked={product.enabled}
            onChange={() => handleSwitch(product, !product.enabled)}
          />
        </div>
      ),
      width: 100,
    },
    {
      title: 'Remover',
      render: (row: ProductProps) => (
        <Popconfirm
          title="Tem certeza que deseja excluir esse produto?"
          onConfirm={() => handleDelete(row.id, row.name)}
          okText="Sim"
          cancelText="Não"
        >
          <button className="tableButton remove" type="submit">
            <FiTrash2 />
          </button>
        </Popconfirm>
      ),
      width: 100,
    },
  ];

  const emptyTable = (
    <Empty
      image={emptyProducts}
      description="Você não possui produtos cadastrados."
    >
      <button
        className="primary-button store"
        type="button"
        onClick={() => history.push('/store/products/register')}
        style={{ backgroundColor: 'FFB822' }}
      >
        Cadastrar produto
      </button>
    </Empty>
  );

  const tableData = productStore.products?.filter((product: ProductProps) => {
    return (
      (product.name
        .toLowerCase()
        .includes(productStore.filterProductName.toLowerCase()) ||
        product.code
          .toLowerCase()
          .includes(productStore.filterProductName.toLowerCase())) &&
      product.category?.description
        .toLowerCase()
        .includes(productStore.filterCategoryName.toLowerCase())
    );
  });

  console.log('Table', tableData);

  const FilterByNameInput = (
    <Input
      placeholder="Pesquisar produto"
      value={productStore.filterProductName}
      className="table-search"
      suffix={<FiSearch />}
      onChange={(e) => productStore.setFilterProductName(e.target.value)}
    />
  );
  const FilterByCategoryInput = (
    <Input
      placeholder="Pesquisar categoria"
      value={productStore.filterCategoryName}
      className="table-search"
      suffix={<FiSearch />}
      onChange={(e) => productStore.setFilterCategoryName(e.target.value)}
    />
  );

  const renderTable = () => {
    return (
      <Table
        columns={parentColumns}
        dataSource={tableData}
        className="suiteshare-table"
        locale={{ emptyText: emptyTable }}
      />
    );
  };

  const renderCards = () => {
    return tableData?.map((product: ProductProps) => {
      return (
        <Card
          key={product.id}
          className="suiteshare-card mobile"
          style={{ width: '100%', maxWidth: 400, marginTop: 16 }}
          actions={[
            <button
              className="tableButton"
              type="submit"
              onClick={() => captureProductRow(product)}
            >
              <FiEdit />
            </button>,
            <Popconfirm
              title="Tem certeza que deseja excluir esse produto?"
              onConfirm={() => handleDelete(product.id, product.name)}
              okText="Sim"
              cancelText="Não"
            >
              <button className="tableButton remove" type="submit">
                <FiTrash2 />
              </button>
            </Popconfirm>,
          ]}
        >
          <Skeleton loading={isTableLoading} avatar active>
            <Meta
              avatar={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Avatar
                  shape="square"
                  src={
                    process.env.REACT_APP_BUCKET_ASSETS_URL +
                    encodeURI(product.images[0]?.image_url)
                  }
                />
              }
              title={product.name}
              description={product.value}
            />
          </Skeleton>
        </Card>
      );
    });
  };

  return (
    <Content>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '0 0 2rem',
        }}
      >
        <CatalogSelect />
      </div>
      <ActionBar className="action-bar">
        <div className="text-wrapper">
          <h1>
            Produtos |
            <span style={{ fontWeight: 400, fontSize: '1rem', marginLeft: 5 }}>
              {productStore.products?.length}
            </span>
          </h1>
        </div>
        <AddProduct
          onClick={goToProductRegister}
          title="Cadastrar novo produto"
        >
          Cadastrar produto
        </AddProduct>
      </ActionBar>
      <div className="product-filters">
        <div className="product-filters__wrapper">{FilterByNameInput}</div>
        <div className="product-filters__wrapper">{FilterByCategoryInput}</div>
      </div>
      <StyledForm>
        {isTableLoading ? <Skeleton active /> : renderTable()}
        {productStore.products?.length <= 0 ? (
          <div className="empty-table-mobile">{emptyTable}</div>
        ) : (
          renderCards()
        )}
      </StyledForm>
    </Content>
  );
});

export default ParentProductList;
