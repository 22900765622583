import { message } from 'antd';
import api from '../api';
import { CompanyProps } from '../../Types';

export async function postStoreData(companyId: string, storeObj: object) {
  const storeData = await api
    .post(`/company/${companyId}/stores`, storeObj)
    .then((res) => res.data)
    .catch((err) => message.error(err.message));
  return storeData;
}

export const getStoreData = async (company: CompanyProps) => {
  const response = await api
    .get(`/company/${company.id}/stores`)
    .then((res) => res.data)
    .catch((err) => message.error(err.message));

  return response;
};

export async function postStoreImage(
  companyId: string,
  storeId: string,
  image: object,
) {
  const storeImage = await api
    .post(`/company/${companyId}/stores/${storeId}/upload`, image)
    .catch((err) => message.error(err.message));

  return storeImage;
}

export async function putStoreData(companyId: string, storeObj: object) {
  const storeData = await api
    .put(`/company/${companyId}/stores`, storeObj)
    .then((res) => res.data)
    .catch((err) => message.error(err.message));
  return storeData;
}

export async function deleteStore(companyId: string, storeId: string) {
  const deleteStore = await api
    .delete(`/company/${companyId}/stores/${storeId}`)
    .catch((err) => message.error(err.message));
  return deleteStore;
}
