import styled from 'styled-components';
import { Container } from '../../../styles/default';
import { appearFromLeft } from '../../../styles/animations';

export const LoginContainer = styled(Container)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  place-content: center;
  background: #fbfbfb;
  box-shadow: -5px 0px 5px #f4f4f4;
  @media (min-width: 1024px) {
    width: 40%;
    min-width: 400px;
  }
  form {
    width: 100%;
    padding: 0.5rem 1.5rem 1rem;
    @media (min-width: 1024px) {
      padding: 0;
    }
    h1 {
      font-size: 1rem;
      margin: 0 0 3rem;
      font-weight: 400;
    }
    .user-login {
      margin: 0 0 2.5rem;
    }
    .check-and-forgot {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-row.ant-form-item {
        margin: 0;
      }
      a {
        margin: 0;
        font-size: 0.75rem;
        text-decoration: underline;
        &:hover {
          color: #232323;
        }
      }
    }
    .ant-form-item-explain {
      text-align: left;
      position: absolute;
      bottom: -2rem;
    }
    .ant-input-affix-wrapper {
      background: transparent;
      border: none;
      border-bottom: 1px solid #232323;
      transition: border 0.3s ease;
      .ant-input-prefix {
        margin: 0 1rem 0 0;
        color: #232323;
        transition: color 0.3s ease;
      }
      input {
        color: #232323;
        background: transparent;
        &::placeholder {
          color: #cfcfcf;
          opacity: 1;
          transition: color 0.3s ease;
        }
      }
      &:focus,
      &.ant-input-affix-wrapper-focused {
        border: none;
        border-bottom: 1px solid #ffb900;
        box-shadow: none;
        .ant-input-prefix {
          color: #ffb900;
        }
        input {
          &::placeholder {
            color: #cfcfcf;
          }
        }
      }
    }
  }
  .bellow-link {
    padding: 0rem 1rem;
    flex-flow: row wrap;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 1024px) {
      margin: 2rem auto 0;
      padding: 0;
    }
    p,
    a {
      font-size: 0.9rem;
      color: #232323;
    }
    p {
      margin: 0 0.3rem 0 0;
    }
    a {
      text-decoration: underline;
    }
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  place-content: center;
  display: grid;
  grid-template-rows: 0.5fr 3.3fr 0.5fr;
  animation: ${appearFromLeft} 1s;
  @media (min-width: 1024px) {
    grid-template-rows: 1fr 3.3fr 0.5fr;
  }
  img {
    width: 90px;
    margin: 0 auto;
    @media (min-width: 1024px) {
      width: 150px;
    }
  }
  form {
    text-align: center;
    a {
      color: #232323;
      display: block;
      text-decoration: none;
      transition: all 0.2s;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const Button = styled.button`
  position: relative;
  padding: 0.7rem 0;
  background: linear-gradient(29deg, #ffb900 0%, #ffb900 70%);
  border-radius: 5px;
  border: 0;
  color: #fff;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  box-shadow: 4px 4px 5px #caa14b;
  z-index: 1;
  transition: box-shadow 0.3s ease;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    background: linear-gradient(29deg, rgb(255 149 42) 0%, #ffb900 70%);
    z-index: -1;
    transition: all 0.3s ease;
    opacity: 0;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
  }
  &:active {
    box-shadow: -1px -1px 5px #caa14b;
  }

  &.connect {
    background: linear-gradient(29deg, #fd397a 0%, #fd397a 70%);
    box-shadow: 2px 2px 4px #fd397aa8;
    &::before {
      background: linear-gradient(29deg, rgb(193 42 255) 0%, #fd397a 70%);
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
    &:active {
      box-shadow: -1px -1px 5px #fd397aa8;
    }
  }
`;
