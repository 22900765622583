import { observable } from 'mobx';
import { createContext } from 'react';
import _ from 'lodash';

import { ProductProps } from '../Types';
class ProductsStore {
  @observable products: any;

  @observable filterProductName = '';

  @observable filterCategoryName = '';

  setProductsList = (productList: ProductProps[]) => {
    this.products = productList;
  };

  deleteProductFromList = (productId: string) => {
    const removeProduct = _.remove(this.products, function (e: { id: string }) {
      return e.id !== productId;
    });
    this.products = removeProduct;
  };

  editProductFromList = (product: ProductProps) => {
    const editProduct = _.map(this.products, function (e: { id: string }) {
      return e.id === product.id ? product : e;
    });

    this.products = editProduct;
  };

  setFilterProductName = (name: any) => {
    this.filterProductName = name;
  };

  setFilterCategoryName = (category: any) => {
    this.filterCategoryName = category;
  };
}

export const ProductsStoreContext = createContext(new ProductsStore());
