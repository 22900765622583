import React, { useState } from 'react';

// Styles
import { CircleButtonWrapper, CircleButton } from './styles';

interface IProps {
  background: string[];
  changeBackground: (colorArray: string[]) => void | undefined;
}

const Color: React.FC<IProps> = (props) => {
  const { background, changeBackground } = props;

  const [isActive] = useState(false);

  return (
    <CircleButtonWrapper>
      <CircleButton
        type="button"
        background={background}
        isActive={isActive}
        onClick={() => {
          changeBackground(background);
        }}
      />
    </CircleButtonWrapper>
  );
};

export default Color;
