import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('@Suiteshare:token');
      localStorage.removeItem('@Suiteshare:user');

      window.location.href = `/store/login`;
      return Promise.reject(error);
    }
    if (error.response.status === 402) {
      localStorage.removeItem('@Suiteshare:token');
      localStorage.removeItem('@Suiteshare:user');

      window.location.href = `/trial-end`;
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export default api;
