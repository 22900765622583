import { observable } from 'mobx';
import { createContext } from 'react';
import _ from 'lodash';
import { CategoryProps } from '../Types';

class CategoriesStore {
  @observable categories: any;

  setCategories = (categoriesList: CategoryProps[]) => {
    this.categories = categoriesList;
  };

  deleteCategoryFromList = (categoryId: string) => {
    const removeCategory = _.remove(this.categories, function (e: {
      id: string;
    }) {
      return e.id !== categoryId;
    });
    this.categories = removeCategory;
  };

  editCategoryFromList = (category: CategoryProps) => {
    const editCategory = _.map(this.categories, function (e: { id: string }) {
      return e.id === category.id ? category : e;
    });

    this.categories = editCategory;
  };
}

export const CategoriesStoreContext = createContext(new CategoriesStore());
