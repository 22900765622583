import React, { FC, useState } from 'react';
import { FaCog, FaPlus } from 'react-icons/fa';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Switch } from 'antd';

import { IStoreProps } from '../../../../../../Types';

interface IProps {
  storeData: IStoreProps;
  index: number;
  switchEnabled: (value: boolean, index: number) => void;
  deleteCatalog: (storeId: string) => void;
  editCatalog: (storeId: string) => void;
}

const CardContainer: FC<IProps> = (props) => {
  const { storeData, index, switchEnabled, deleteCatalog, editCatalog } = props;

  const [activeMask, setActiveMask] = useState(false);

  return (
    <li className="cards-container__card-wrapper column center">
      <div
        className="cards-container__background-img"
        style={{
          backgroundImage: storeData.cover_image
            ? `url(${
                process.env.REACT_APP_BUCKET_ASSETS_URL +
                encodeURI(storeData.cover_image)
              })`
            : 'repeating-linear-gradient(45deg, #6c757d, #6c757d 100px)',
        }}
      />
      <div
        className={`cards-container__hover-mask ${
          activeMask ? 'cards-container__hover-mask--active' : ''
        }`}
      >
        <button
          className="cards-container__top-button"
          type="button"
          onClick={() => setActiveMask(true)}
        >
          <span>
            <FaCog />
          </span>
        </button>
      </div>
      <div
        className={`cards-container__active-mask column center ${
          activeMask ? 'cards-container__active-mask--active' : ''
        }`}
      >
        <button
          className="cards-container__top-button"
          type="button"
          onClick={() => setActiveMask(false)}
        >
          <span>
            <FaPlus />
          </span>
        </button>
        <div className="active-mask__button-wrapper wrapper-1-1 row center">
          <button
            className="config-button config-button--blue"
            type="button"
            onClick={() => editCatalog(storeData.id)}
          >
            <span>
              <FiEdit />
            </span>
          </button>
          <div className="switch-wrapper">
            <Switch
              checked={storeData.enabled}
              onChange={() => switchEnabled(!storeData.enabled, index)}
            />
          </div>
          <button
            className="config-button config-button--red"
            type="button"
            onClick={() => deleteCatalog(storeData.id)}
          >
            <span>
              <FiTrash2 />
            </span>
          </button>
        </div>
      </div>
      <div className="cards-container__cat-name">
        <h3 className={`${activeMask ? 'active' : ''}`}>{storeData.title}</h3>
      </div>
    </li>
  );
};

export default CardContainer;
