import React, { useContext } from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import { BsBuilding } from 'react-icons/bs';
import { BiStore } from 'react-icons/bi';
import { FiLock } from 'react-icons/fi';

import { ConfigurationContainer } from './styles';

import UserInfo from './Views/UserInfo';
import CompanyInfo from './Views/CompanyInfo';
import BranchList from './Views/BrachList';
import SecurityInfo from './Views/SecurityInfo';

import '../../../scss/_grid.scss';
import { GeneralStoreContext } from '../../../stores/GeneralStore';

const Configuration: React.FC = observer(() => {
  const generalStore = useContext(GeneralStoreContext);

  return (
    <ConfigurationContainer>
      <div id="menu-column" className="wrapper-1-3 column start">
        <div className="title">
          <div className="title__content">
            <h1>Bem-vindo(a) à sua conta da Suiteshare!</h1>
            <p>
              Essas informações serão as mesmas para todos os produtos da
              Suiteshare deste painel.
            </p>
          </div>
        </div>
        {/* <NavLink
          className="config-menu-link"
          to="/suiteshare/account/my-account"
          activeClassName="active"
        >
          <span>
            <BsPerson />
            Minha conta
          </span>
        </NavLink> */}
        <NavLink
          className="config-menu-link"
          to="/suiteshare/account/company-info"
        >
          <span>
            <BsBuilding />
            Informações da empresa
          </span>
        </NavLink>
        <NavLink
          className="config-menu-link"
          to="/suiteshare/account/branch-list"
          style={{ display: generalStore.isChild ? 'none' : 'flex' }}
        >
          <span>
            <BiStore />
            Unidades e Filiais
          </span>
        </NavLink>
        <NavLink className="config-menu-link" to="/suiteshare/account/security">
          <span>
            <FiLock />
            Segurança
          </span>
        </NavLink>
      </div>

      <div id="view-column" className="row center">
        <Switch>
          <Route path="/:app/account/my-account" component={UserInfo} />
          <Route path="/:app/account/company-info" component={CompanyInfo} />
          <Route path="/:app/account/branch-list" component={BranchList} />
          <Route path="/:app/account/security" component={SecurityInfo} />
        </Switch>
      </div>
    </ConfigurationContainer>
  );
});

export default Configuration;
