import api from '../api';
import { message } from 'antd';

export async function getOrders(companyId: string) {
  const ordersData = await api
    .get(`/company/${companyId}/orders`)
    .then((res) => res.data)
    .catch((err) => {
      message.error(err.message);
    });

  return ordersData;
}
