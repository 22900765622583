import React, { FC, useContext, useEffect, useState } from 'react';
import filesize from 'filesize';
import { FaPlus } from 'react-icons/fa';
import { BiImageAdd } from 'react-icons/bi';
import { LoadingOutlined } from '@ant-design/icons';
import { message } from 'antd';

import { FileProps, IStoreProps } from '../../../Types';

import { useAuth } from '../../../hooks/auth';

import StoreServices from '../../../services/store/stores';

import './styles.scss';
import { base64ToFile, resizeFile } from '../../../utils/resizeFile';
import { GeneralStoreContext } from '../../../stores/GeneralStore';

interface IProps {
  closeModal: () => void;
  isModalOpen: boolean;
  id?: string;
}

const CatalogRegister: FC<IProps> = (props) => {
  const { closeModal, isModalOpen, id } = props;

  const generalStore = useContext(GeneralStoreContext);

  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const [description, setDescription] = useState('');
  const [uploadedFile, setUploadedFile] = useState<FileProps>();
  const [currentImage, setCurrentImage] = useState('');

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      const filteredCatalog: IStoreProps[] = generalStore.catalogsList.filter(
        (catalog: IStoreProps) => catalog.id === id,
      );

      setNameInput(filteredCatalog[0].title);
      setCurrentImage(filteredCatalog[0].cover_image);
      setDescription(filteredCatalog[0].description);

      setIsLoading(false);
    }
  }, [id, generalStore]);

  const submitFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target;
    if (files) {
      if (files.length > 0) {
        const fileMapped: FileProps = {
          file: files[0],
          name: files[0].name,
          position: 0,
          path: URL.createObjectURL(files[0]),
          readableSize: filesize(files[0].size),
        };
        setUploadedFile(fileMapped);

        if (currentImage) {
          setCurrentImage('');
        }
      }
    }
  };

  const postCoverImage = async (storeId: string) => {
    if (uploadedFile) {
      const img = await resizeFile(uploadedFile.file);
      const nFile = await base64ToFile(img, uploadedFile.name);
      const image = new FormData();

      image.append('file', nFile, nFile.name);

      await StoreServices.postStoreCover(user.company_id, storeId, image);
    }
  };

  const handleSubmit = () => {
    if (nameInput) {
      setIsLoading(true);

      if (id) {
        const currentStore: IStoreProps[] = generalStore.catalogsList.filter(
          (catalog: IStoreProps) => catalog.id === id,
        );

        currentStore[0].title = nameInput;
        currentStore[0].description = description;

        StoreServices.editStore(user.company_id, currentStore[0])
          .then(async () => {
            await postCoverImage(id);
          })
          .finally(() => {
            setIsLoading(false);
            closeModal();
            setUploadedFile(undefined);
          });
      } else {
        StoreServices.postStore(user.company_id, {
          company_id: user.company_id,
          redirect_url: '',
          primary_color: '',
          secondary_color: '',
          company_logo: '',
          suiteshare_logo: '',
          redirect_phone: '',
          enabled: true,
          title: nameInput,
          description,
          cover_image: '',
          position: 0,
        })
          .then(async (response) => {
            await postCoverImage(response.id);
          })
          .finally(() => {
            setIsLoading(false);
            closeModal();
            setUploadedFile(undefined);
          });
      }
    } else {
      message.warning('Insira pelo menos um nome para o novo catálogo');
    }
  };

  return (
    <div className={`catalog-register ${isModalOpen ? 'active' : ''}`}>
      <button
        className="catalog-register__mask"
        type="button"
        onClick={() => closeModal()}
      >
        x
      </button>
      <div className="catalog-register__wrapper wrapper-1-1 column center xa-start">
        <div className="catalog-register__close-btn">
          <button type="button" onClick={() => closeModal()}>
            <FaPlus />
          </button>
        </div>
        <div className="catalog-register__title wrapper-1-1 row center xa-start">
          <FaPlus />
          <h1>Novo Catálogo</h1>
        </div>
        <label htmlFor="catalog-name" className="catalog-register__name-input">
          <input
            type="text"
            id="catalog-name"
            value={nameInput}
            placeholder="Nome do catálogo"
            onChange={({ target: { value } }) => setNameInput(value)}
          />
        </label>
        <label
          htmlFor="catalog-description"
          className="catalog-register__name-input"
          style={{ margin: '0.5rem 0 1rem' }}
        >
          <input
            type="text"
            id="catalog-description"
            value={description}
            placeholder="Descrição do catálogo"
            onChange={({ target: { value } }) => setDescription(value)}
          />
        </label>
        <div className="catalog-register__img-upload">
          {uploadedFile || currentImage ? (
            <div className="catalog-register__curr-img">
              <img
                src={
                  uploadedFile
                    ? uploadedFile.path
                    : process.env.REACT_APP_BUCKET_ASSETS_URL +
                      encodeURI(currentImage)
                }
                alt={uploadedFile ? uploadedFile.name : currentImage}
              />
            </div>
          ) : (
            <div />
          )}
          {uploadedFile?.path || currentImage ? (
            <label className="change-image" htmlFor="catalog-img">
              <BiImageAdd size={20} />
              Trocar imagem
              <input
                type="file"
                name=""
                id="catalog-img"
                accept=".jpg, .png, .jpeg"
                onChange={(e) => submitFile(e)}
              />
            </label>
          ) : (
            <label className="add-image" htmlFor="catalog-img">
              <BiImageAdd size={50} />
              <input
                type="file"
                name=""
                id="catalog-img"
                accept=".jpg, .png, .jpeg"
                onChange={(e) => submitFile(e)}
              />
            </label>
          )}
          <p></p>
        </div>
        <div className="catalog-register__submit">
          {id ? (
            <button
              type="button"
              disabled={isLoading}
              onClick={() => handleSubmit()}
            >
              {isLoading ? <LoadingOutlined /> : 'Editar catálogo'}
            </button>
          ) : (
            <button
              type="button"
              disabled={isLoading}
              onClick={() => handleSubmit()}
            >
              {isLoading ? <LoadingOutlined /> : 'Cadastrar catálogo'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CatalogRegister;
