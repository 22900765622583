import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FiTrash2, FiEdit, FiFolderPlus } from 'react-icons/fi';
import { message, Popconfirm, Table, Skeleton, Card, Switch } from 'antd';

import { CategoryProps, FileProps } from '../../../../../Types';

import CategoriesServices from '../../../../../services/store/Categories';
import StoreServices from '../../../../../services/store/stores';

import { base64ToFile, resizeFile } from '../../../../../utils/resizeFile';

import { CategoriesStoreContext } from '../../../../../stores/CategoriesStore';
import { GeneralStoreContext } from '../../../../../stores/GeneralStore';

import { useAuth } from '../../../../../hooks/auth';

import EditCategModal from '../../../../../components/EditCategModal';
import CatalogSelect from '../../../../../components/_Store/CatalogSelect';
import CategoryForm from '../../../../../components/_Store/CategoryForm';
import Empty from '../../../../../components/Empty';

import { AddCategory, Content, StyledForm, ActionBar } from './styles';
import { CategoryRegistrationModal } from '../ProductRegistration/styles';
import { ReactComponent as Close } from '../../../../../assets/close-btn.svg';

const { Meta } = Card;

const CategoriesList: React.FC = observer(() => {
  const categoriesStore = useContext(CategoriesStoreContext);
  const generalStore = useContext(GeneralStoreContext);
  const history = useHistory();

  const [isSettingData, setIsSettingData] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [categoryModalState, setCategoryModalState] = useState(false);
  const [modalCategory, setModalCategory] = useState<
    CategoryProps | undefined
  >();

  const { user } = useAuth();

  const storeId = generalStore.currentCatalogId;

  useEffect(() => {
    if (user.company.parent_company_id) {
      history.push('/store/products');
      message.warning('Você não tem permissão para acessar esta página');
    }
    if (storeId) {
      StoreServices.getStoreCategories(user.company_id, storeId)
        .then((categories) => categoriesStore.setCategories(categories))
        .finally(() => {
          setIsTableLoading(false);
        });
    } else {
      CategoriesServices.getCategories(user.company_id, storeId)
        .then((categories) => {
          categoriesStore.setCategories(categories);
        })
        .finally(() => {
          setIsTableLoading(false);
        });
    }
  }, [categoriesStore, user.company_id, storeId, history, user]);

  const getCategoriesHelper = (store_id?: string | null) => {
    if (store_id) {
      StoreServices.getStoreCategories(user.company_id, store_id)
        .then((categories) => categoriesStore.setCategories(categories))
        .finally(() => {
          setIsTableLoading(false);
        });
    } else {
      CategoriesServices.getCategories(user.company_id, storeId)
        .then((categories) => {
          categoriesStore.setCategories(categories);
        })
        .finally(() => {
          setIsTableLoading(false);
        });
    }
  };

  const handleDelete = (categoryId: string) => {
    setIsTableLoading(true);
    CategoriesServices.deleteCategory(user.company_id, storeId, categoryId)
      .then((response) => {
        if (response) {
          getCategoriesHelper(storeId);
          message.success(`Categoria excluida com sucesso`);
        }
      })
      .finally(() => setIsTableLoading(false));
  };

  const closeModal = (value: boolean) => {
    setModalIsOpen(value);
  };

  const handleEditCategory = (category: CategoryProps, files: FileProps[]) => {
    setIsSettingData(true);
    CategoriesServices.putCategory(user.company_id, storeId, category)
      .then(() => {
        files.map(async (file) => {
          const img = await resizeFile(file.file);
          const nFile = await base64ToFile(img, file.name);
          const image = new FormData();
          image.append('file', nFile, nFile.name);

          CategoriesServices.postCategoryImage(
            user.company_id,
            storeId,
            category.id,
            image,
          );
        });
      })
      .finally(() => {
        getCategoriesHelper(storeId);
        message.success('Categoria editada com sucesso');
        setModalIsOpen(false);
        setIsSettingData(false);
      });
  };

  const handleSwitch = (categoryId: string, enabled: boolean) => {
    if (categoriesStore.categories) {
      const currCategory = categoriesStore.categories.filter(
        (category: CategoryProps) => category.id === categoryId,
      );

      currCategory[0].enabled = enabled;

      setIsTableLoading(true);

      CategoriesServices.putCategory(
        user.company_id,
        storeId,
        currCategory[0],
      ).then(() => getCategoriesHelper(storeId));
    }
  };

  const captureProductRow = (category: CategoryProps) => {
    setModalCategory(category);
    setModalIsOpen(true);
  };

  const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      showSorterTooltip: false,
      width: 400,
    },
    {
      title: 'Editar',
      render: (row: CategoryProps) => (
        <button
          className="tableButton"
          type="submit"
          onClick={() => captureProductRow(row)}
        >
          <FiEdit />
        </button>
      ),
      width: 100,
    },
    {
      title: 'Habilitar',
      render: (category: CategoryProps) => (
        <div className="switch-wrapper">
          <Switch
            checked={category.enabled}
            onChange={() => handleSwitch(category.id, !category.enabled)}
          />
        </div>
      ),
      width: 100,
    },
    {
      title: 'Remover',
      render: (row: CategoryProps) => (
        <Popconfirm
          title="Deseja excluir? Todos os produtos atribuidos a essa categoria também serão excluídos."
          onConfirm={() => handleDelete(row.id)}
          okText="Sim"
          cancelText="Não"
        >
          <button className="tableButton remove" type="submit">
            <FiTrash2 />
          </button>
        </Popconfirm>
      ),
      width: 100,
    },
  ];

  const closeCategoryRegModal = () => {
    setCategoryModalState(false);
    getCategoriesHelper(storeId);
  };

  const openCategoryRegModal = () => {
    setCategoryModalState(true);
  };

  return (
    <Content>
      <CategoryRegistrationModal className={categoryModalState ? 'active' : ''}>
        <div className="mask" />
        <div className="categ-modal-body">
          <div className="categ-modal-header ">
            <h1>
              <FiFolderPlus /> Cadastrar Categoria
            </h1>
            <button
              className="close-btn"
              type="button"
              onClick={() => setCategoryModalState(false)}
            >
              <Close />
            </button>
          </div>
          <CategoryForm redirect={closeCategoryRegModal} />
        </div>
      </CategoryRegistrationModal>
      {modalIsOpen && modalCategory ? (
        <EditCategModal
          isOpen={modalIsOpen}
          categoryData={modalCategory}
          closeModal={closeModal}
          editCategory={handleEditCategory}
          loading={isSettingData}
        />
      ) : (
        <div />
      )}

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '0 0 2rem',
        }}
      >
        <CatalogSelect />
      </div>

      <ActionBar className="action-bar">
        <div className="text-wrapper">
          <h1>Categorias</h1>
        </div>
        <AddCategory
          onClick={() => setCategoryModalState(true)}
          title="Cadastrar nova categoria"
        >
          Cadastrar categoria
        </AddCategory>
      </ActionBar>
      <StyledForm>
        {isTableLoading ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            dataSource={categoriesStore.categories}
            className="suiteshare-table"
            locale={{ emptyText: <Empty callBack={openCategoryRegModal} /> }}
          />
        )}

        {categoriesStore.categories?.length <= 0 ? (
          <div className="empty-table-mobile">
            <Empty callBack={openCategoryRegModal} />
          </div>
        ) : (
          categoriesStore.categories?.map((category: CategoryProps) => {
            return (
              <Card
                key={category.id}
                className="suiteshare-card mobile category"
                cover={
                  <img
                    alt={`categoria ${category.description}`}
                    src={
                      category.image_url
                        ? process.env.REACT_APP_BUCKET_ASSETS_URL +
                          category.image_url
                        : ''
                    }
                  />
                }
                actions={[
                  <button
                    className="tableButton"
                    type="submit"
                    onClick={() => captureProductRow(category)}
                  >
                    <FiEdit />
                  </button>,
                  <Popconfirm
                    title="Tem certeza que deseja excluir esse produto?"
                    onConfirm={() => handleDelete(category.id)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <button className="tableButton remove" type="submit">
                      <FiTrash2 />
                    </button>
                  </Popconfirm>,
                ]}
              >
                <Skeleton loading={isTableLoading} active>
                  <Meta title={category.description} />
                </Skeleton>
              </Card>
            );
          })
        )}
      </StyledForm>
    </Content>
  );
});

export default CategoriesList;
