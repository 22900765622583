import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { FiEye } from 'react-icons/fi';
import { UserOutlined } from '@ant-design/icons';
import { useAuth } from '../../../hooks/auth';

import CatalogButton from '../../CatalogButton';
import SuiteAppsContainer from '../../SuiteAppsContainer';

import { Icon, Icons } from '../styles';

import storeLogo from '../../../assets/logos/products/store.png';
import storeLogoWhite from '../../../assets/logos/products/store-white.svg';

import connectLogo from '../../../assets/logos/products/connect.png';
import connectWhite from '../../../assets/logos/products/connect-white.png';

import SuiteshareLogo from '../../_Logos/SuiteshareLogo';

interface IProps {
  app: string;
}

const HeaderTop: FC<IProps> = ({ app }) => {
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  const renderLogo = () => {
    switch (app) {
      case 'store':
        return (
          <div
            className="row xa-start center"
            style={{ width: 'auto', gap: '1rem' }}
          >
            <img
              className="app-logo"
              src={storeLogo}
              alt={app}
              // style={{
              //   borderRight: '1px solid #e5e5e5',
              //   padding: '0 1rem 0 0',
              // }}
            />
            {/* <CatalogSelect /> */}
          </div>
        );
      case 'connect':
        return <img className="app-logo" src={connectLogo} alt={app} />;

      case 'suiteshare':
        return <SuiteshareLogo color="#232323" size="1.6rem" />;

      default:
        return <SuiteshareLogo color="#232323" size="1.6rem" />;
    }
  };

  const renderLogoMobile = () => {
    switch (app) {
      case 'store':
        return (
          <>
            <img className="app-logo" src={storeLogoWhite} alt={app} />;
            {/* <CatalogButton /> */}
          </>
        );
      case 'connect':
        return (
          <>
            <img className="app-logo" src={connectWhite} alt={app} />
            <button
              id="connect-button"
              type="button"
              onClick={() => {
                window.open(
                  `https://suitesha.re/${user.company.social_url}`,
                  '_blank',
                );
              }}
            >
              <FiEye />
              Ver Connect
            </button>
          </>
        );

      case 'suiteshare':
        return <SuiteshareLogo color="#fff" size="1.6rem" />;

      default:
        return <SuiteshareLogo color="#fff" size="1.6rem" />;
    }
  };

  const renderTopNav = () => {
    switch (app) {
      case 'store':
        return <CatalogButton />;
      case 'connect':
        return (
          <button
            id="connect-button"
            type="button"
            onClick={() => {
              window.open(
                `https://suitesha.re/${user.company.social_url}`,
                '_blank',
              );
            }}
          >
            <FiEye />
            Ver Connect
          </button>
        );

      case 'suiteshare':
        return <div />;

      default:
        return <div />;
    }
  };

  return (
    <>
      <div className="title only-desk">
        {renderLogo()}
        <Icons>
          {renderTopNav()}
          <SuiteAppsContainer />
          <Icon>
            <Link to="/suiteshare/account/company-info">
              <UserOutlined style={{ height: '100%' }} />
              <p
                style={{
                  height: '100%',
                  fontSize: '1rem',
                }}
              >
                Minha Conta
              </p>
            </Link>
          </Icon>
          <Icon>
            <button type="button" onClick={() => handleLogout()}>
              <AiOutlinePoweroff size={13} />
              <p>Sair</p>
            </button>
          </Icon>
        </Icons>
      </div>

      <div className="mobile-logo-header">{renderLogoMobile()}</div>
    </>
  );
};

export default HeaderTop;
