import React, { FC } from 'react';
import Cropper from 'react-cropper';
import '../../../node_modules/cropperjs/dist/cropper.css';
import './styles.scss';

interface IProps {
  imgSrc: File;
  aspectRatio: number;
  handleSetCropper: (value: any) => void;
  getCropData: () => void;
}

export const Demo: FC<IProps> = (props) => {
  const { imgSrc, aspectRatio, handleSetCropper, getCropData } = props;

  return (
    <div className="cropper-body">
      <div style={{ width: '100%' }}>
        <Cropper
          style={{ height: 400, width: '100%' }}
          initialAspectRatio={aspectRatio}
          preview=".img-preview"
          src={URL.createObjectURL(imgSrc)}
          viewMode={1}
          guides
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            handleSetCropper(instance);
          }}
        />
      </div>
      <div className="button-wrapper">
        <button onClick={getCropData} type="button">
          Confirmar
        </button>
      </div>
    </div>
  );
};

export default Demo;
