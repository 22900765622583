import React, { Suspense, FC } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute, SessionRoute } from './helper';

import Login from '../modules/Sessions/Login';
import SignUp from '../modules/Sessions/SignUp';
import ResetPassword from '../modules/Sessions/ResetPassword';
import ForgotPassword from '../modules/Sessions/ForgotPassword';
import TrialEnd from '../modules/Sessions/TrialEnd';
import CatalogsList from '../modules/Panel/Store/Views/CatalogsList';
import CategoriesList from '../modules/Panel/Store/Views/CategoriesList';
import CategoryRegistration from '../modules/Panel/Store/Views/CategoryRegistration';
import OrdersList from '../modules/Panel/Store/Views/OrdersList';
import ProductRegistration from '../modules/Panel/Store/Views/ProductRegistration';
import ProductsList from '../modules/Panel/Store/Views/ProductsList';
import StoreConfigurations from '../modules/Panel/Store/Views/StoreConfigurations';
import ConnectMain from '../modules/Panel/Connect';
import Configuration from '../modules/Panel/Configuration';
import SpecificationsList from '../modules/Panel/Store/Views/SpecificationsList';
import SpecificationRegister from '../modules/Panel/Store/Views/SpecificationRegister';
import ProductInfo from '../modules/Panel/Store/Views/ProductInfo';
import AdminView from '../modules/Panel/Store/Views/ProductsList/AdminView';
import SellersList from '../modules/Panel/Store/Views/SellersList';

const Routes: FC = () => {
  return (
    <Router>
      <Suspense fallback="">
        <Switch>
          <SessionRoute path="/" component={Login} exact />
          <SessionRoute path="/login" component={Login} />
          <SessionRoute path="/signup" component={SignUp} />
          <SessionRoute path="/:app/login" component={Login} />
          <SessionRoute path="/:app/signup" component={SignUp} />
          <SessionRoute
            path="/:app/forgot-password"
            component={ForgotPassword}
          />
          <SessionRoute path="/:app/reset-password" component={ResetPassword} />

          <Route path="/trial-end" component={TrialEnd} />

          <PrivateRoute path="/:app/account" component={Configuration} />

          <PrivateRoute path="/:app/customize" component={ConnectMain} />
          <PrivateRoute path="/:app/links" component={ConnectMain} />
          <PrivateRoute path="/:app/share" component={ConnectMain} />

          <PrivateRoute path="/:app/catalogs" component={CatalogsList} exact />

          <PrivateRoute path="/:app/products" component={ProductsList} exact />

          <PrivateRoute
            path="/:app/products/admin-list"
            component={AdminView}
            exact
          />

          <PrivateRoute
            path="/:app/products/register"
            component={ProductRegistration}
          />
          <PrivateRoute
            path="/:app/products/:id/view"
            component={ProductInfo}
          />
          <PrivateRoute
            path="/:app/products/:id/edit"
            component={ProductRegistration}
          />
          <PrivateRoute
            path="/:app/products/:productId/edit"
            component={ProductsList}
          />
          <PrivateRoute path="/:app/categories" component={CategoriesList} />
          <PrivateRoute
            path="/:app/categories/register"
            component={CategoryRegistration}
          />
          <PrivateRoute
            path="/:app/categories/:categoryId/edit"
            component={CategoriesList}
          />
          <PrivateRoute
            path="/:app/specifications"
            component={SpecificationsList}
            exact
          />
          <PrivateRoute
            path="/:app/specifications/:specification_id/edit"
            component={SpecificationRegister}
            exact
          />
          <PrivateRoute
            path="/:app/specifications/register"
            component={SpecificationRegister}
          />
          <PrivateRoute path="/:app/sellers" component={SellersList} />

          <PrivateRoute path="/:app/orders" component={OrdersList} />
          <PrivateRoute path="/:app/config" component={StoreConfigurations} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
