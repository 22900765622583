import React, { FC, useState, useEffect } from 'react';
import { message } from 'antd';
import { GrClose } from 'react-icons/gr';
import { ThunderboltOutlined } from '@ant-design/icons';

import api from '../../../services/api';

import { useAuth } from '../../../hooks/auth';

import './styles.scss';

const TrialBanner: FC = () => {
  const { user } = useAuth();

  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [daysOfTrial, setDaysOfTrial] = useState<Number>();

  useEffect(() => {
    api
      .get('/now')
      .then((response) => {
        const currDate: Date = new Date(response.data);
        const createdDate: Date = new Date(
          user.company.created_at ? user.company.created_at : '',
        );

        const MS_PER_DAY = 1000 * 60 * 60 * 24;

        // Discard the time and time-zone information.
        const utc1 = Date.UTC(
          currDate.getFullYear(),
          currDate.getMonth(),
          currDate.getDate(),
        );
        const utc2 = Date.UTC(
          createdDate.getFullYear(),
          createdDate.getMonth(),
          createdDate.getDate(),
        );

        setDaysOfTrial(Math.floor((utc1 - utc2) / MS_PER_DAY));
      })
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });
  });

  return user.company.plan_id === null ? (
    <div />
  ) : (
    <div
      className="trial-banner wrapper-1-1 row center"
      style={{ display: isBannerVisible ? 'flex' : 'none' }}
    >
      <div className="trial-banner__close">
        <button type="button" onClick={() => setIsBannerVisible(false)}>
          <GrClose />
        </button>
      </div>
      <div className="trial-banner__content wrapper-1-1 center between">
        <div className="trial-banner__text-wrapper">
          <h2>Trial</h2>
        </div>
        <div className="trial-banner__countdown">
          <div className="countdown-bar">
            <div className="countdown-bar__background" />
            <div
              className="countdown-bar__front"
              style={{
                right: daysOfTrial
                  ? `-${(Number(daysOfTrial) / 7) * 100}%`
                  : '0%',
              }}
            />
          </div>
          <p>
            Faltam {7 - Number(daysOfTrial)} dias para acabar seu período de
            teste.
          </p>
        </div>
        <div className="trial-banner__call-to-action row center">
          <p>Contrate um plano e amplie as funcionalidades</p>
          <button
            type="button"
            onClick={() => window.open('https://whts.co/store-trial')}
          >
            <ThunderboltOutlined />
            Contratar plano
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrialBanner;
