import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { Select, message } from 'antd';
import MaskedInput from 'antd-mask-input';
import { LoadingOutlined } from '@ant-design/icons';
import { RiImageAddLine } from 'react-icons/ri';
import { FiEye } from 'react-icons/fi';

import filesize from 'filesize';

import { ConfigurationStoreContext } from '../../../../../stores/ConfigurationStore';
import { GeneralStoreContext } from '../../../../../stores/GeneralStore';

import StoreServices from '../../../../../services/store/stores';

import { useAuth } from '../../../../../hooks/auth';

import { resizeFile, base64ToFile } from '../../../../../utils/resizeFile';

import { FileProps, IStoreProps } from '../../../../../Types';

import {
  Content,
  Wrapper,
  Block,
  RedirectBodyWrapper,
  Title,
  Text,
  StoreRedirect,
  WhtscoInput,
  WhatsappInputWrapper,
  UploadWrapper,
  BtnWrapper,
  Button,
  GoToStore,
} from './styles';

const prefixOptions = ['55', '49'];

const StoreConfigurations: React.FC = observer(() => {
  const configStore = useContext(ConfigurationStoreContext);
  const generalStore = useContext(GeneralStoreContext);

  const [link, setLink] = useState('');
  const [numberPrefix, setNumberPrefix] = useState('55');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [hasSuiteshareLink, setHasSuiteshareLink] = useState(true);
  const [loading, setLoading] = useState(false);
  const [catalogList, setCatalogList] = useState<IStoreProps[]>([]);

  const { user } = useAuth();
  const { Option } = Select;

  useEffect(() => {
    StoreServices.getStores(user.company_id).then((stores) => {
      if (stores) {
        if (stores && stores.length > 0) {
          configStore.setStoreObject(stores[0]);
          setCatalogList(stores);
        } else {
          StoreServices.postStore(user.company_id, {
            company_id: user.company_id,
            enabled: true,
            redirect_phone: '',
            redirect_url: '',
            primary_color: '#fd397a',
            secondary_color: '#6f42eb',
            company_logo: '',
            suiteshare_logo: '',
            title: 'Catálogo padrão',
            description: '',
            cover_image: '',
            position: 0,
          }).then((res) => {
            configStore.setStoreObject(res);
            setCatalogList([res]);
          });
        }
      }
    });
  }, [user, configStore, generalStore]);

  useEffect(() => {
    if (configStore.store) {
      if (configStore.store.redirect_url) {
        const linkPathname = configStore.store.redirect_url
          ? configStore.store.redirect_url.split('/')
          : '';
        setLink(linkPathname[3]);
        setHasSuiteshareLink(true);
      }
      if (configStore.store.redirect_phone) {
        const cleanNumber = configStore.store.redirect_phone
          .split('/')[3]
          .replace(/^\d{2}/, '');
        setWhatsappNumber(cleanNumber);
        setHasSuiteshareLink(false);
      }
    }
  }, [configStore.store, user.company, user.company_id, configStore]);

  const handlePrefixChange = (selectedItem: string) => {
    setNumberPrefix(selectedItem);
  };

  const prefixSelector = (
    <Select
      style={{ width: 70 }}
      value={numberPrefix}
      onChange={(e) => handlePrefixChange(e)}
    >
      {prefixOptions.map((option) => {
        return (
          <Option key={option} value={option}>
            {option}
          </Option>
        );
      })}
    </Select>
  );

  const handleUploadChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0]) {
        setUploadedFile(e.target.files[0]);
        setLoading(true);
        if (configStore.store) {
          const updatedStore = configStore.store;
          updatedStore.company_logo = e.target.files[0].name;

          const fileMapped: FileProps = {
            file: e.target.files[0],
            name: e.target.files[0].name,
            position: 0,
            path: URL.createObjectURL(e.target.files[0]),
            readableSize: filesize(e.target.files[0].size),
          };
          const img = await resizeFile(fileMapped.file);
          const nFile = await base64ToFile(img, fileMapped.name);
          const image = new FormData();
          image.append('file', nFile, nFile.name);

          await Promise.all(
            catalogList.map((catalog: IStoreProps) => {
              return StoreServices.postStoreLogo(
                user.company_id,
                catalog.id,
                image,
              );
            }),
          )
            .then(() => {
              StoreServices.getStores(user.company_id).then((stores) => {
                configStore.setStoreObject(stores[0]);
                setCatalogList(stores);
              });
            })
            .finally(() => setLoading(false));
        }
      }
    }
  };

  const handleSubmit = async () => {
    if (configStore.store) {
      let updatedStore = configStore.store;
      const whatsLink = numberPrefix + whatsappNumber.replace(/[^0-9]/g, '');

      if (hasSuiteshareLink) {
        if (link === '') {
          message.error('Insira um link válido');
          return;
        }

        // Gets the last slash character (/) and sums 1 to get the start of the social URL
        const urlStartIndex = link.lastIndexOf('/') + 1;
        let url = '';

        if (urlStartIndex > 0) {
          // Get the string from the previous index to get the social URL
          url = link.substr(urlStartIndex);
          if (url.length < 1) {
            // If the string is empty that's because the user put a slash on the end of the string, so it's invalid.
            message.error('Insira um link da Suiteshare válido');
            return;
          }
        } else {
          // If there are no slashes in the string we can assign it directly to social URL
          url = link;
        }

        updatedStore = {
          ...configStore.store,
          redirect_url: `https://whts.co/${url}`,
          redirect_phone: null,
        };
      } else {
        if (whatsappNumber === '') {
          message.error('Insira um número válido');
          return;
        }

        updatedStore = {
          ...configStore.store,
          redirect_phone: `https://wa.me/${whatsLink}`,
          redirect_url: null,
        };
      }

      await Promise.all(
        catalogList.map((store: IStoreProps) => {
          const currentStore = store;
          currentStore.redirect_phone = updatedStore.redirect_phone;
          currentStore.redirect_url = updatedStore.redirect_url;

          return StoreServices.editStore(user.company_id, currentStore);
        }),
      )
        .then(() => {
          StoreServices.getStores(user.company_id).then((stores) => {
            configStore.setStoreObject(stores[0]);
            setCatalogList(stores);
          });
        })
        .finally(() => {
          setLoading(false);
          message.success('Método de contato atualizado com sucesso');
        });
    }
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWhatsappNumber(e.target.value);
  };

  const handleDemoRequest = async () => {
    const currStore: IStoreProps = configStore.store;

    if (currStore.demo_requests === null) currStore.demo_requests = 1;

    if (currStore.demo_requests) {
      currStore.demo_requests =
        Number(parseInt(currStore.demo_requests.toString(), 10)) + 1;
    }

    await Promise.all(
      catalogList.map((store: IStoreProps) => {
        const currentStore = store;
        currentStore.demo_requests = currStore.demo_requests;

        return StoreServices.editStore(user.company_id, currentStore);
      }),
    )
      .then(() => {
        StoreServices.getStores(user.company_id).then((stores) => {
          configStore.setStoreObject(stores[0]);
          setCatalogList(stores);
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Content>
      <div className="margin-mask" />
      <Block>
        <Wrapper>
          <Title>
            <span>1</span>Logotipo da empresa
          </Title>
          <Text>
            O logotipo da sua empresa aparecerá na página principal da Store
          </Text>
          <UploadWrapper>
            {uploadedFile ? (
              <img
                src={URL.createObjectURL(uploadedFile)}
                alt="Logotipo da empresa"
              />
            ) : (
              configStore.store?.company_logo && (
                <img
                  src={`${process.env.REACT_APP_BUCKET_ASSETS_URL}${encodeURI(
                    configStore.store.company_logo,
                  )}`}
                  alt=""
                />
              )
            )}
            <label htmlFor="logo-upload">
              {loading ? <LoadingOutlined /> : <RiImageAddLine size={20} />}
              {configStore.store?.company_logo || uploadedFile
                ? 'Trocar logotipo'
                : 'Carregar logotipo'}
              <input
                type="file"
                name=""
                id="logo-upload"
                accept=".jpg, .png"
                disabled={loading}
                onChange={(e) => handleUploadChange(e)}
              />
            </label>
          </UploadWrapper>
        </Wrapper>
      </Block>
      <Block>
        <Wrapper>
          <Title>
            <span>2</span>Redirecione o atendimento da sua Store
          </Title>
          <Text>
            O cliente será enviado para esse contato para tirar dúvidas e
            finalizar a compra.
          </Text>

          <StoreRedirect>
            <div className="nav-buttons">
              <button
                type="button"
                className={!hasSuiteshareLink ? 'active' : ''}
                onClick={() => setHasSuiteshareLink(false)}
              >
                1 Vendedor
              </button>
              <button
                type="button"
                className={`right ${hasSuiteshareLink ? 'active' : ''}`}
                onClick={() => setHasSuiteshareLink(true)}
              >
                2+ Vendedores
              </button>
            </div>
            <RedirectBodyWrapper
              style={{ display: !hasSuiteshareLink ? 'flex' : 'none' }}
            >
              <WhatsappInputWrapper>
                <div className="text-wrapper">
                  <h3>Possui apenas um vendedor?</h3>
                  <p className="subtitle">
                    Coloque o número do WhatsApp do vendedor:{' '}
                  </p>
                </div>
                <MaskedInput
                  addonBefore={prefixSelector}
                  mask="(11) 11111-1111"
                  style={{ width: '100%' }}
                  value={whatsappNumber}
                  placeholder="(xx) 99999-5555"
                  onChange={(e) => handleNumberChange(e)}
                />
              </WhatsappInputWrapper>
            </RedirectBodyWrapper>
            <RedirectBodyWrapper
              style={{ display: hasSuiteshareLink ? 'flex' : 'none' }}
            >
              <div className="text-wrapper">
                <h3>Possui mais de um vendedor?</h3>
                <p className="subtitle">
                  Utilize o seu link da Suiteshare abaixo:
                </p>
              </div>

              <WhtscoInput>
                <p>https://whts.co/</p>
                <input
                  type="text"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </WhtscoInput>
              <div className="text-wrapper link">
                <p>
                  <span>Não possui o seu link da Suiteshare?</span>{' '}
                  <a
                    href="https://whts.co/marcelo"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleDemoRequest()}
                  >
                    Solicite uma demonstração
                  </a>{' '}
                  com nossos especialistas para ampliar seu time de vendas no
                  WhatsApp
                </p>
              </div>
            </RedirectBodyWrapper>
            <BtnWrapper>
              <Button type="button" onClick={() => handleSubmit()}>
                Salvar
              </Button>
            </BtnWrapper>
          </StoreRedirect>
        </Wrapper>
      </Block>
      <Block>
        <Wrapper>
          <Title>
            <span>3</span>
            Ver Store
          </Title>
          <Text>
            Agora você pode visualizar todos os produtos adicionados em seu
            catálogo virtual.
          </Text>
          <GoToStore
            onClick={() => {
              window.open(
                `https://store.suitesha.re/${user.company.social_url}`,
                '_blank',
              );
            }}
          >
            <FiEye />
            Ver Catálogo
          </GoToStore>
        </Wrapper>
      </Block>
    </Content>
  );
});

export default StoreConfigurations;
