import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { FiEye } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';

import './styles.scss';

const CatalogButton: FC = observer(() => {
  const { user } = useAuth();

  const handleClick = () => {
    window.open(
      `https://store.suitesha.re/${user.company.social_url}`,
      '_blank',
    );
  };

  return (
    <div id="catalog-button-wrapper">
      <button id="catalog-button" type="button" onClick={() => handleClick()}>
        <FiEye />
        Ver Catálogo
      </button>
    </div>
  );
});

export default CatalogButton;
