import styled from 'styled-components';

export const ImgContainer = styled.div`
  display: none;
  @media (min-width: 1024px) {
    width: 60%;
    height: 100%;
    min-width: 400px;
    display: flex;
    justify-content: center;
    align-items: content;
    .parent-company-presentation {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h1,
      p {
        text-align: center;
      }
      h1 {
        font-size: 1.6rem;
        font-weight: 600;
      }
      img {
        width: 50%;
        object-fit: contain;
      }
      p {
        font-size: 1.2rem;
      }
    }
    figure {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      overflow: hidden;
      .line-bg {
        width: 85%;
        bottom: 0;
        left: 0;
        z-index: 0;
        position: absolute;
      }
      img {
        width: 60%;
        position: relative;
        z-index: 1;
        &.connect {
          width: 100%;
        }
      }
    }
  }
`;
