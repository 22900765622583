import styled from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #fff;
  border-radius: 10px;
  border: solid 1px #afafaf;
  padding: 16px;
  width: 100%;
  color: #afafaf;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isErrored &&
    `
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    `
      color: #00BF54;
      border-color: #00BF54;
    `}

  ${(props) =>
    props.isFilled &&
    `
      color: #00BF54;
    `}

  input {
    flex: 1;
    background: trasparent;
    border: 0;
    color: #232323;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0%;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
