import React, { FC, useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { IStoreProps } from '../../../Types';
import { useAuth } from '../../../hooks/auth';

import { GeneralStoreContext } from '../../../stores/GeneralStore';

import './styles.scss';

const CatalogSelect: FC = observer(() => {
  const generalStore = useContext(GeneralStoreContext);
  const { user } = useAuth();
  const { Option } = Select;

  const [defaultStore, setDefaultStore] = useState<string>(
    'Selecione um catálogo',
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (generalStore.catalogsList && generalStore.catalogsList.length > 0) {
      const filteredCatalog = generalStore.catalogsList.filter(
        (store: IStoreProps) => store.id === generalStore.currentCatalogId,
      );
      if (filteredCatalog && filteredCatalog.length > 0) {
        setDefaultStore(filteredCatalog[0].title);
      }
    }
    setIsLoading(false);
  }, [generalStore.catalogsList, generalStore.currentCatalogId]);

  const handleCatalogChange = (event: any, value: any) => {
    generalStore.setCurrentCatalogId(value.key);
    localStorage.setItem(`@Suiteshare:catalogId-${user.company_id}`, value.key);
  };

  return (
    <div className="catalog-select">
      <div className="catalog-select__wrapper">
        <span className="catalog-select__title">Catálogo</span>
        {!isLoading ? (
          <Select
            defaultValue={
              generalStore.catalogsList && generalStore.catalogsList.length > 0
                ? generalStore.catalogsList[0].title
                : 'Selecione um catálogo'
            }
            value={defaultStore}
            onChange={handleCatalogChange}
          >
            {generalStore.catalogsList?.map((catalog: IStoreProps) => {
              return (
                <Option key={catalog.id} value={catalog.title}>
                  {catalog.title}
                </Option>
              );
            })}
          </Select>
        ) : (
          <LoadingOutlined />
        )}
      </div>
    </div>
  );
});

export default CatalogSelect;
