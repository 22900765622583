import React, { FC, useEffect, useState, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { message, Skeleton } from 'antd';

import { useAuth } from '../../../hooks/auth';

import { ConnectStoreContext } from '../../../stores/ConnectStore';

import MobPreview from './components/MobPreview';
import ConnectEntitiesServices from '../../../services/connect/Entities';
import { Display, IndividualContent, StyledForm } from './styles';
import Customize from './components/Customize';
import CreateContent from './components/CreateContent';
import ShareLinks from './components/Share';
import { IConnectProps } from '../../../Types';

const ConnectMain: FC = observer(() => {
  const connectStore = useContext(ConnectStoreContext);

  const { user } = useAuth();
  const { company_id } = user;

  const [loading, setLoading] = useState(false);

  const defaultCompanyData = {
    company_id,
    rounded_header: true,
    primary_color: '#FD397A',
    secondary_color: '#6F42EB',
  };

  useEffect(() => {
    setLoading(true);
    ConnectEntitiesServices.getConnectData(user.company_id).then(
      (connectData: IConnectProps) => {
        if (!connectData) {
          ConnectEntitiesServices.postConnectData(
            user.company_id,
            defaultCompanyData,
          )
            .then((newConnectData: IConnectProps) => {
              connectStore.setConnectOptions(newConnectData);
              message.success('Connect criado com sucesso!');
            })
            .catch((error) => message.error(error.message))
            .finally(() => {
              setLoading(false);
            });
        } else {
          connectStore.setConnectOptions(connectData);
          setLoading(false);
        }
      },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id]);

  return (
    <IndividualContent>
      <StyledForm>
        {loading ? (
          <Skeleton />
        ) : (
          <Switch>
            <Route path="/:app/customize" component={Customize} />
            <Route path="/:app/links" component={CreateContent} />
            <Route path="/:app/share" component={ShareLinks} />
          </Switch>
        )}
        <Display>
          <MobPreview />
        </Display>
      </StyledForm>
    </IndividualContent>
  );
});

export default ConnectMain;
