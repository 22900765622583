import Resizer from 'react-image-file-resizer';
import { v4 as uuid_v4 } from 'uuid';
import api from '../services/api';

export const resizeFile = (file: any): Promise<string> =>
  new Promise((resolve: any) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      'PNG',
      70,
      0,
      (uri: any) => {
        resolve(uri);
      },
      'base64',
    );
  });

export const base64ToFile = async (img: string, fileName: string) => {
  return fetch(img)
    .then((res) => res.blob())
    .then(async (blob) => {
      return new File([blob], fileName, {
        type: 'image/png',
      });
    });
};

export const blobCreationFromURL = async (urlParameter: string) => {
  const uid = uuid_v4();

  return api
    .get(`/public/proxy?image_url=${urlParameter}`, {
      responseType: 'blob',
    })
    .then((res) => new Blob([res.data]))
    .then((blob) => {
      const file = new File([blob], `file-${uid}.png`);

      return file;
    });
};
