import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { ArrowRight, Plus } from 'react-feather';

import { useAuth } from '../../hooks/auth';

import HeaderNavBar from './HeaderNavBar';
import HeaderTop from './HeaderTop';

import SuiteAppsContainer from '../SuiteAppsContainer';

import { HeaderContainer, Icons, Icon } from './styles';

interface IUrlParams {
  app: string;
}

const Header: React.FC = () => {
  const { app } = useParams<IUrlParams>();
  const { logout } = useAuth();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPlusButtonClicked, setIsPlusButtonClicked] = useState(false);

  const handlePlusButton = (value: boolean) => {
    setIsPlusButtonClicked(value);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <HeaderContainer>
        <HeaderTop app={app} />
        <nav className={`nav ${app}`}>
          <HeaderNavBar
            app={app}
            isPlusButtonClicked={isPlusButtonClicked}
            handlePlusButton={handlePlusButton}
          />
        </nav>
      </HeaderContainer>

      {/* <div className="mobile-logo-header">
        <HeaderTop app={app} />
      </div> */}

      <HeaderContainer className="mobile">
        <div
          id="plus-menu"
          style={{
            bottom: isPlusButtonClicked ? '105%' : '-100%',
            opacity: isPlusButtonClicked ? 1 : 0,
          }}
        >
          <NavLink to="/store/products/register">
            <i>
              <Plus />
            </i>
            <span>Produto</span>
          </NavLink>
          <NavLink to="/store/categories/register">
            <i>
              <Plus />
            </i>
            <span>Categoria</span>
          </NavLink>
        </div>
        <div className="title">
          <nav className={`nav ${app}`}>
            <HeaderNavBar
              app={app}
              isPlusButtonClicked={isPlusButtonClicked}
              handlePlusButton={handlePlusButton}
            />
            <div id="button-mobile-menu">
              <button
                type="button"
                onClick={() => {
                  setIsMenuOpen(true);
                  setIsPlusButtonClicked(false);
                }}
              >
                <BsThreeDotsVertical />
              </button>
            </div>
          </nav>
          <div className="menu-box" style={{ right: isMenuOpen ? 0 : '-100%' }}>
            <Icons>
              <Icon>
                <button type="button" onClick={() => handleLogout()}>
                  <AiOutlinePoweroff size={13} />
                  <p>Sair</p>
                </button>
              </Icon>
              <SuiteAppsContainer />
            </Icons>
            <button
              className="close-btn"
              type="button"
              onClick={() => setIsMenuOpen(false)}
            >
              <ArrowRight />
            </button>
          </div>
        </div>
      </HeaderContainer>
    </>
  );
};

export default Header;
