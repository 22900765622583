import React from 'react';
import { message } from 'antd';
import { FiCopy, FiShare2 } from 'react-icons/fi';

import { useAuth } from '../../../../../hooks/auth';

import {
  ShareWrapper,
  Title,
  Subtitle,
  IntagramShareButton,
  ShareLink,
} from './styles';

import instagram from '../../../../../assets/icons/instagram/instagram.png';

const Share: React.FC = () => {
  const { user } = useAuth();

  const copyToClip = (e: any) => {
    e.target.select();
    document.execCommand('copy');
    e.target.focus();

    message.success('Link copiado');
  };

  return (
    <ShareWrapper>
      <Title>
        <h2>
          <span>
            <FiShare2 />
          </span>{' '}
          Compartilhar link
        </h2>
      </Title>
      <Subtitle style={{ marginTop: '3rem' }}>
        <p>
          <span>
            Esse é o seu link do Connect para utilizar nas suas redes sociais!
            ;)
          </span>
        </p>
      </Subtitle>

      <ShareLink>
        <input
          type="text"
          value={`suitesha.re/${user.company.social_url}`}
          onClick={(e) => copyToClip(e)}
        />
        <FiCopy />
      </ShareLink>

      <Subtitle>
        <p>
          <span>
            O instagram é uma das redes sociais que mais convertem. Clique no
            botão abaixo para compartilhar na bio do instagram da sua empresa.
          </span>
        </p>
      </Subtitle>

      <IntagramShareButton type="button">
        <a
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instagram} alt="instagram" />
          Compartilhar na bio do Instagram
        </a>
      </IntagramShareButton>
    </ShareWrapper>
  );
};

export default Share;
