export default class StoreViews {
  static readonly Products: string = 'Produtos';

  static readonly ProductRegistration: string = 'Cadastrar Produtos';

  static readonly Categories: string = 'Categorias';

  static readonly CategoryRegistration: string = 'Cadastrar Categorias';

  static readonly Orders: string = 'Pedidos';

  static readonly Configurations: string = 'Configurações';

  static readonly navItems = {
    products: StoreViews.Products,
    categories: StoreViews.Categories,
    orders: StoreViews.Orders,
    configuration: StoreViews.Configurations,
  };
}
