import { message } from 'antd';
import { IConnectLinks } from '../../Types';
import api from '../api';

export default class ConnectLinksServices {
  static readonly putCompanyLinks = async (
    companyId: string,
    link: IConnectLinks,
  ) => {
    const putLinks = await api
      .put(`/company/${companyId}/connect-links`, link)
      .then((res) => res.data)
      .catch((err) => message.error(err.message));

    return putLinks;
  };

  static readonly postCompanyLinks = async (
    companyId: string,
    link: IConnectLinks,
  ) => {
    const postLinks = await api
      .post(`/company/${companyId}/connect-links`, link)
      .then((res) => res.data)
      .catch((err) => message.error(err.message));

    return postLinks;
  };

  static readonly deleteCompanyLink = async (
    companyId: string,
    companyLinkId: string,
  ) => {
    const deleteLink = await api
      .delete(`/company/${companyId}/connect-links/${companyLinkId}`)
      .catch((err) => message.error(err.message));

    return deleteLink;
  };
}
