import styled from 'styled-components';
import { fadeIn } from '../../../../../styles/animations';

export const ShareWrapper = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  animation: ${fadeIn} 0.6s;

  @media (min-width: 1024px) {
    padding: 0 5rem;
  }
`;

export const Title = styled.div`
  width: 100%;
  h2 {
    display: flex;
    flex: 1;
    width: 100%;
    margin: 0 0 2.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 21px;
    align-items: center;
    color: #171733;
    span {
      font-family: 'Poppins', sans-serif;
      margin-right: 0.5rem;
      color: #fd397a;
    }
  }
`;

export const Subtitle = styled.div`
  width: 100%;
  text-align: left;
  line-height: 21px;
  color: #232323;
  margin: 0 0 1rem;
  p {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  span {
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

export const CreateNewButton = styled.button`
  background-color: #fff;
  border: 1px solid #fd397a;
  width: 100%;
  color: #fd397a;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  padding: 0.5rem;
  outline: none !important;
`;

export const IntagramShareButton = styled(CreateNewButton)`
  border: none;
  padding: 0;
  background: linear-gradient(88.65deg, #fd397a 0%, #6f42eb 99.75%);
  box-shadow: 0px 4px 4px rgba(179, 62, 180, 0.25);
  img {
    width: 1.5rem;
    margin: 0 1rem 0 0;
  }
  a {
    width: 100%;
    height: 100%;
    padding: 0.7rem;
    display: inline-block;
    box-sizing: border-box;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    text-decoration: none;
  }
`;

export const ShareLink = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #fff;
  position: relative;
  border: 1px solid #fd397a;
  border-top: none;
  border-bottom: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 400;
  color: #232323;
  outline: none !important;
  margin: 0 0 3rem;
  padding: 0 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(179, 179, 180, 0.25);
  input {
    width: 100%;
    height: 100%;
    border: none;
    text-align: center;
    background: transparent;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  svg {
    position: absolute;
    z-index: 1;
    font-size: 1.5rem;
  }
`;
