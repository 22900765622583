import { message } from 'antd';
import api from '../api';
import { IConnectProps } from '../../Types';

export default class ConnectEntitiesServices {
  static readonly getConnectData = async (companyId: string) => {
    const getData = await api
      .get(`/company/${companyId}/connects`)
      .then((res) => res.data)
      .catch((err) => message.error(err.message));
    return getData;
  };

  static readonly postConnectData = async (
    companyId: string,
    connectObj: IConnectProps,
  ) => {
    const postData = await api
      .post(`/company/${companyId}/connects`, connectObj)
      .catch((err) => message.error(err.message));

    return postData;
  };

  static readonly putConnectData = async (
    companyId: string,
    connectObj: IConnectProps,
  ) => {
    const putData = await api
      .put(`/company/${companyId}/connects`, connectObj)
      .catch((err) => message.error(err.message));

    return putData;
  };

  static readonly postConnectImage = async (
    companyId: string,
    connectId: string,
    image: object,
  ) => {
    const connectImage = await api
      .post(`/company/${companyId}/connects/${connectId}/upload`, image)
      .catch((err) => message.error(err.message));

    return connectImage;
  };

  static readonly deleteConnectData = async (
    companyId: string,
    connectId: string,
  ) => {
    const deleteData = await api
      .delete(`/company/${companyId}/connects/${connectId}`)
      .catch((err) => message.error(err.message));

    return deleteData;
  };
}
