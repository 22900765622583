import React, { FC, useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import parse from 'html-react-parser';
import Slider from 'react-slick';
import { Skeleton, Switch } from 'antd';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { ICompanyProducts } from '../../../../../Types';

import { useAuth } from '../../../../../hooks/auth';

import ProductsServices from '../../../../../services/store/Products';

import inProgress from '../../../../../assets/empty/in-progress.png';

import '../../../../../../node_modules/slick-carousel/slick/slick.css';
import '../../../../../../node_modules/slick-carousel/slick/slick-theme.css';
import { Content } from '../ProductsList/styles';
import './styles.scss';
import { GeneralStoreContext } from '../../../../../stores/GeneralStore';

interface IUrlParams {
  id: string;
}

interface IArrowProps {
  className?: string;
  style?: object;
  direction: string;
  onClick?: () => void;
}

function CustomArrow(props: IArrowProps) {
  const { className, style, direction, onClick } = props;
  return (
    <button
      type="button"
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      {direction === 'right' ? <FiChevronRight /> : <FiChevronLeft />}
    </button>
  );
}

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  nextArrow: <CustomArrow className="custom-slick-arrow" direction="right" />,
  prevArrow: <CustomArrow className="custom-slick-arrow" direction="left" />,
};

const ProductInfo: FC = observer(() => {
  const generalStore = useContext(GeneralStoreContext);
  const history = useHistory();
  const { id } = useParams<IUrlParams>();
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState<ICompanyProducts>({
    id: '',
    company_id: '',
    parent_company_id: '',
    enabled: true,
    available_quantity: 0,
    blocked_quantity: 0,
    product: {
      id: '',
      code: '',
      name: '',
      value: '',
      enabled: true,
      images: [],
      category_id: '',
      description: '',
    },
    product_id: '',
    promotional_value: null,
    created_at: '',
    updated_at: '',
  });

  const storeId = generalStore.currentCatalogId;

  useEffect(() => {
    if (user.company.parent_company_id) {
      ProductsServices.getChildProducts(user.company_id, storeId)
        .then((companyProducts) => {
          if (companyProducts) {
            const currCompanyProduct = companyProducts.filter(
              (companyProd: ICompanyProducts) => {
                return companyProd.id === id;
              },
            );
            setProductData(currCompanyProduct[0]);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, user, generalStore, storeId]);

  const handleProuctSwitch = () => {
    ProductsServices.editChildProduct(storeId, id, !productData.enabled).then(
      () => {
        ProductsServices.getChildProducts(user.company_id, storeId).then(
          (companyProducts) => {
            if (companyProducts) {
              const currCompanyProduct = companyProducts.filter(
                (companyProd: ICompanyProducts) => {
                  return companyProd.id === id;
                },
              );
              setProductData(currCompanyProduct[0]);
            }
          },
        );
      },
    );
  };

  return (
    <Content>
      <div className="product-info wrapper-1-1">
        <div className="navigation-button-wrapper wrapper-1-1 row center xa-start">
          <button
            type="button"
            className="back-button"
            onClick={() => history.push('/store/products')}
          >
            <FiChevronLeft /> Voltar
          </button>
        </div>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <div className="title-row wrapper-1-1 row between">
              <h1>Informações do produto</h1>
              <div className="switch-wrapper row center">
                <p>{productData?.enabled ? 'Disponível' : 'Indisponível'}</p>
                <Switch
                  checked={productData.enabled}
                  onChange={() => handleProuctSwitch()}
                />
              </div>
            </div>
            <div className="product-info__body row wrapper-1-1 ya-start between">
              <div className="product-info__content wrapper-1-2 column center ya-start">
                <div className="product-info__row wrapper-1-1 row between ya-start">
                  <div className="column center xa-start">
                    <h3>Nome do produto:</h3>
                    <p>{productData?.product?.name}</p>
                  </div>
                  <div className="column center xa-end">
                    <h3>
                      {productData?.product?.value === '$0.00'
                        ? 'Preço sob consulta'
                        : parseFloat(
                            productData.product.value
                              .replace(/\$/g, '')
                              .replace(/,/g, ''),
                          ).toLocaleString('pt-BR')}
                    </h3>
                  </div>
                </div>
                <div className="product-info__row wrapper-1-1 row between ya-start">
                  <div className="column center xa-start">
                    <h3>Categoria:</h3>
                    <p>{productData?.product?.category?.description}</p>
                  </div>
                  <div className="column center xa-end">
                    <h3>Referência:</h3>
                    <p>{productData?.product?.code}</p>
                  </div>
                </div>
                <div className="product-info__row wrapper-1-1 row between ya-start">
                  <h3>Descrição:</h3>
                </div>
                <div className="product-info__row wrapper-1-1 row start">
                  {parse(productData?.product?.description)}
                </div>
                <div className="product-info__row wrapper-1-1 row start">
                  <h3>Imagens do produto:</h3>
                </div>
                <div className="product-info__row wrapper-1-1 row start">
                  {productData.product.images.length >= 3 ? (
                    <Slider {...settings}>
                      {productData?.product?.images.map((image) => {
                        return (
                          <img
                            src={
                              process.env.REACT_APP_BUCKET_ASSETS_URL +
                              encodeURI(image.image_url)
                            }
                            alt={image.image_url}
                          />
                        );
                      })}
                    </Slider>
                  ) : (
                    productData?.product?.images.map((image) => {
                      return (
                        <figure className="product-info__image-wrapper">
                          <img
                            src={
                              process.env.REACT_APP_BUCKET_ASSETS_URL +
                              encodeURI(image.image_url)
                            }
                            alt={image.image_url}
                          />
                        </figure>
                      );
                    })
                  )}
                </div>
              </div>
              <div className="product-info__content product-info__content--borderless wrapper-1-2 column center ya-start">
                <div className="product-info__row wrapper-1-1 row start">
                  <h3>Variações de produto</h3>
                </div>
                <div className="product-info__row wrapper-1-1 row center">
                  <h3>Em breve!</h3>
                </div>
                <div className="product-info__row wrapper-1-1 row center">
                  <figure>
                    <img
                      src={inProgress}
                      alt="Tela de computador, fundo laranja, com barra de loading, branca, sendo preenchida"
                    />
                  </figure>
                </div>
                <div className="product-info__row wrapper-1-2 row center">
                  <p className="t-center">
                    <small>
                      Estamos sempre evoluindo! Logo, logo você poderá cadastrar
                      as variações dos seus produtos! ;)
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Content>
  );
});

export default ProductInfo;
