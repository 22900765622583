import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import './styles.scss';

const TrialEnd: FC = () => {
  const history = useHistory();

  return (
    <div className="trial-end wrapper-1-1 column center">
      <h1 className="wrapper-1-2">Seu período de teste chegou ao fim</h1>
      <p className="wrapper-1-2">
        Entre em contato com nosso time comercial para continuar utilizando a
        Store
      </p>
      <div className="button-wrapper wrapper-1-2 row center">
        <button
          type="button"
          onClick={() => {
            window.open('https://whts.co/storesuiteshare');
            history.push('/store/login');
          }}
        >
          Falar agora
        </button>
      </div>
    </div>
  );
};

export default TrialEnd;
