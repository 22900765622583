import styled from 'styled-components';

export const Container = styled.div`
  display: none !important;

  width: 100%;
  height: 60px;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: 0.7fr 2fr 0.7fr;
  grid-template-rows: 1fr;
  background: #161823;
  box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.6);
  color: #fff;
  overflow-x: hidden;
  @media (min-width: 1024px) {
    position: relative;
    height: 100%;
    width: 65px;
    top: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

export const Logo = styled.div`
  position: relative;
  z-index: 4;
  margin: 0;
  box-sizing: border-box;
  outline: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    height: 80px;
  }
  img {
    width: auto;
    height: 1.8rem;
    @media (min-height: 1024px) {
      height: 3rem;
    }
  }
`;

export const MenuItems = styled.div`
  margin: auto;
  display: flex;
  @media (min-width: 1024px) {
    display: block;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 1.5rem 0; */
  a {
    width: 100%;
    display: inline-block;
    padding: 0.5rem;
    color: #fff;
    text-align: center;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    transition: color 0.2s ease;
    @media (min-width: 1024px) {
      padding: 1rem 0;
    }
    &.active {
      border-color: #5b5b5b;
      background: #474747;
      border-radius: 0.5rem;
      margin: 0 0.2rem;
    }
    img {
      width: 1.8rem;
      @media (min-width: 1024px) {
        width: 55%;
      }
    }
  }
`;

export const Footer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  margin: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  place-content: center;
  height: 60px;
  bottom: 0;
  box-sizing: border-box;
  a {
    color: #fff;
    transition: color 0.2s ease;
    &:active,
    &:focus,
    &:visited {
      color: #fff;
    }
    &:hover {
      color: #788cdc;
    }
    &.footer-active {
      color: #788cdc;
    }
  }
`;
