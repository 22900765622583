import React, { useState, useRef, useEffect, useContext } from 'react';
import filesize from 'filesize';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { observer } from 'mobx-react';
import { Input, message, Descriptions } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BiImageAdd } from 'react-icons/bi';

import { FileProps, CategoryProps } from '../../../Types';

import { GeneralStoreContext } from '../../../stores/GeneralStore';

import CategoriesServices from '../../../services/store/Categories';
import { resizeFile, base64ToFile } from '../../../utils/resizeFile';
import { useAuth } from '../../../hooks/auth';

import { StyledForm, NavigationButtons, Button } from './styles';
import { ProductDetails, ProductImages } from '../../EditCategModal/styles';

interface IProps {
  defaultInputValue?: string;
  defineSelectDefaultValue?: (category: CategoryProps) => void;
  redirect?: () => void;
}

const CategoryForm: React.FC<IProps> = observer((props) => {
  const { defaultInputValue, defineSelectDefaultValue, redirect } = props;
  const generalStore = useContext(GeneralStoreContext);

  const [categoryName, setCategoryName] = useState<string>();
  const [code, setCode] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<FileProps | null>();
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const handleCode = (description: string) => {
    return description.toLowerCase().split(' ').join('-');
  };

  const storeId = generalStore.currentCatalogId;

  useEffect(() => {
    if (defaultInputValue) {
      setCategoryName(defaultInputValue);
      setCode(handleCode(defaultInputValue));
    }
  }, [defaultInputValue]);

  const submitFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target;
    if (files) {
      if (files.length > 0) {
        const fileMapped: FileProps = {
          file: files[0],
          name: files[0].name,
          position: 0,
          path: URL.createObjectURL(files[0]),
          readableSize: filesize(files[0].size),
        };
        setUploadedFile(fileMapped);
      }
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const categoryData = {
      code,
      enabled: true,
      description: categoryName,
      company_id: user.company_id,
      store_id: storeId,
    };

    CategoriesServices.postCategory(
      user.company_id,
      storeId,
      categoryData,
    ).then(async (response) => {
      if (uploadedFile) {
        const img = await resizeFile(uploadedFile.file);
        const nFile = await base64ToFile(img, uploadedFile.name);
        const image = new FormData();
        image.append('file', nFile, nFile.name);

        CategoriesServices.postCategoryImage(
          user.company_id,
          storeId,
          response.id,
          image,
        );
      }

      message.success('Categoria cadastrada com sucesso!');

      if (categoryName && defineSelectDefaultValue) {
        defineSelectDefaultValue(response);
      }

      setCategoryName('');
      setCode('');
      setUploadedFile(null);
      setIsLoading(false);

      if (redirect) redirect();
      return response;
    });
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(handleCode(e.target.value));
    setCategoryName(e.target.value);
  };

  return (
    <StyledForm>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <ProductDetails style={{ maxWidth: 355, marginBottom: '-1rem' }}>
          <Descriptions
            layout="vertical"
            column={1}
            className="suiteshare-descriptions"
          >
            <Descriptions.Item label="Nome">
              <Input
                id="product-name"
                type="text"
                name="product-name"
                placeholder="Nome da categoria"
                value={categoryName}
                onChange={handleDescriptionChange}
              />
            </Descriptions.Item>
          </Descriptions>
        </ProductDetails>
        <ProductImages>
          {uploadedFile ? (
            <div id="categ-img">
              <img src={uploadedFile.path} alt={uploadedFile.name} />
            </div>
          ) : (
            <div />
          )}
          {uploadedFile?.path ? (
            <label className="change-image" htmlFor="logo-upload">
              <BiImageAdd size={20} />
              Trocar imagem
              <input
                type="file"
                name=""
                id="logo-upload"
                accept=".jpg, .png, .jpeg"
                onChange={(e) => submitFile(e)}
              />
            </label>
          ) : (
            <label className="add-image" htmlFor="logo-upload">
              <BiImageAdd size={50} />
              <input
                type="file"
                name=""
                id="logo-upload"
                accept=".jpg, .png, .jpeg"
                onChange={(e) => submitFile(e)}
              />
            </label>
          )}
          <p>
            *Utilize imagens em sentido horizontal para melhor apresentação da
            categoria
          </p>
        </ProductImages>
        <NavigationButtons>
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            <Button type="submit">Cadastrar</Button>
          )}
        </NavigationButtons>
      </Form>
    </StyledForm>
  );
});

export default CategoryForm;
