import React, { FC, useState, useEffect, useContext } from 'react';
import {
  message,
  Popconfirm,
  Table,
  Skeleton,
  Empty,
  Input,
  Switch,
  Select,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { FiEdit, FiTrash2, FiSearch } from 'react-icons/fi';

import ProductsServices from '../../../../../../services/store/Products';
import SpecificationsServices from '../../../../../../services/store/specifications';
import { getStoreData } from '../../../../../../services/store/Configuration';
import CategoriesServices from '../../../../../../services/store/Categories';

import Paths from '../../../../../../routes/Paths';

import { useAuth } from '../../../../../../hooks/auth';
import { GeneralStoreContext } from '../../../../../../stores/GeneralStore';
import { ProductsStoreContext } from '../../../../../../stores/ProductsStore';

import {
  ProductProps,
  ISpecification,
  IProductSpecificationOption,
} from '../../../../../../Types';

import './styles.scss';
import { Content, StyledForm, ActionBar, AddProduct } from '../styles';

import emptyProducts from '../../../../../../assets/empty/new-empty-products.png';

interface OptionsType {
  id: string;
  value: string;
}

let planLimit = 25;

const AdminView: FC = observer(() => {
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isBtnDisabled, setBtnAsDisabled] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [categoryOptions, setCategoryOptions] = useState<OptionsType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [specifications, setSpecifications] = useState<ISpecification[]>();
  const [specificationsSelect, setSpecificationsSelect] = useState<
    OptionsType[]
  >();

  const generalStore = useContext(GeneralStoreContext);
  const productStore = useContext(ProductsStoreContext);
  const { user } = useAuth();
  const history = useHistory();

  const storeId = generalStore.currentCatalogId;

  useEffect(() => {
    const categOptions: OptionsType[] = [];
    CategoriesServices.getCategories(user.company_id, storeId).then(
      (response) => {
        response.forEach((category: any) => {
          const categoryName = { value: category.description, id: category.id };
          categOptions.push(categoryName);
        });
        setCategoryOptions(categOptions);
      },
    );

    getStoreData(user.company).then((response) => {
      if (response && response.products_limit) {
        planLimit = response.products_limit;
      }
    });

    ProductsServices.getProducts(user.company_id, storeId).then((res) => {
      if (res.length >= planLimit) setBtnAsDisabled(true);
      productStore.setProductsList(res);
      setIsTableLoading(false);
    });

    SpecificationsServices.getSpecifications(user.company_id, storeId).then(
      (specificationsData) => {
        if (specificationsData) {
          setSpecifications(specificationsData);
          const specificationsSelectOptions: OptionsType[] = [];
          specificationsData.forEach((specification) => {
            const specificationSelect = {
              value: specification.description,
              id: specification.id,
            };
            specificationsSelectOptions.push(specificationSelect);
          });
          setSpecificationsSelect(specificationsSelectOptions);
        }
      },
    );
  }, [productStore, user.company_id, user.company, storeId]);

  const goToProductRegister = () => {
    history.push(Paths.productRegistration.url);
  };

  const handleDelete = (productId: string, productName: string) => {
    ProductsServices.deleteProduct(productId, storeId, user.company_id).then(
      (response) => {
        if (!response) {
          setBtnAsDisabled(false);
          return;
        }
        message.success(`Produto ${productName} foi removido com sucesso`);
        productStore.deleteProductFromList(productId);
        setBtnAsDisabled(false);
      },
    );
  };

  const handleSwitch = (product: ProductProps, enabled: boolean) => {
    if (productStore.products) {
      const currProd = product;

      currProd.enabled = enabled;

      ProductsServices.editProduct(user.company_id, storeId, currProd).then(
        () => {
          ProductsServices.getProducts(user.company_id, storeId).then(
            (products) => {
              productStore.setProductsList(products);
            },
          );
        },
      );
    }
  };

  const captureProductRow = (product: ProductProps) => {
    history.push(`/store/products/${product.id}/edit`);
  };

  const handleCategoryChange = async (
    event: string,
    categoryData: any,
    productId: string,
  ) => {
    const currProducts = productStore.products;
    const currProduct = currProducts.filter(
      (product: ProductProps) => product.id === productId,
    );
    currProduct[0].category_id = categoryData.id;

    await ProductsServices.editProduct(
      user.company_id,
      storeId,
      currProduct[0],
    ).then(() => {
      ProductsServices.getProducts(user.company_id, storeId).then(
        (products) => {
          productStore.setProductsList(products);
        },
      );
    });
    productStore.setProductsList([...currProducts]);
  };

  const editSpecification = (
    product: ProductProps,
    specification: ISpecification,
  ) => {
    setIsLoading(true);
    if (
      product &&
      product.productSpecifications &&
      product.productSpecifications?.length > 0
    ) {
      ProductsServices.deleteProductSpecification(
        user.company_id,
        storeId,
        product.id,
        product.productSpecifications[0],
      )
        .then(async () => {
          await ProductsServices.postProductSpecification(
            user.company_id,
            storeId,
            product.id,
            specification.id,
            specification.enabled,
          ).then(async (productSpecificationData) => {
            if (productSpecificationData) {
              const currSpecificationsOptions = specifications?.filter(
                (specs) => {
                  return specs.id === specification.id;
                },
              );

              if (
                currSpecificationsOptions &&
                currSpecificationsOptions.length > 0
              ) {
                await Promise.all(
                  currSpecificationsOptions[0].options.map(
                    (specificationOption) => {
                      return ProductsServices.postProductSpecificationOption(
                        user.company_id,
                        storeId,
                        product.id,
                        {
                          product_specification_id: productSpecificationData.id,
                          specification_option_id: specificationOption.id,
                          sku: '',
                          position: specificationOption.position,
                          enabled: specificationOption.enabled,
                        },
                      );
                    },
                  ),
                );
              }
            }
          });
        })
        .finally(() => {
          ProductsServices.getProducts(user.company_id, storeId).then(
            (products) => {
              productStore.setProductsList(products);
            },
          );
        });

      setIsLoading(false);
    }
  };

  const createSpecification = (
    productData: string,
    specification: ISpecification,
  ) => {
    ProductsServices.postProductSpecification(
      user.company_id,
      storeId,
      productData,
      specification.id,
      specification.enabled,
    ).then(() => {
      ProductsServices.getProducts(user.company_id, storeId).then(
        (products) => {
          productStore.setProductsList(products);
        },
      );
    });
  };

  const handleSpecificationChange = async (
    event: string,
    specification: any,
    product: ProductProps,
  ) => {
    const currProduct = productStore.products.filter(
      (o: ProductProps) => o.id === product.id,
    );
    currProduct[0].specifications?.length > 0
      ? editSpecification(product, specification)
      : createSpecification(product.id, specification);
  };

  const editOption = (
    option: IProductSpecificationOption,
    product: ProductProps,
  ) => {
    const toggleEnable = !option.enabled;
    if (
      product.productSpecifications &&
      product.productSpecifications.length > 0
    ) {
      ProductsServices.putProductSpecificationOption(
        user.company_id,
        storeId,
        product.id,
        product.productSpecifications[0].specification_id,
        {
          id: option.id,
          sku: '',
          position: option.position,
          enabled: toggleEnable,
        },
      ).then(() => {
        ProductsServices.getProducts(user.company_id, storeId).then(
          (products) => {
            productStore.setProductsList(products);
          },
        );
      });
    }
  };

  const parentColumns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 450,
    },
    {
      title: 'Categoria',
      render: (row: ProductProps) => (
        <div>
          <Select
            showSearch
            placeholder="Escolha uma categoria"
            optionFilterProp="children"
            value={row.category?.description}
            onChange={(event, obj) => handleCategoryChange(event, obj, row.id)}
            options={categoryOptions}
            filterOption={(inputValue, option) => {
              return (
                option?.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              );
            }}
          />
        </div>
      ),
    },
    {
      title: 'Variações',
      render: (row: ProductProps) =>
        !isLoading ? (
          <div>
            <Select
              showSearch
              placeholder="Escolha uma variação"
              optionFilterProp="children"
              value={
                row.productSpecifications &&
                row.productSpecifications.length > 0
                  ? row.productSpecifications[0]?.specification?.description
                  : 'Escolha uma variação'
              }
              onChange={
                (event, obj) => handleSpecificationChange(event, obj, row)
                // eslint-disable-next-line react/jsx-curly-newline
              }
              options={specificationsSelect}
              filterOption={(inputValue, option) => {
                return (
                  option?.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                );
              }}
            />
          </div>
        ) : (
          <LoadingOutlined />
        ),
    },
    {
      title: 'Opções',
      render: (row: ProductProps) =>
        !isLoading ? (
          <div className="option-button">
            {row.productSpecifications
              ? row.productSpecifications[0].options.map((option) => (
                  // eslint-disable-next-line react/jsx-indent
                  <button
                    type="button"
                    onClick={() => editOption(option, row)}
                    className={`${option.enabled ? 'active' : ''}`}
                  >
                    {option.specificationOption?.description}
                  </button>
                ))
              : ''}
          </div>
        ) : (
          <LoadingOutlined />
        ),
    },
    {
      title: 'Editar',
      render: (row: ProductProps) => (
        <button
          className="tableButton"
          type="submit"
          onClick={() => captureProductRow(row)}
        >
          <FiEdit />
        </button>
      ),
      width: 100,
    },
    {
      title: 'Habilitar',
      render: (product: ProductProps) => (
        <div className="switch-wrapper">
          <Switch
            checked={product.enabled}
            onChange={() => handleSwitch(product, !product.enabled)}
          />
        </div>
      ),
      width: 100,
    },
    {
      title: 'Remover',
      render: (row: ProductProps) => (
        <Popconfirm
          title="Tem certeza que deseja excluir esse produto?"
          onConfirm={() => handleDelete(row.id, row.name)}
          okText="Sim"
          cancelText="Não"
        >
          <button className="tableButton remove" type="submit">
            <FiTrash2 />
          </button>
        </Popconfirm>
      ),
      width: 100,
    },
  ];

  const emptyTable = (
    <Empty
      image={emptyProducts}
      description="Você não possui produtos cadastrados."
    >
      <button
        className="primary-button store"
        type="button"
        onClick={() => history.push('/store/products/register')}
        style={{ backgroundColor: 'FFB822' }}
      >
        Cadastrar produto
      </button>
    </Empty>
  );

  const tableData = productStore.products?.filter((product: ProductProps) => {
    return (
      (product.name.toLowerCase().includes(filterName.toLowerCase()) ||
        product.code.toLowerCase().includes(filterName.toLowerCase())) &&
      product.category?.description
        .toLowerCase()
        .includes(filterCategory.toLowerCase())
    );
  });

  const FilterByNameInput = (
    <Input
      placeholder="Pesquisar produto"
      value={filterName}
      className="table-search"
      suffix={<FiSearch />}
      onChange={(e) => setFilterName(e.target.value)}
    />
  );
  const FilterByCategoryInput = (
    <Input
      placeholder="Pesquisar categoria"
      value={filterCategory}
      className="table-search"
      suffix={<FiSearch />}
      onChange={(e) => setFilterCategory(e.target.value)}
    />
  );

  const renderTable = () => {
    return (
      <Table
        columns={parentColumns}
        dataSource={tableData}
        className="suiteshare-table"
        locale={{ emptyText: emptyTable }}
      />
    );
  };

  return (
    <Content>
      <ActionBar className="action-bar">
        <div className="text-wrapper">
          <h1>Produtos</h1>
          <p>
            {productStore.products?.length} de {planLimit}
          </p>
        </div>
        <AddProduct
          onClick={goToProductRegister}
          title="Cadastrar novo produto"
          disabled={isBtnDisabled}
        >
          Cadastrar produto
        </AddProduct>
      </ActionBar>
      <div className="product-filters">
        <div className="product-filters__wrapper">{FilterByNameInput}</div>
        <div className="product-filters__wrapper">{FilterByCategoryInput}</div>
      </div>
      <StyledForm>
        {isTableLoading ? <Skeleton active /> : renderTable()}
      </StyledForm>
    </Content>
  );
});

export default AdminView;
