import styled from 'styled-components';
import { shade } from 'polished';
import { Container } from '../../../../../styles/default';
import { fadeIn } from '../../../../../styles/animations';

export const CategoriesRegistrationContainer = styled(Container)`
  flex: 0 0 100%;
  flex-wrap: wrap;
  padding: 0 2.5rem;
  align-items: flex-start;
`;

export const Content = styled.div`
  width: 100%;
  margin: 3rem 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: ${fadeIn} 0.5s;

  #code-preview {
    margin-bottom: 16px;
    font-size: 12px;
  }
`;

export const StyledForm = styled.div`
  width: 100%;
  min-height: 10rem;
  margin: 2.5rem 0 0 0;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  color: #000000;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.1);
  form {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      padding: 0;
      border: none;
      width: 50%;
      height: auto;
      margin: 0rem 0 1.5rem;
      input {
        width: 100%;
        border: 1px solid rgba(170, 170, 170, 0.6);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        border-radius: 2px;
        padding: 0.4rem 0.8rem;
      }
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  h2 {
    display: flex;
    flex: 1;
    width: 100%;
    margin: 0 0 2.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 21px;
    align-items: center;
    color: #171733;
    span {
      font-family: 'Lato', sans-serif;
      margin-right: 0.5rem;
    }
  }
`;

export const Subtitle = styled.div`
  width: 100%;
  text-align: left;
  max-width: 35rem;
  line-height: 21px;
  color: #232323;
  margin: 0 0 3rem;
  p {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  span {
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

export const NavigationButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 0 4rem;
  justify-content: space-around;
`;

export const BackButton = styled.button`
  svg {
    color: #ffa800;
    margin-right: 0.3rem;
  }
  border: none;
  background: none;
  font-size: 0.8rem;
  font-weight: 600;
  outline: none;
  &:active {
    outline: none;
  }
  &:hover {
    outline: none;
  }
`;

export const Button = styled.button`
  width: 180px;
  height: 36px;
  background: #ffa800;
  border: 0;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 19px;
  color: #f5f5f5;
`;

export const AnimationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  place-content: center;

  animation: ${fadeIn} 0.5s;

  form {
    margin: 80px 0;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #fff;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#fff')};
      }
    }
  }

  > a {
    color: #fff;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }
`;

export const StyledInput = styled.input`
  border-radius: 2px;
  border: 1px solid #aaaaaa;
  box-sizing: border-box;
  width: 100%;
`;

export const FormHeader = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ImagesContainer = styled.div`
  display: flex;
  min-width: 250px;
  width: 30%;
  padding-top: 16px;
  padding-right: 42px;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const Images = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MainImage = styled.div`
  display: flex;
  width: 60%;
  height: 140px;
  margin-right: 24px;
`;

export const SecondaryImages = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
  background: #f5f5f5;
  border: 1px solid #aaaaaa;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;

  &:first-child {
    margin-top: 0px;
  }

  svg {
    color: #aaaaaa;
  }
`;

export const ImagesSubtitle = styled.div`
  height: 50px;
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 12px;
  display: flex;
  align-items: center;

  color: #232323;
`;

export const UploadImage = styled.input`
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const Fields = styled.div`
  flex-wrap: wrap;
  display: flex;
  width: 70%;
  padding-left: 0px;
  padding-top: 16px;
  flex-direction: column;
  height: 100%;
`;

export const ProductFields = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0 0 0px;

  &:first-child {
    padding: 0px;
  }

  input {
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: 24px;

    &:first-child {
      margin: 0;
    }
  }
`;

export const ProductDescription = styled.div`
  padding-top: 64px;

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;

    color: #232323;
  }
`;

export const Description = styled.textarea`
  width: 100%;

  border: 1px solid #aaaaaa;
  box-sizing: border-box;
  border-radius: 2px;
`;
