import { createContext } from 'react';
import { observable } from 'mobx';

import { IConnectProps } from '../Types';

class ConnectStore {
  @observable links: any = [];

  @observable connectOptions: any;

  @observable logoPreview: any;

  setLogoPreview = (logo: any) => {
    this.logoPreview = logo;
  };

  setLinks = (links: any) => {
    this.links = links;
  };

  setConnectOptions = (options: IConnectProps) => {
    this.connectOptions = options;
  };
}

export const ConnectStoreContext = createContext(new ConnectStore());
