import React, { FC, useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { Select, message, Skeleton } from 'antd';
import { BsBuilding } from 'react-icons/bs';
import MaskedInput from 'antd-mask-input';

import CompanyServices from '../../../../../services/company/index';

import { GeneralStoreContext } from '../../../../../stores/GeneralStore';

import { useAuth } from '../../../../../hooks/auth';
import { CompanyProps } from '../../../../../Types';

const prefixOptions = ['55'];

const CompanyInfo: FC = observer(() => {
  const companyStore = useContext(GeneralStoreContext);
  const { user } = useAuth();
  const { Option } = Select;

  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState<CompanyProps>(
    companyStore.companyData,
  );
  const [companyForPhone, setCompanyForPhone] = useState<CompanyProps>(
    companyStore.companyData,
  );
  const [numberPrefix, setNumberPrefix] = useState('55');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    CompanyServices.getCompanyData(
      (companyStore.companyData && companyStore.companyData.social_url) ||
        user.company.social_url,
    )
      .then((response) => {
        companyStore.setCompanyData(response);
        setCompany(response);
        setCompanyForPhone(response);
        if (response.phone) {
          const regex = new RegExp(/\W+/);
          const sectNumber = response.phone.replace(regex, '');
          const prefix = sectNumber.split('').splice(0, 2).join('');
          setNumberPrefix(prefix);
          setPhoneNumber(sectNumber.substring(2));
        }
      })
      .finally(() => setIsLoading(false));
  }, [user, companyStore]);

  const handlePrefixChange = (selectedItem: string) => {
    setNumberPrefix(selectedItem);
  };

  const handleNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  const prefixSelector = (
    <Select
      style={{ width: 70 }}
      value={numberPrefix}
      onChange={(value) => handlePrefixChange(value)}
    >
      {prefixOptions.map((option) => {
        return (
          <Option key={option} value={option}>
            {option}
          </Option>
        );
      })}
    </Select>
  );

  const handleSubmitPhone = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    const currentCompany = companyForPhone;

    currentCompany.phone = '';

    if (phoneNumber) {
      const newPhone = numberPrefix + phoneNumber;

      currentCompany.phone = newPhone;
    }

    CompanyServices.putCompanyData(currentCompany).then((response) => {
      if (response) {
        message.success('Dados atualizados com sucesso');
      }
    });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const currentCompany = company;

    CompanyServices.putCompanyData(currentCompany).then((response) => {
      if (response) {
        message.success('Dados atualizados com sucesso');
      }
    });
  };

  return (
    <div id="company-info" className="view-wrapper">
      <div
        className="title-wrapper wrapper-1-1 row xa-start ya-center"
        style={{ gap: '0.5rem', margin: '0 0 2rem' }}
      >
        <BsBuilding />
        <h1>Informações da empresa</h1>
      </div>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <form className="config-page-form" action="">
            <div className="config-page-register-data">
              <h1>Dados Cadastrais</h1>
              <div className="config-page-form-item">
                <p>Nome da Empresa</p>
                <input value={company.name} disabled />
              </div>
              <div className="config-page-form-item">
                <p>Telefone para contato</p>
                <MaskedInput
                  addonBefore={prefixSelector}
                  mask="(11) 11111-1111"
                  style={{ width: '100%' }}
                  value={phoneNumber}
                  id="user-phone"
                  name="Telefone para contato"
                  placeholder="(51) 00000-0000"
                  onChange={
                    ({ target: { value } }) => handleNumberChange(value)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
              </div>
              <div className="config-page-form-item button-wrapper">
                <button type="button" onClick={(e) => handleSubmitPhone(e)}>
                  Editar
                </button>
              </div>
            </div>
            <div className="config-page-adress">
              <h1>Dados de Endereço</h1>
              <label htmlFor="country" className="config-page-form-item">
                <p>País</p>
                <input
                  type="text"
                  id="country"
                  name="País"
                  placeholder="Digite ou selecione o País"
                  value={company?.country}
                  onChange={({ target: { value } }) => {
                    setCompany({ ...company, country: value });
                  }}
                />
              </label>
              <div className="config-page-form-item">
                <p>CEP</p>
                <MaskedInput
                  mask="11111-111"
                  style={{ width: '100%' }}
                  value={company?.zipcode}
                  id="zip-code"
                  name="CEP"
                  placeholder="00000-000"
                  onChange={({ target: { value } }) => {
                    setCompany({ ...company, zipcode: value });
                  }}
                />
              </div>
              <label htmlFor="address" className="config-page-form-item">
                <p>Rua</p>
                <input
                  type="text"
                  id="address"
                  name="Rua"
                  placeholder="Digite a Rua"
                  value={company?.address}
                  onChange={({ target: { value } }) => {
                    setCompany({ ...company, address: value });
                  }}
                />
              </label>
              <label htmlFor="address-number" className="config-page-form-item">
                <p>Número</p>
                <input
                  type="number"
                  id="address-number"
                  name="Número"
                  placeholder="Digite o Número"
                  value={company?.address_number}
                  onChange={({ target: { value } }) => {
                    setCompany({ ...company, address_number: value });
                  }}
                />
              </label>
              <label htmlFor="city" className="config-page-form-item">
                <p>Cidade</p>
                <input
                  type="text"
                  id="city"
                  name="Cidade"
                  placeholder="Digite a cidade"
                  value={company?.city}
                  onChange={({ target: { value } }) => {
                    setCompany({ ...company, city: value });
                  }}
                />
              </label>

              <label htmlFor="state" className="config-page-form-item">
                <p>Estado</p>
                <input
                  type="text"
                  id="state"
                  name="Estado"
                  placeholder="Digite ou selecione o Estado"
                  value={company?.state}
                  onChange={({ target: { value } }) => {
                    setCompany({ ...company, state: value });
                  }}
                />
              </label>
            </div>

            <div className="config-page-form-item button-wrapper">
              <button type="button" onClick={(e) => handleSubmit(e)}>
                Editar
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
});

export default CompanyInfo;
