import styled from 'styled-components';
import { fadeIn } from '../../../../../styles/animations';

export const Content = styled.div`
  width: 100%;
  margin: 2rem 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: ${fadeIn} 0.5s;
  @media (min-width: 1024px) {
    margin: 3rem 0 0 0;
  }

  .categ-modal-body {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 3px 6px 6px #767676;
    position: relative;
    padding: 1rem;
    z-index: 22;
    @media (min-width: 1024px) {
      width: 430px;
      height: auto;
    }
  }
  .product-filters {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    @media (min-width: 1024px) {
      max-width: unset;
      margin: 0 0 1rem;
    }
    .ant-input-affix-wrapper.table-search {
      width: 100%;
      max-width: 400px;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      padding: 5px 10px;
      @media (min-width: 1024px) {
        width: 45%;
      }
      input::placeholder {
        color: #a1a1a1;
      }
      .ant-input-suffix {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
`;
