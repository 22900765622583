import { message } from 'antd';
import { CategoryProps, ICompanyStore, IStoreProps } from '../../Types';
import api from '../api';

export default class StoreServices {
  static async getStores(companyId: string): Promise<IStoreProps[]> {
    const stores = await api
      .get(`/company/${companyId}/stores`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return stores;
  }

  static async postStore(
    companyId: string,
    newStore: {
      company_id: string;
      redirect_url: string;
      primary_color: string;
      secondary_color: string;
      company_logo: string;
      suiteshare_logo: string;
      redirect_phone: string;
      enabled: boolean;
      title: string;
      description: string;
      cover_image: string;
      position: number;
    },
  ): Promise<IStoreProps> {
    const storeData = await api
      .post(`/company/${companyId}/stores`, newStore)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return storeData;
  }

  static async getStoreCategories(
    companyId: string,
    storeId: string,
  ): Promise<CategoryProps[]> {
    const storeData = await api
      .get(`/company/${companyId}/stores/${storeId}/categories`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return storeData;
  }

  static async postStoreCover(
    companyId: string,
    storeId: string,
    file: FormData,
  ) {
    const storeData = await api
      .post(`/company/${companyId}/stores/${storeId}/upload-cover`, file)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return storeData;
  }

  static async postStoreLogo(
    companyId: string,
    storeId: string,
    image: object,
  ) {
    const storeLogo = await api
      .post(`/company/${companyId}/stores/${storeId}/upload`, image)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return storeLogo;
  }

  static async mergeStores(companyId: string): Promise<ICompanyStore[]> {
    const mergeData = await api
      .post(`/company/${companyId}/company-stores/merge`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });
    return mergeData;
  }

  static async editStore(
    companyId: string,
    store: IStoreProps,
  ): Promise<IStoreProps> {
    const storeData = await api
      .put(`/company/${companyId}/stores`, store)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });
    return storeData;
  }

  static async deleteStore(companyId: string, storeId: string) {
    const storeData = await api
      .delete(`/company/${companyId}/stores/${storeId}`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });
    return storeData;
  }
}
