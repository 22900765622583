import React, { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FiTrash2, FiEdit, FiSearch } from 'react-icons/fi';
import { Popconfirm, Table, Input, Skeleton } from 'antd';

import { ISpecification } from '../../../../../Types';

import SpecificationsServices from '../../../../../services/store/specifications';

import { GeneralStoreContext } from '../../../../../stores/GeneralStore';

import { useAuth } from '../../../../../hooks/auth';

import { Content, ActionBar, StyledForm, AddCategory } from './styles';

const SpecificationList: FC = observer(() => {
  const history = useHistory();
  const { user } = useAuth();

  const generalStore = useContext(GeneralStoreContext);

  const [isLoading, setIsLoading] = useState(true);
  const [specifications, setSpecifications] = useState<ISpecification[]>();

  const storeid = generalStore.currentCatalogId;

  useEffect(() => {
    SpecificationsServices.getSpecifications(user.company_id, storeid)
      .then((specificationsData) => {
        if (specificationsData) {
          setSpecifications(specificationsData);
        }
      })
      .finally(() => setIsLoading(false));
  }, [setIsLoading, user, storeid]);

  const FilterByNameInput = (
    <Input
      placeholder="Pesquisar variação"
      // value={searchFieldValue}
      className="table-search"
      suffix={<FiSearch />}
      // onChange={(e) => setSearchFieldValue(e.target.value)}
    />
  );

  const removeSpecification = (specification: ISpecification) => {
    SpecificationsServices.deleteSpecification(
      user.company_id,
      storeid,
      specification.id,
    )
      .catch()
      .finally(() => {
        setIsLoading(true);
        SpecificationsServices.getSpecifications(user.company_id, storeid)
          .then((specificationsData) => {
            if (specificationsData) {
              setSpecifications(specificationsData);
            }
          })
          .finally(() => setIsLoading(false));
      });
  };

  const columns = [
    {
      title: 'Variação',
      dataIndex: 'description',
    },
    {
      title: 'Opções de variação',
      render: (specificationData: ISpecification) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '0.5rem',
            fontSize: '0.8rem',
          }}
        >
          {specificationData.options?.map((option) => {
            return <p>{option.description} </p>;
          })}
        </div>
      ),
    },
    // {
    //   title: 'Produtos vínculados',
    //   dataIndex: 'products',
    // },
    {
      title: 'Editar',
      render: (specification: ISpecification) => (
        <button
          className="tableButton"
          type="button"
          onClick={() => {
            history.push(`/store/specifications/${specification.id}/edit`);
          }}
        >
          <FiEdit />
        </button>
      ),
      width: 100,
    },
    {
      title: 'Remover',
      render: (specification: ISpecification) => (
        <Popconfirm
          title="Deseja excluir?"
          onConfirm={() => removeSpecification(specification)}
          okText="Sim"
          cancelText="Não"
        >
          <button className="tableButton remove" type="button">
            <FiTrash2 />
          </button>
        </Popconfirm>
      ),
      width: 100,
    },
  ];

  return (
    <Content>
      <ActionBar className="action-bar">
        <div className="text-wrapper">
          <h1>Grade de Variações</h1>
        </div>
        <AddCategory
          onClick={() => history.push('/store/specifications/register')}
          title="Cadastrar nova variação"
        >
          + Nova Variação
        </AddCategory>
      </ActionBar>
      <div className="product-filters">{FilterByNameInput}</div>
      <StyledForm>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            dataSource={specifications}
            className="suiteshare-table"
            // locale={{ emptyText: <Empty callBack={openCategoryRegModal} /> }}
          />
        )}
      </StyledForm>
    </Content>
  );
});

export default SpecificationList;
