import { message, notification } from 'antd';
import api from '../api';
import { CompanyProps } from '../../Types';

export default class CompanyServices {
  static readonly getCompanyData = async (
    companySocialUrl: string,
  ): Promise<CompanyProps> => {
    const companyData = await api
      .get(`/companies/${companySocialUrl}`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });
    return companyData;
  };

  static readonly getCompanyDataById = async (
    companyID: string,
  ): Promise<CompanyProps> => {
    const companyData = await api
      .get(`/companies/${companyID}/data`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return companyData;
  };

  static async getCompanyBranches(companyId: string) {
    const companyBranchesData = await api
      .get(`/companies/${companyId}/branches`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return companyBranchesData;
  }

  static readonly postCompanyData = (
    companyData: CompanyProps,
  ): Promise<CompanyProps> => {
    return api
      .post(`/companies`, companyData)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          notification.error({
            message: `Erro ${error.response.status}`,
            description: `${error.response.data.error}`,
            className: 'error-message',
            duration: 2,
          });
          // eslint-disable-next-line no-else-return
        } else if (error.request) {
          message.error(`${error.request}`);
        } else {
          message.error(error.message);
        }
        return false;
      });
  };

  static readonly putCompanyData = (
    companyData: CompanyProps,
  ): Promise<CompanyProps> => {
    return api
      .put(`/companies`, companyData)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.error}`,
          );
        } else {
          message.error(error.message);
        }
      });
  };
}
