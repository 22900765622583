import { message } from 'antd';
import api from '../api';
import { CategoryProps, ProductProps } from '../../Types';

export default class CategoriesServices {
  static async getCategories(
    companyId: string,
    storeId?: string,
  ): Promise<CategoryProps[]> {
    const categoriesData = await api
      .get(`/company/${companyId}/store/${storeId}/categories`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });
    return categoriesData;
  }

  static async getCategoryProducts(
    companyId: string,
    storeId: string,
    categoryId: string,
  ): Promise<ProductProps[]> {
    const productsData = api
      .get(
        `/company/${companyId}/store/${storeId}/categories/${categoryId}/products`,
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return productsData;
  }

  static async postCategory(
    companyId: string,
    storeId: string,
    newCategory: {
      code?: string;
      enabled?: boolean;
      description?: string;
      company_id?: string;
      store_id: string | null;
    },
  ): Promise<CategoryProps> {
    const categoryData = await api
      .post(`/company/${companyId}/store/${storeId}/categories`, newCategory)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return categoryData;
  }

  static async postCategoryImage(
    companyId: string,
    storeId: string,
    categoryId: string,
    image: FormData,
  ) {
    const categoryImage = await api
      .post(
        `/company/${companyId}/store/${storeId}/categories/${categoryId}/upload`,
        image,
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return categoryImage;
  }

  static async putCategory(
    companyId: string,
    storeId: string,
    category: CategoryProps,
  ): Promise<CategoryProps> {
    const categoryData = await api
      .put(`/company/${companyId}/store/${storeId}/categories/`, category)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return categoryData;
  }

  static async deleteCategory(
    companyId: string,
    storeId: string,
    categoryId: string,
  ) {
    const categoryData = await api
      .delete(`/company/${companyId}/store/${storeId}/categories/${categoryId}`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.error}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return categoryData;
  }
}
