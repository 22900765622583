import React, { FC } from 'react';

interface IProps {
  color?: string;
  size?: string;
}

const SuiteshareLogo: FC<IProps> = ({ color, size }) => {
  const style = {
    width: 'auto',
    height: size || 'auto',
  };

  return (
    <svg
      width="1824"
      height="323"
      viewBox="0 0 1824 323"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M449.1 1.13992L492.99 1.24992C496.06 1.25992 498.55 3.75992 498.54 6.82992L498.43 52.9299C498.42 55.9999 495.92 58.4899 492.85 58.4799L448.96 58.3699C445.89 58.3599 443.4 55.8599 443.41 52.7899L443.52 6.68992C443.53 3.61992 446.03 1.12992 449.1 1.13992Z"
        fill={color || 'white'}
      />
      <path
        d="M97.2296 0.270023C110.78 0.300023 123.04 2.50002 134 6.86002C144.96 11.22 153.75 16.94 160.38 23.99C167 31.06 172.19 37.71 175.98 43.94C178.76 48.54 181.1 53.14 183 57.74C184.13 60.48 182.75 63.62 180.05 64.83L142.15 81.88C139.42 83.11 136.3 81.91 134.89 79.27C125.57 61.86 112.57 53.13 95.8896 53.08C86.3996 53.06 78.8696 55.62 73.3096 60.75C67.7396 65.88 64.9496 72.38 64.9296 80.23C64.9096 88.63 69.1596 96.16 77.6796 102.82C86.1996 109.49 96.4796 115.46 108.53 120.79C120.57 126.1 132.61 132.09 144.66 138.76C156.7 145.44 166.97 154.74 175.48 166.68C183.98 178.63 188.22 192.73 188.18 208.98C188.12 234.45 179.53 255.23 162.42 271.3C145.31 287.38 122.12 295.38 92.8596 295.31C78.4996 295.27 65.4896 293.01 53.8596 288.51C42.2096 284.01 32.8096 278.09 25.6496 270.76C18.4896 263.43 12.8196 256.44 8.6296 249.78C5.3896 244.64 2.6796 239.37 0.499597 233.99C-0.580403 231.32 0.679586 228.28 3.26959 227.03C15.3196 221.19 47.1396 205.78 47.1496 205.78C57.1096 229.92 73.4796 242.01 96.2396 242.07C106.81 242.1 115.08 239.55 121.05 234.41C127.02 229.28 130.02 222.1 130.04 212.89C130.06 204.23 125.88 196.49 117.5 189.7C109.11 182.91 98.8996 176.79 86.8596 171.33C74.8196 165.88 62.7696 159.75 50.7296 152.95C38.6896 146.15 28.4796 136.64 20.1096 124.42C11.7396 112.2 7.57959 97.7 7.61959 80.9C7.67959 56.78 15.8596 37.29 32.1496 22.43C48.4396 7.60002 70.1296 0.200023 97.2296 0.270023Z"
        fill={color || 'white'}
      />
      <path
        d="M292.59 295.83C244.76 295.71 220.92 264.74 221.08 202.9L221.36 88.76C221.37 85.69 223.87 83.2 226.94 83.21L270.51 83.32C273.58 83.33 276.07 85.83 276.06 88.9L275.76 209.38C275.73 220.48 279.08 229.48 285.8 236.36C292.52 243.25 300.64 246.7 310.15 246.72C321.25 246.75 330.18 243.15 336.93 235.89C343.69 228.64 347.08 219.34 347.11 207.97L347.41 89.07C347.42 86 349.92 83.51 352.99 83.52L396.56 83.63C399.63 83.64 402.12 86.14 402.11 89.21L401.62 285.38C401.61 288.45 399.11 290.94 396.04 290.93L354.73 290.83C351.07 290.82 348.1 287.84 348.11 284.18C348.12 278.51 341.5 275.43 337.21 279.14C333.88 282.02 330.11 284.71 325.9 287.19C316.11 292.98 305.01 295.86 292.59 295.83Z"
        fill={color || 'white'}
      />
      <path
        d="M442.83 291.24L443.33 89.31C443.34 86.24 445.84 83.75 448.91 83.76L492.49 83.87C495.56 83.88 498.05 86.38 498.04 89.45L497.54 291.38C497.53 294.45 495.03 296.94 491.96 296.93L448.38 296.82C445.31 296.81 442.82 294.31 442.83 291.24Z"
        fill={color || 'white'}
      />
      <path
        d="M661.89 188.54C661.97 157.09 671.61 131.02 690.83 110.32C710.03 89.62 734.31 79.31 763.64 79.38C795.35 79.46 820.02 89.37 837.68 109.1C855.34 128.83 864.13 155.88 864.04 190.22C864.03 192.67 864 194.83 863.94 196.71C863.84 199.73 861.38 202.1 858.36 202.1L723.89 201.77C720.43 201.76 717.82 204.89 718.35 208.31C720.04 219.37 725.1 228.63 733.51 236.08C743.53 244.96 756.2 249.42 771.53 249.46C791.59 249.51 807.35 242.48 818.83 228.36C820.67 226.09 824.06 225.83 826.38 227.6L853.91 248.58C856.31 250.41 856.78 253.77 855.08 256.26C848.97 265.19 839.6 273.87 826.94 282.29C812.11 292.16 793.34 297.07 770.62 297.02C737.85 296.94 711.52 286.77 691.62 266.5C671.72 246.23 661.81 220.24 661.89 188.54ZM726.03 166.1L802.43 166.29C805.87 166.3 808.51 163.19 807.98 159.79C806.45 150.08 802.33 142.07 795.61 135.75C787.43 128.07 777.01 124.21 764.32 124.18C751.63 124.15 741.32 128.09 733.37 136C726.98 142.36 722.73 150.08 720.6 159.18C719.78 162.69 722.43 166.09 726.03 166.1Z"
        fill={color || 'white'}
      />
      <path
        d="M957.71 297.48C938.94 297.43 923.03 293.63 909.97 286.06C898.61 279.48 889.69 271.76 883.24 262.89C881.59 260.63 882.08 257.45 884.18 255.59L908.61 233.99C910.88 231.98 914.26 232.24 916.39 234.4C928.58 246.81 943.19 253.04 960.2 253.09C968.13 253.11 974.34 251.48 978.84 248.18C983.34 244.89 985.6 240.34 985.62 234.52C985.63 228.97 982.27 224.14 975.55 220.03C968.82 215.91 960.63 212.2 951 208.87C941.36 205.55 931.79 201.56 922.29 196.9C912.79 192.26 904.68 185.23 897.97 175.84C891.25 166.44 887.92 154.88 887.95 141.13C888 122.37 895.17 107.46 909.47 96.4C923.77 85.33 941.74 79.83 963.41 79.88C993.66 79.96 1016.32 90.84 1031.39 112.51C1033.07 114.93 1032.41 118.3 1030.05 120.06L998.13 143.79C987.59 130.55 975.19 123.91 960.92 123.88C954.58 123.86 949.42 125.38 945.45 128.4C941.48 131.44 939.49 135.46 939.48 140.48C939.47 146.3 942.82 151.19 949.55 155.18C956.27 159.16 964.45 162.69 974.1 165.75C983.74 168.81 993.37 172.53 1003.01 176.92C1012.64 181.3 1020.82 188.13 1027.53 197.4C1034.25 206.67 1037.58 218.17 1037.55 231.91C1037.5 251.73 1029.94 267.63 1014.84 279.62C999.75 291.58 980.7 297.54 957.71 297.48Z"
        fill={color || 'white'}
      />
      <path
        d="M1178.72 81.81C1227.45 81.93 1251.75 113.5 1251.59 176.5L1251.3 292.91C1251.29 295.98 1248.79 298.47 1245.72 298.46L1201.52 298.35C1198.45 298.34 1195.96 295.84 1195.97 292.77L1196.28 169.9C1196.31 158.6 1192.9 149.44 1186.05 142.42C1179.2 135.4 1170.93 131.88 1161.23 131.85C1149.65 131.82 1140.35 135.5 1133.34 142.88C1126.32 150.27 1122.8 159.76 1122.77 171.34L1122.47 292.59C1122.46 295.66 1119.96 298.15 1116.89 298.14L1072.28 298.03C1069.21 298.02 1066.72 295.52 1066.73 292.45L1067.43 11.57C1067.44 8.50005 1069.94 6.01005 1073.01 6.02005L1117.62 6.13005C1120.69 6.14005 1123.18 8.64005 1123.17 11.71L1122.96 95.76C1122.95 100.62 1128.81 103.37 1132.33 100.03C1136.04 96.51 1140.32 93.3 1145.18 90.41C1154.89 84.66 1166.06 81.78 1178.72 81.81Z"
        fill={color || 'white'}
      />
      <path
        d="M1277.76 228.91C1277.81 208.57 1285.25 192.06 1300.08 179.42C1314.91 166.77 1334.35 160.48 1358.4 160.54C1377.16 160.59 1392.08 164.46 1403.16 172.15L1403.19 158.67C1403.21 149.42 1399.92 142.02 1393.33 136.45C1386.73 130.88 1377.5 128.08 1365.6 128.05C1349.37 128.01 1332.58 132.08 1315.22 140.25C1312.55 141.51 1309.35 140.56 1307.93 137.97L1292.46 109.84C1291.05 107.27 1291.81 103.99 1294.31 102.46C1303.51 96.8299 1314.43 91.9799 1327.05 87.9199C1341.72 83.2099 1356.59 80.8599 1371.66 80.8999C1402.84 80.9799 1425.02 88.2399 1438.2 102.68C1451.37 117.11 1457.93 137.02 1457.86 162.38L1458.23 293.75C1458.24 296.83 1455.74 299.34 1452.65 299.33L1414.54 299.24C1411.32 299.23 1408.56 296.91 1408.02 293.73L1407.63 291.48C1406.82 286.78 1401.39 284.33 1397.48 287.07C1386.58 294.73 1372.51 298.53 1355.26 298.49C1333.32 298.44 1314.91 292.12 1300.02 279.53C1285.13 266.92 1277.7 250.05 1277.76 228.91ZM1342.43 206.68C1335.8 212.08 1332.49 219.01 1332.47 227.46C1332.45 235.92 1335.86 242.79 1342.72 248.1C1349.58 253.41 1358.16 256.07 1368.47 256.09C1379.03 256.12 1387.63 253.56 1394.25 248.42C1400.86 243.29 1404.19 236.35 1404.21 227.64C1404.23 218.66 1400.93 211.57 1394.35 206.41C1387.75 201.24 1379.04 198.64 1368.2 198.61C1357.64 198.58 1349.04 201.27 1342.43 206.68Z"
        fill={color || 'white'}
      />
      <path
        d="M1609.09 87.2701L1608.98 133.09C1608.97 136.1 1606.57 138.46 1603.57 138.63C1568.9 140.64 1551.51 163.34 1551.4 206.74L1551.18 294C1551.17 297.07 1548.67 299.56 1545.6 299.55L1500.54 299.44C1497.47 299.43 1494.98 296.93 1494.99 293.86L1495.49 92.0701C1495.5 89.0001 1498 86.5101 1501.07 86.5201L1538.27 86.6101C1545.01 86.6301 1550.46 92.1001 1550.44 98.8401C1550.43 103.99 1556.74 106.33 1560.16 102.48C1563.89 98.2801 1568.26 94.5801 1573.26 91.3701C1581.99 85.7701 1591.88 82.5501 1602.93 81.7001C1606.23 81.4501 1609.1 83.9501 1609.09 87.2701Z"
        fill={color || 'white'}
      />
      <path
        d="M587.72 1.47989L539.71 1.35989C536.64 1.34989 534.14 3.83989 534.13 6.90989L533.42 291.46C533.41 294.53 535.9 297.03 538.97 297.04L586.98 297.16C590.05 297.17 592.55 294.68 592.56 291.61L592.91 149.97C592.92 146.9 595.42 144.41 598.49 144.42L646.5 144.54C649.57 144.55 652.07 142.06 652.08 138.99L652.2 90.9799C652.21 87.9099 649.72 85.4099 646.65 85.3999L598.64 85.2799C595.57 85.2699 593.08 82.7699 593.09 79.6999L593.27 7.04989C593.27 3.98989 590.79 1.48989 587.72 1.47989Z"
        fill={color || 'white'}
      />
      <path
        d="M1823.19 195.73C1823.36 126.89 1786.13 83.31 1720.39 83.15C1659.92 83 1616.38 130.39 1616.22 194C1616.22 194.75 1616.22 195.49 1616.23 196.23H1616.21C1616.07 251.45 1659.21 296.68 1713.68 299.8C1716.82 299.98 1719.45 297.36 1719.45 294.22L1719.56 249.28C1719.57 246.5 1717.53 244.06 1714.76 243.77C1695.93 241.82 1680.1 228.83 1674.39 210.94C1673.26 207.39 1676.04 203.76 1679.77 203.77L1823.02 204.13C1823.18 201.34 1823.18 198.53 1823.19 195.73ZM1685.42 164.37C1681.57 164.36 1678.77 160.48 1680.18 156.89C1686.48 140.88 1700.51 129.91 1719.88 129.96C1738.94 130.01 1753.89 140.4 1759.76 157.22C1761.01 160.8 1758.21 164.55 1754.42 164.54L1685.42 164.37Z"
        fill={color || 'white'}
      />
      <path
        d="M1780.75 241.9L1784.31 241.91C1805.72 241.96 1823.03 259.29 1822.98 280.61L1822.89 317.2C1822.88 320.27 1820.39 322.76 1817.31 322.75L1780.55 322.66C1759.14 322.61 1741.83 305.28 1741.88 283.96L1741.89 280.42C1741.95 259.09 1759.35 241.84 1780.75 241.9Z"
        fill="#00BF54"
      />
    </svg>
  );
};

export default SuiteshareLogo;
