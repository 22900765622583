import React, { FC, useState, useContext } from 'react';
import { observer } from 'mobx-react';
import { Input, message } from 'antd';
import Switch from '@material-ui/core/Switch';
import {
  LoadingOutlined,
  FacebookOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import { RiImageAddLine } from 'react-icons/ri';
import { FaPaintBrush } from 'react-icons/fa';

import filesize from 'filesize';

import { FileProps } from '../../../../../Types';

import { useAuth } from '../../../../../hooks/auth';

import { ConnectStoreContext } from '../../../../../stores/ConnectStore';

import Color from '../Color';

import {
  CustomizeWrapper,
  Title,
  Subtitle,
  ImageUploadWrapper,
  SwitchArabesque,
  Colors,
  ColorsWrapper,
  ColorsRow,
  SocialMedia,
} from './styles';
import { base64ToFile, resizeFile } from '../../../../../utils/resizeFile';
import ConnectEntitiesServices from '../../../../../services/connect/Entities';
import CompanyServices from '../../../../../services/company';
import { GeneralStoreContext } from '../../../../../stores/GeneralStore';

const solidColorsArray = [
  ['#E0E0E0', '#E0E0E0'],
  ['#000', '#000'],
  ['#3D509C', '#3D509C'],
  ['#76B6E1', '#76B6E1'],
  ['#285F41', '#285F41'],
  ['#24D570', '#24D570'],
  ['#FECC4E', '#FECC4E'],
  ['#EF4424', '#EF4424'],
];

const gradientColorsArray = [
  ['#36E481', '#53CCEA'],
  ['#B66FCF', '#5277D6'],
  ['#FD397A', '#6F42EB'],
  ['#F95EC7', '#FFA800'],
  ['#FC636B', '#FFB900'],
  ['#FBCBCB', '#E28291'],
];

const Customize: FC = observer(() => {
  const connectStore = useContext(ConnectStoreContext);
  const generalStore = useContext(GeneralStoreContext);

  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();

  const changeCurrentBackground = (colorArray: string[]) => {
    connectStore.setConnectOptions({
      ...connectStore.connectOptions,
      primary_color: colorArray[0],
      secondary_color: colorArray[1],
    });
  };

  const handleRoundedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentConnect = connectStore.connectOptions;

    currentConnect.rounded_header = event.target.checked;

    connectStore.setConnectOptions(currentConnect);
  };

  const handleUploadChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0]) {
        setUploadedFile(e.target.files[0]);
        connectStore.setLogoPreview(URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  const handleSubmit = () => {
    const currentConnect = connectStore.connectOptions;

    const currentCompanyData = generalStore.companyData;

    setLoading(true);

    CompanyServices.putCompanyData(currentCompanyData)
      .then(() => {
        ConnectEntitiesServices.putConnectData(
          user.company_id,
          currentConnect,
        ).then(async () => {
          if (uploadedFile) {
            const fileMapped: FileProps = {
              file: uploadedFile,
              name: uploadedFile.name,
              position: 0,
              path: URL.createObjectURL(uploadedFile),
              readableSize: filesize(uploadedFile.size),
            };
            const img = await resizeFile(fileMapped.file);
            const nFile = await base64ToFile(img, fileMapped.name);
            const image = new FormData();
            image.append('file', nFile, nFile.name);

            ConnectEntitiesServices.postConnectImage(
              user.company_id,
              connectStore.connectOptions.id,
              image,
            ).then(() => {
              ConnectEntitiesServices.getConnectData(user.company_id).then(
                (response) => {
                  connectStore.setConnectOptions(response);
                  setLoading(false);
                },
              );
            });
          }
        });
      })
      .finally(() => {
        message.success('Dados salvos com sucesso!');
        setLoading(false);
      });
  };

  return (
    <CustomizeWrapper>
      <Title>
        <h2>
          <span>
            <FaPaintBrush />
          </span>{' '}
          Personalize
        </h2>
      </Title>
      <Subtitle>
        <p>
          1.1
          <span>
            Escolha a imagem do logotipo da sua empresa ou use um logotipo
            personalizado para o Connect.
          </span>
        </p>
      </Subtitle>

      <ImageUploadWrapper>
        {uploadedFile ? (
          <img
            src={URL.createObjectURL(uploadedFile)}
            alt="Logotipo da empresa"
          />
        ) : (
          connectStore.connectOptions?.company_logo && (
            <img
              src={`${process.env.REACT_APP_BUCKET_ASSETS_URL}${encodeURI(
                connectStore.connectOptions?.company_logo,
              )}`}
              alt="logo"
            />
          )
        )}
        <label htmlFor="logo-upload">
          {loading ? <LoadingOutlined /> : <RiImageAddLine size={20} />}
          {connectStore.connectOptions?.company_logo || uploadedFile
            ? 'Trocar logotipo'
            : 'Carregar logotipo'}
          <input
            type="file"
            name=""
            id="logo-upload"
            accept=".jpg, .png"
            disabled={loading}
            onChange={(e) => handleUploadChange(e)}
          />
        </label>
      </ImageUploadWrapper>

      <Subtitle>
        <p>
          1.2
          <span>Ainda não adicionou suas redes sociais?</span>
        </p>
      </Subtitle>

      <SocialMedia>
        <Input
          value={generalStore.companyData?.facebook}
          onChange={(e) => {
            generalStore.setCompanyData({
              ...generalStore.companyData,
              facebook: e.target.value,
            });
          }}
          prefix={
            <>
              <FacebookOutlined />{' '}
              <p style={{ margin: '0 0 0 0.5rem' }}>facebook.com/</p>
            </>
          }
        />
        <Input
          value={generalStore.companyData?.instagram}
          onChange={(e) => {
            generalStore.setCompanyData({
              ...generalStore.companyData,
              instagram: e.target.value,
            });
          }}
          prefix={
            <>
              <InstagramOutlined />{' '}
              <p style={{ margin: '0 0 0 0.5rem' }}>instagram.com/</p>
            </>
          }
        />
        <Input
          value={generalStore.companyData?.youtube}
          onChange={(e) => {
            generalStore.setCompanyData({
              ...generalStore.companyData,
              youtube: e.target.value,
            });
          }}
          prefix={
            <>
              <YoutubeOutlined />{' '}
              <p style={{ margin: '0 0 0 0.5rem' }}>youtube.com/</p>
            </>
          }
        />
        <Input
          value={generalStore.companyData?.twitter}
          onChange={(e) => {
            generalStore.setCompanyData({
              ...generalStore.companyData,
              twitter: e.target.value,
            });
          }}
          prefix={
            <>
              <TwitterOutlined />{' '}
              <p style={{ margin: '0 0 0 0.5rem' }}>twitter.com/</p>
            </>
          }
        />
      </SocialMedia>

      <Subtitle>
        <p>
          1.3
          <span>Personalize o background do seu Connect.</span>
        </p>
      </Subtitle>

      <SwitchArabesque>
        <Switch
          checked={connectStore.connectOptions?.rounded_header}
          onChange={handleRoundedChange}
        />
        <p>Habilitar bordas arredondadas</p>
      </SwitchArabesque>

      <Colors>
        <ColorsWrapper>
          <p>Cor sólida</p>
          <ColorsRow>
            {solidColorsArray.map((color) => {
              return (
                <Color
                  key={color[0] + color[1]}
                  background={color}
                  changeBackground={changeCurrentBackground}
                />
              );
            })}
          </ColorsRow>
        </ColorsWrapper>
        <ColorsWrapper>
          <p>Gradiente</p>
          <ColorsRow>
            {gradientColorsArray.map((color) => {
              return (
                <Color
                  key={color[0] + color[1]}
                  background={color}
                  changeBackground={changeCurrentBackground}
                />
              );
            })}
          </ColorsRow>
        </ColorsWrapper>
      </Colors>
      <div className="save-content-bar">
        <button type="submit" onClick={handleSubmit}>
          Salvar
        </button>
      </div>
    </CustomizeWrapper>
  );
});

export default Customize;
