import { message } from 'antd';
import {
  ICompanyProducts,
  ProductProps,
  IProductSpecification,
  IProductSpecificationOption,
} from '../../Types';
import api from '../api';

export default class ProductsServices {
  static async getProducts(
    companyId: string,
    storeId?: string,
  ): Promise<ProductProps[]> {
    const productsData = await api
      .get(`/company/${companyId}/store/${storeId}/products`)
      .then((res) => res.data)
      .catch((err) => {
        message.error(err.message);
      });

    return productsData;
  }

  static async getProductData(
    companyId: string,
    storeId: string,
    productId: string,
  ): Promise<ProductProps> {
    const productData = await api
      .get(`/company/${companyId}/store/${storeId}/products/${productId}`)
      .then((res) => res.data)
      .catch((err) => {
        message.error(err.message);
      });

    return productData;
  }

  static async editProduct(
    companyId: string,
    storeId: string,
    product: object,
  ) {
    const editedProduct = await api
      .put(`/company/${companyId}/store/${storeId}/products/`, product)
      .catch((err) => {
        message.error(err.message);
      });

    return editedProduct;
  }

  static async deleteProduct(
    productId: string,
    storeId: string,
    companyId: string,
  ) {
    const deletedProduct = await api
      .delete(`/company/${companyId}/store/${storeId}/products/${productId}`)
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          message.error(error.message);
        }
      });

    return deletedProduct;
  }

  static async postNewProduct(
    companyId: string,
    storeId: string,
    product: object,
  ): Promise<ProductProps> {
    const newProduct = await api
      .post(`/company/${companyId}/store/${storeId}/products`, product)
      .then((res) => res.data)
      .catch((err) => message.error(err.message));

    return newProduct;
  }

  static async postProductImage(
    companyId: string,
    storeId: string,
    productId: string,
    image: object,
  ) {
    const productImage = api
      .post(
        `/company/${companyId}}/store/${storeId}/products/${productId}/upload`,
        image,
      )
      .catch((err) => message.error(err.message));
    return productImage;
  }

  static async postProductImageWithPosition(
    companyId: string,
    storeId: string,
    productId: string,
    image: object,
    position: number,
  ) {
    const productImage = api
      .post(
        `/company/${companyId}/store/${storeId}/products/${productId}/upload/${position}`,
        image,
      )
      .catch((err) => message.error(err.message));
    return productImage;
  }

  static async editProductImagePosition(
    companyId: string,
    storeId: string,
    productId: string,
    productImageId: string,
    position: number,
  ) {
    const editedProduct = await api
      .put(
        `/company/${companyId}/store/${storeId}/products/${productId}/images`,
        {
          id: productImageId,
          position,
        },
      )
      .catch((err) => {
        message.error(err.message);
      });

    return editedProduct;
  }

  static async deleteImage(
    companyId: string,
    storeId: string,
    productId: string,
    imageId: string,
  ) {
    const deletedProduct = await api
      .delete(
        `/company/${companyId}/store/${storeId}/products/${productId}/image/${imageId}`,
      )
      .catch((err) => message.error(err.message));

    return deletedProduct;
  }

  static async getChildProducts(companyId: string, storeId: string) {
    const productsData = await api
      .get(`/company/${companyId}/store/${storeId}/company-products`)
      .then((res) => res.data)
      .catch((err) => {
        message.error(err.message);
      });

    return productsData;
  }

  static async mergeChildProducts(companyId: string, storeId: string) {
    const productsData = await api
      .post(`/company/${companyId}/store/${storeId}/company-products/merge`)
      .then((res) => res.data)
      .catch((err) => {
        message.error(err.message);
      });

    return productsData;
  }

  static async editChildProduct(
    storeId: string,
    companyProductId: string,
    enabled: boolean,
  ): Promise<ICompanyProducts> {
    const companyProduct = {
      id: companyProductId,
      enabled,
    };
    return api
      .put(
        `/company/${companyProductId}/store/${storeId}/company-products/`,
        companyProduct,
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        message.error(err.message);
        return false;
      });
  }

  static async postProductSpecification(
    companyId: string,
    storeId: string,
    productId: string,
    specificationId: string,
    enabled: boolean,
  ): Promise<IProductSpecification> {
    const productSpecification = await api
      .post(
        `/company/${companyId}/store/${storeId}/products/${productId}/specifications`,
        {
          company_id: companyId,
          product_id: productId,
          specification_id: specificationId,
          enabled,
        },
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return productSpecification;
  }

  static async putProductSpecification(
    companyId: string,
    storeId: string,
    productId: string,
    productSpecification: IProductSpecification,
  ): Promise<IProductSpecification> {
    const productSpecificationData = await api
      .put(
        `/company/${companyId}/store/${storeId}/products/${productId}/specifications/${productSpecification.id}`,
        {
          company_id: companyId,
          product_id: productId,
          specification_id: productSpecification.specification_id,
          enabled: productSpecification.enabled,
        },
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return productSpecificationData;
  }

  static async deleteProductSpecification(
    companyId: string,
    storeId: string,
    productId: string,
    productSpecification: IProductSpecification,
  ): Promise<JSON> {
    const productSpecificationData = await api
      .delete(
        `/company/${companyId}/store/${storeId}/products/${productId}/specifications/${productSpecification.id}`,
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return productSpecificationData;
  }

  static async postProductSpecificationOption(
    companyId: string,
    storeId: string,
    productId: string,
    productSpecificationOption: {
      product_specification_id: string;
      specification_option_id: string;
      sku: string;
      position?: number;
      enabled: boolean;
    },
  ): Promise<IProductSpecificationOption> {
    const productSpecificationOptionData = await api
      .post(
        `/company/${companyId}/store/${storeId}/products/${productId}/specifications/${productSpecificationOption.product_specification_id}/options`,
        productSpecificationOption,
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return productSpecificationOptionData;
  }

  static async putProductSpecificationOption(
    companyId: string,
    storeId: string,
    productId: string,
    productSpecificationId: string,
    productSpecificationOption: {
      id: string;
      sku: string;
      position?: number;
      enabled: boolean;
    },
  ): Promise<IProductSpecificationOption> {
    const productSpecificationOptionData = await api
      .put(
        `/company/${companyId}/store/${storeId}/products/${productId}/specifications/${productSpecificationId}/options/${productSpecificationOption.id}`,
        productSpecificationOption,
      )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          message.error(
            `Erro ${error.response.status}. ${error.response.data.message}`,
          );
        } else {
          message.error(error.message);
        }
      });

    return productSpecificationOptionData;
  }
}
