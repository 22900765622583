/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/self-closing-comp */
import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Form, Input, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MaskedInput } from 'antd-mask-input';
import { FiMail, FiUser, FiLock, FiBriefcase, FiPhone } from 'react-icons/fi';

import { GeneralStoreContext } from '../../../stores/GeneralStore';

import UserServices from '../../../services/user';
import CompanyServices from '../../../services/company';
import { getStoreData } from '../../../services/store/Configuration';

import { useAuth } from '../../../hooks/auth';
import useQuery from '../../../hooks/useQuery';
import Paths from '../../../routes/Paths';

import SideImage from '../../../components/_Sessions/SideImage';

import storeTitle from '../../../assets/logos/products/store-title.png';
import connectTitle from '../../../assets/logos/products/connect.png';

const prefixOptions = ['55', '49'];

interface IUrlParams {
  app: string;
}

const SignUp: React.FC = observer(() => {
  const query = useQuery();

  const generalStore = useContext(GeneralStoreContext);

  const { login } = useAuth();
  const { Option } = Select;
  const history = useHistory();
  const { app } = useParams<IUrlParams>();

  const [socialUrl, setSocialUrl] = useState('');
  const [numberPrefix, setNumberPrefix] = useState<string>(prefixOptions[0]);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [parentCompanyLogo, setParentCompanyLogo] = useState<string | null>();
  const [parentCompanyName, setParentCompanyName] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  const parentId = query.get('parent-id');

  useEffect(() => {
    generalStore.changeCurrApp(app);

    if (parentId) {
      setIsLoading(true);
      CompanyServices.getCompanyDataById(parentId).then((response) => {
        setParentCompanyName(response.name);
        getStoreData(response)
          .then((storeData) => {
            if (storeData) {
              setParentCompanyLogo(storeData.company_logo);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
  }, [app, generalStore, parentId]);

  const handleSocialUrl = (companyName: string) => {
    return companyName
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove acentos
      .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
      .replace(/[-][-]+/g, '-') // Substitui multiplos hífens por um único hífen
      .replace(/(^-+|-+$)/, ''); // Remove hífens extras do final ou do inicio da string;
  };

  const handleCompanyChange = (e: any) => {
    setSocialUrl(handleSocialUrl(e.target.value));
  };

  const handlePrefixChange = (selectedItem: string) => {
    setNumberPrefix(selectedItem);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const socialUrlPreview = () => {
    return app === 'connect' ? (
      <>
        <p>O link para seu Connect será:</p>
        <span className="url-preview">https://suitesha.re/{socialUrl}</span>
      </>
    ) : (
      <>
        <p>O link para sua Store será:</p>
        <span className="url-preview">
          https://store.suitesha.re/{socialUrl}
        </span>
      </>
    );
  };

  const onFinish = async (values: {
    company: string;
    name: string;
    email: string;
    password: string;
  }) => {
    setLoggingIn(true);
    const companyData = {
      id: '',
      name: values.company,
      social_url: handleSocialUrl(values.company),
      parent_company_id: parentId,
      phone: '',
      zipcode: '',
      address: '',
      address_number: '',
      city: '',
      state: '',
      country: '',
      logo: '',
      instagram: '',
      facebook: '',
      twitter: '',
      youtube: '',
      plan_id: '4966b835-6821-4342-a223-39a20bb2f5c6',
    };

    await CompanyServices.postCompanyData(companyData).then(
      async (companyResponse) => {
        if (companyResponse) {
          const usersData = {
            id: '',
            name: values.name,
            email: values.email.toLowerCase().trim(),
            password: values.password.trim(),
            phone: numberPrefix + phoneNumber,
            company: companyResponse,
            company_id: companyResponse.id,
            avatar_url: '',
          };

          await UserServices.postUserData(usersData).then(
            async (userResponse) => {
              if (userResponse) {
                await login({
                  email: usersData.email,
                  password: usersData.password,
                }).finally(() => {
                  history.push(
                    app === 'connect' ? '/connect/links' : Paths.products.url,
                  );
                });
              }
              setLoggingIn(false);
            },
          );

          setLoggingIn(false);
          return companyResponse;
        }
        setLoggingIn(false);
      },
    );
  };

  return isLoading ? (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoadingOutlined style={{ textAlign: 'center', fontSize: '6rem' }} />
    </div>
  ) : (
    <>
      <SideImage
        app={app}
        parentLogo={parentCompanyLogo}
        parentName={parentCompanyName}
      />
      <div className="form-wrapper">
        <img
          src={app === 'connect' ? connectTitle : storeTitle}
          alt="Suiteshare"
        />
        <Form className="suiteshare-form" onFinish={onFinish}>
          <h1>Crie sua conta grátis!</h1>
          <Form.Item
            name="company"
            rules={[
              {
                required: true,
                message: 'Por favor, insira o nome da sua empresa',
              },
            ]}
          >
            <Input
              prefix={<FiBriefcase />}
              placeholder="Empresa"
              onChange={handleCompanyChange}
            />
          </Form.Item>
          {socialUrl ? socialUrlPreview() : ''}
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Por favor, insira seu nome',
              },
            ]}
          >
            <Input prefix={<FiUser />} placeholder="Nome" />
          </Form.Item>
          <Form.Item name="phone">
            <Select
              defaultValue="55"
              value={numberPrefix}
              onChange={(e) => handlePrefixChange(e)}
            >
              {prefixOptions.map((option) => {
                return (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
            <MaskedInput
              className="phone-input"
              prefix={<FiPhone />}
              placeholder="Telefone"
              mask="(11) 11111-1111"
              onChange={(e) => handlePhoneChange(e)}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Por favor, insira um e-mail válido',
              },
            ]}
          >
            <Input prefix={<FiMail />} placeholder="E-mail" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Por favor, crie uma senha',
              },
            ]}
          >
            <Input.Password prefix={<FiLock />} placeholder="Senha" />
          </Form.Item>
          <button
            className={`session-button ${app === 'connect' ? app : ''}`}
            type="submit"
          >
            {loggingIn ? <LoadingOutlined /> : 'CADASTRAR'}
          </button>
        </Form>
        <div className="bellow-link">
          <p>Já possui uma conta?</p>
          {app === 'connect' ? (
            <Link to="/connect/login">Clique aqui</Link>
          ) : (
            <Link to="/store/login">Clique aqui</Link>
          )}
        </div>
        <script
          type="text/javascript"
          async
          src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/d835fb96-555e-48a7-98b0-0965a140acd5-loader.js"
        ></script>
      </div>
    </>
  );
});

export default SignUp;
