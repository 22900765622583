import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { GeneralStoreContext } from '../../../../../stores/GeneralStore';

import { useAuth } from '../../../../../hooks/auth';

import ParentProductList from './ParentView';

import ChildProductList from './ChildView';

const ProductsList: React.FC = observer(() => {
  const generalStore = useContext(GeneralStoreContext);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      if (user.company.parent_company_id) {
        generalStore.setIsChild(true);
      } else {
        generalStore.setIsChild(false);
      }
    }
  }, [user, generalStore]);

  return !generalStore.isChild ? <ParentProductList /> : <ChildProductList />;
});

export default ProductsList;
