import { keyframes } from 'styled-components';

export const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const appearFromUp = keyframes`
  from {
    transform: translateY(-150px);
  }

  to {
    transform: translateY(0);
  }
`;

export const appearFromDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const streach = keyframes`
  from {
    opacity: 0;
    transform: scaleY(0.5);
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;
