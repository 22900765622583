import React from 'react';
import { Empty } from 'antd';
import emptyCategories from '../../assets/empty/new-empty-categories.png';

interface IProps {
  callBack: (value: boolean) => void;
}

function SuiteshareEmpty(props: IProps) {
  const { callBack } = props;

  return (
    <Empty
      image={emptyCategories}
      description="Você ainda não possui categorias cadastradas."
    >
      <button
        className="primary-button store"
        type="button"
        onClick={() => callBack(true)}
        style={{ backgroundColor: 'FFB822' }}
      >
        Cadastrar categoria
      </button>
    </Empty>
  );
}

export default SuiteshareEmpty;
