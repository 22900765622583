import React, { createContext, useCallback, useState, useContext } from 'react';
import { observer } from 'mobx-react';

import api from '../services/api';
import { IUser } from '../Types';
import { GeneralStoreContext } from '../stores/GeneralStore';

interface AuthState {
  token: string;
  user: IUser;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: IUser;
  login(credentials: SignInCredentials): Promise<void>;
  logout(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = observer(({ children }) => {
  const generalStore = useContext(GeneralStoreContext);
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Suiteshare:token');
    const user = localStorage.getItem('@Suiteshare:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const login = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem('@Suiteshare:token', token);
    localStorage.setItem('@Suiteshare:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('@Suiteshare:token');
    localStorage.removeItem('@Suiteshare:user');

    generalStore.setIsChild(false);

    setData({} as AuthState);
  }, [generalStore]);

  return (
    <AuthContext.Provider value={{ user: data.user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
});

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
