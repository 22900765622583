import React, { FC, useEffect, useState } from 'react';
import { GoFileCode, GoMail } from 'react-icons/go';
import { FiUser } from 'react-icons/fi';
import { MaskedInput } from 'antd-mask-input';
import { Select } from 'antd';

import SellersServices from '../../services/store/Sellers';
import { ISeller } from '../../Types/index';

import { useAuth } from '../../hooks/auth';

import { ReactComponent as HeadSetIcon } from '../../assets/icons/svg-icons/headphones.svg';
import WhatsIcon from '../../assets/whatsapp.png';
import './styles.scss';

interface IProps {
  sellerData?: ISeller;
  editSeller: (seller: ISeller) => void;
  updateSellers: (sellers: ISeller[]) => void;
}

const prefixOptions = ['55'];

const SellersRegistration: FC<IProps> = (props) => {
  const { sellerData, editSeller, updateSellers } = props;
  const { user } = useAuth();
  const { Option } = Select;
  const [numberPrefix, setNumberPrefix] = useState('55');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [newSeller, setNewSeller] = useState<ISeller>({
    id: '',
    company_id: user.company_id,
    company: user.company,
    phone: '',
    name: '',
    email: '',
    code: '',
  });

  useEffect(() => {
    if (sellerData) {
      const currentSeller = sellerData;
      const cleanNumber = currentSeller.phone.replace(/^\d{2}/, '');
      setPhoneNumber(cleanNumber);
      currentSeller.phone = cleanNumber;
      setPhoneNumber(currentSeller.phone);
      setNewSeller(currentSeller);
    }
  }, [sellerData]);

  const handlePrefixChange = (selectedItem: string) => {
    setNumberPrefix(selectedItem);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    if (newSeller.name && newSeller.email && phoneNumber) {
      const currentSeller = newSeller;
      currentSeller.phone = numberPrefix + phoneNumber.replace(/[^0-9]/g, '');

      if (sellerData) {
        await editSeller(currentSeller);
      } else {
        await SellersServices.postSeller(currentSeller).then(
          async (postResponse) => {
            if (postResponse) {
              await SellersServices.getSellers(user.company_id).then(
                (sellersArray) => {
                  updateSellers(sellersArray);
                },
              );
            }
          },
        );
      }
    }
  };

  return (
    <div className="seller-registration">
      <div className="seller-registration__title">
        <div className="title__main">
          <HeadSetIcon />
          <h1>{sellerData ? 'Editar vendedor' : 'Cadastrar Vendedor'}</h1>
        </div>
        <div className="title__sub">
          <p>Informe os dados do seu vendedor abaixo:</p>
        </div>
      </div>
      <form className="seller-registration__form">
        <label htmlFor="seller-name" className="form__label">
          <FiUser />
          <input
            placeholder="Nome do Vendedor"
            type="text"
            value={newSeller?.name}
            onChange={({ target: { value } }) => {
              setNewSeller({ ...newSeller, name: value });
            }}
            required
          />
        </label>
        <label htmlFor="seller-phone" className="form__label">
          <img src={WhatsIcon} alt="Whatsapp icon" />
          <Select
            defaultValue="55"
            value={numberPrefix}
            onChange={(e) => handlePrefixChange(e)}
          >
            {prefixOptions.map((option) => {
              return (
                <Option key={option} value={option}>
                  {option}
                </Option>
              );
            })}
          </Select>
          <MaskedInput
            className="phone-input"
            value={phoneNumber}
            placeholder="(00) 00000-0000"
            mask="(11) 11111-1111"
            onChange={(e) => handlePhoneChange(e)}
          />
        </label>
        <label htmlFor="seller-email" className="form__label">
          <GoMail />
          <input
            placeholder="E-mail"
            type="email"
            value={newSeller?.email}
            onChange={({ target: { value } }) => {
              setNewSeller({ ...newSeller, email: value });
            }}
            required
          />
        </label>
        <label htmlFor="seller-code" className="form__label">
          <GoFileCode />
          <input
            placeholder="Código do Vendedor"
            value={newSeller?.code}
            onChange={({ target: { value } }) => {
              setNewSeller({ ...newSeller, code: value });
            }}
            required
          />
        </label>
        <div className="form__button">
          <button
            type="submit"
            className="button__submit"
            onClick={(e) => handleSubmit(e)}
          >
            {sellerData ? 'Editar vendedor' : 'Cadastrar Vendedor'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SellersRegistration;
