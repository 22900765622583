import styled from 'styled-components';

export const LinkTitle = styled.input`
  width: 100%;
  margin: 0 !important;
`;

export const LinkUrl = styled.input`
  width: 100%;
`;

export const RemoveButton = styled.button`
  color: #a5a5a5;
  transition: color 0.3s ease;
  outline: none;
  border: none;
  background-color: unset;
  &:active {
    outline: none;
  }
  &:hover {
    color: #f27878;
  }
`;

export const LinkGroupWrapper = styled.div`
  width: 100%;
  cursor: grabbing !important;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 1rem 0;
  padding: 0.5rem;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  &::before {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    left: 0rem;
    background: transparent;
    transition: all 0.3s ease;
  }
  &.focused {
    &::before {
      background: #fd397a;
    }
  }
  .input-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .validation-tools {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;

      .tool-tip {
        position: absolute;
        right: 1rem;
        top: 1rem;
        color: #f27878;
        background: none;
        border: none;
      }
      .error-message {
        position: absolute;
        left: 0;
        bottom: -2rem;
        color: #f27878;
        font-size: 0.7rem;
        padding: 0 0.3rem;
      }
    }
  }
  .row-wrapper {
    width: 100%;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 4fr 1fr 0.3fr;
    label {
      margin: 0 0 0 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        margin: 0 0.5rem 0 0;
      }
    }

    &.action-bar {
      margin: 1rem 0 0.5rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        margin: 0 0 0 1.5rem;
        svg {
          font-size: 1.2rem;
        }
        &.drag-and-drop {
          position: absolute;
          left: 0;
          color: #858585;
          border: none;
          background: unset;
          transition: color 0.3s ease;
          &:hover,
          &:active {
            color: #fd397a;
          }
        }
        &.highlight-button {
          border: none;
          background: none;
          color: #fd397a;
          svg {
            font-size: 1.5rem;
          }
          &:disabled {
            color: #888;
          }
        }
        &.save-button {
          padding: 0.5rem 1rem;
          border: none;
          background: #fd397a;
          border-radius: 4px;
          color: #fff;
        }
        &.edit-button {
          padding: 0.5rem 1rem;
          border: 1px solid #fd397a;
          background: #fff;
          color: #fd397a;
        }
        &.switch-wrapper {
          height: 25px;
          width: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 0;
          align-self: flex-end;
          margin-left: 1.5rem;
          border: none;
          background-color: unset;
          .ant-switch {
            margin: 0 !important;
          }
          p {
            font-size: 0.7rem;
            margin: 0;
          }
        }
      }
    }
  }
  ${LinkTitle}, ${LinkUrl} {
    margin: 0.5rem 0 0;
    padding: 0.5rem 1rem;
    border: 1px solid #f5f5f5;
    background: transparent;
    border-radius: 2px;
    transition: border-color 0.3s ease;
    z-index: 1;
    position: relative;
  }
`;
