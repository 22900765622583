import styled from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #fff;
  border-radius: 5px;
  border: solid 1px #afafaf;
  padding: 16px;
  width: 100%;
  color: #232323;

  display: flex;
  align-items: center;

  ${(props) =>
    props.isFocused &&
    `
      color: #00BF54;
      border-color: #00BF54;
    `}

  input {
    border: none;
  }
`;
