import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import faviconConnect from '../assets/favicons/favicon-connect.png';
import faviconStore from '../assets/favicons/favicon-store.png';

export default function useHeadUpdate() {
  const history = useHistory();

  const capitalizeWords = (word: string) => {
    let wordToCapitalize;
    if (word) {
      wordToCapitalize = word.split('');

      const firstCharacter = word.split('')[0].toUpperCase();

      wordToCapitalize.shift();
      wordToCapitalize.unshift(firstCharacter);

      return wordToCapitalize.join('');
    }
    return wordToCapitalize;
  };

  useEffect(() => {
    const title = history.location.pathname.split('/');

    if (history.location.pathname !== '/') {
      const product = capitalizeWords(title[1]);
      const subpage = capitalizeWords(title[2]);

      const favicon: any = document.getElementById('favicon');

      if (favicon) {
        if (product === 'Connect') {
          favicon.href = faviconConnect;
        } else {
          favicon.href = faviconStore;
        }
      }
      document.title = `${product} | ${subpage || 'Store'}`;
    }
  });
}
