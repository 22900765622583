import React, { FC, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { BsPencilSquare } from 'react-icons/bs';
import { FaPaintBrush } from 'react-icons/fa';
import { Tag, Folder, Tool, Plus, Share2, Server, Book } from 'react-feather';

import { GeneralStoreContext } from '../../../stores/GeneralStore';

import { ReactComponent as HeadSetIcon } from '../../../assets/icons/svg-icons/headphones.svg';

interface IProps {
  app: string;
  isPlusButtonClicked: boolean;
  handlePlusButton: (value: boolean) => void;
}

const HeaderNavBar: FC<IProps> = observer((props) => {
  const { app, isPlusButtonClicked, handlePlusButton } = props;
  const generalStore = useContext(GeneralStoreContext);

  const render = () => {
    switch (app) {
      case 'store':
        return (
          <>
            {!generalStore.isChild ? (
              <NavLink to="/store/catalogs" activeClassName="active">
                <i>
                  <Book />
                </i>
                <span>Catálogos</span>
              </NavLink>
            ) : (
              <div />
            )}
            <NavLink to="/store/products">
              <i>
                <Tag />
              </i>
              <span>Produtos</span>
            </NavLink>
            <NavLink
              to="/store/categories"
              style={{ display: generalStore.isChild ? 'none' : 'flex' }}
            >
              <i>
                <Folder />
              </i>
              <span className="span-menu-desk">Categorias</span>
              <span className="span-menu-mob">Categ.</span>
            </NavLink>
            <NavLink
              to="/store/specifications"
              style={{ display: generalStore.isChild ? 'none' : 'flex' }}
            >
              <i>
                <Server />
              </i>
              <span className="span-menu-desk">Variações</span>
              <span className="span-menu-mob">Var.</span>
            </NavLink>
            <NavLink to="/store/sellers">
              <i>
                <HeadSetIcon />
              </i>
              <span className="span-menu-desk">Vendedores</span>
              <span className="span-menu-mob">Vendedores</span>
            </NavLink>
            <button
              className={`plus-button ${isPlusButtonClicked ? 'is-open' : ''}`}
              onClick={() => handlePlusButton(!isPlusButtonClicked)}
              type="button"
            >
              <i>
                <Plus />
              </i>
            </button>
            <NavLink to="/store/config">
              <i>
                <Tool />
              </i>
              <span className="span-menu-desk">Configurações</span>
              <span className="span-menu-mob">Config.</span>
            </NavLink>
          </>
        );
      case 'connect':
        return (
          <>
            <NavLink
              to="/connect/links"
              activeClassName="active"
              className="connect"
            >
              <i>
                <BsPencilSquare />
              </i>
              <span className="span-menu-desk">Gerenciar links</span>
              <span className="span-menu-mob">Gerenciar</span>
            </NavLink>
            <NavLink to="/connect/customize" className="connect">
              <i>
                <FaPaintBrush />
              </i>
              <span>Personalizar </span>
            </NavLink>
            <NavLink to="/connect/share" className="connect">
              <i>
                <Share2 style={{ width: '1rem' }} />
              </i>
              <span className="span-menu-desk">Compartilhar link</span>
              <span className="span-menu-mob">Compartilhar</span>
            </NavLink>
          </>
        );
      case 'suiteshare':
        return <div />;
      default:
        return <div />;
    }
  };

  return render();
});

export default HeaderNavBar;
