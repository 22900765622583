import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { message, Skeleton } from 'antd';
import { FaPlus } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';

import { IStoreProps } from '../../../../../Types';
import { useAuth } from '../../../../../hooks/auth';

import CompanyServices from '../../../../../services/company';
import StoreServices from '../../../../../services/store/stores';

import { GeneralStoreContext } from '../../../../../stores/GeneralStore';

import CatalogRegister from '../../../../../components/_Store/CatalogRegister';
import CardContainer from './CardContainer';

import './styles.scss';

const CatalogsList: FC = observer(() => {
  const generalStore = useContext(GeneralStoreContext);
  const { user } = useAuth();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editCatalogId, setEditCatalogId] = useState('');
  const [catalogLimit, setCatalogLimit] = useState(1);

  useEffect(() => {
    if (!user.company.plan_id) {
      setCatalogLimit(1);
    }

    if (user.company.plan) {
      setCatalogLimit(user.company.plan.catalogs_limit);
    }

    if (user.company.parent_company_id) {
      history.push('/store/products');
      message.warning('Você não tem permissão para acessar esta página');
    }
    CompanyServices.getCompanyData(user.company.social_url)
      .then((response) => {
        if (response && response.stores) {
          generalStore.setCatalogsList(response.stores);
        }
      })
      .finally(() => setIsLoading(false));
  }, [user.company, generalStore, history]);

  const askForMoreCatalogs = () => {
    const newWindow = window.open('', '_blank');
    const url = 'https://whts.co/store-trial';

    if (newWindow) {
      newWindow.location.href = url;
    } else {
      window.location.href = url;
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (editCatalogId) setEditCatalogId('');

    CompanyServices.getCompanyData(user.company.social_url).then((response) => {
      if (response && response.stores) {
        generalStore.setCatalogsList(response.stores);
      }
    });
  };

  const editCatalog = (storeId: string): void => {
    setEditCatalogId(storeId);

    setIsModalOpen(true);
  };

  const handleCatalogEnable = (value: boolean, index: number) => {
    if (generalStore.catalogsList) {
      const currStores = generalStore.catalogsList;
      const currStore = currStores[index];
      currStore.enabled = value;

      StoreServices.editStore(user.company_id, currStore).then((response) => {
        currStores[index] = response;
        generalStore.setCatalogsList([...currStores]);
      });
    }
  };

  const deleteCatalog = (catalogId: string) => {
    if (generalStore.catalogsList.length <= 1) {
      message.info('Você deve ter apenas um único catálogo');
      return;
    }
    setIsLoading(true);
    StoreServices.deleteStore(user.company_id, catalogId)
      .then((response) => {
        if (response) {
          message.success('Catálogo excluído com sucesso');
          CompanyServices.getCompanyData(user.company.social_url).then(
            (response) => {
              if (response.stores) {
                if (response.stores.length > 0) {
                  localStorage.setItem(
                    `@Suiteshare:catalogId-${user.company_id}`,
                    response.stores[0].id,
                  );
                  generalStore.setCatalogsList(response.stores);
                  generalStore.setCurrentCatalogId(response.stores[0].id);
                } else {
                  localStorage.removeItem(
                    `@Suiteshare:catalogId-${user.company_id}`,
                  );

                  generalStore.setCatalogsList([]);
                  generalStore.setCurrentCatalogId('');
                }
              }
            },
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="catalogs-list">
      {isModalOpen ? (
        <CatalogRegister
          id={editCatalogId}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
        />
      ) : (
        <div />
      )}
      <div className="catalogs-list__action-bar">
        <div className="text-wrapper">
          <h1>Catálogos</h1>
          <span>
            {generalStore.catalogsList ? generalStore.catalogsList.length : 0}{' '}
            de 20
          </span>
        </div>
      </div>
      <div className="catalogs-list__main-content wrapper-1-1 row ya-start between">
        {isLoading ? (
          <Skeleton />
        ) : (
          <ul className="cards-container wrapper-1-1 row center xa-start">
            {generalStore.catalogsList.length >= catalogLimit ? (
              <button
                className="cards-container__card-wrapper cards-container__card-wrapper--add-block column center"
                type="button"
                onClick={() => askForMoreCatalogs()}
              >
                <FiLock />
                <span>Faça Upgrade</span>
              </button>
            ) : (
              <button
                className="cards-container__card-wrapper cards-container__card-wrapper--add-new column center"
                type="button"
                onClick={() => setIsModalOpen(true)}
              >
                <FaPlus />
                <span>Novo catálogo</span>
              </button>
            )}
            {generalStore.catalogsList ? (
              generalStore.catalogsList.map(
                (store: IStoreProps, index: number) => {
                  return (
                    <CardContainer
                      key={store.id}
                      index={index}
                      storeData={store}
                      switchEnabled={handleCatalogEnable}
                      deleteCatalog={deleteCatalog}
                      editCatalog={editCatalog}
                    />
                  );
                },
              )
            ) : (
              <div />
            )}
          </ul>
        )}
      </div>
    </div>
  );
});

export default CatalogsList;
